<template>
  <div class="home container-fluid p-1">
    <Spinner v-if="!isReady" color="primary" />
    <div v-else>
      <div class="row">
        <div class="d-flex col-md-8 mx-auto justify-content-between mt-3">
          <h3 v-if="seasons" class="text-dark">Κατάταξη {{ seasons[0].name }}</h3>
          

          <div v-if="!champion">
            <button v-if="!addPlayerSession && admin" class="btn btn-outline-success"
              @click="checkTotalWins">Check</button>
            <button v-if="!addPlayerSession && admin" class="btn btn-primary"
              @click="addPlayerSession = !addPlayerSession">Προσθήκη παίκτη</button>
            <button v-if="addPlayerSession && admin" class="btn btn-outline-primary"
              @click="addPlayerSession = !addPlayerSession">Ακύρωση</button>
            <button v-if="!playerExists && !admin" class="btn btn-primary" @click="join">Συμμετοχή</button>
          </div>
        </div>
      </div>
      <!-- <h4 v-if="playerExists">{{ playerExists }}</h4> -->

      <transition>
        <div v-if="addPlayerSession" class="col-md-8 mx-auto m-2">
          <div class="my-2">
            <!-- <label for="selectPlayers">Επίλεξε παίκτες</label>  -->
            <select multiple class="form-select col" id="selectPlayers" v-model="addSelected">
              <option class="list-group-item" v-for="(player, index) in filteredPlayers" :key="player.id"
                :value="player.id">{{ player.name }}</option>
            </select>
          </div>
          <button class="btn btn-primary col-md-8 mx-auto" @click="addSelectedPlayers">Προσθήκη παίκτη</button>
        </div>
      </transition>
      <!-- <div v-if="!temp" class="col-md-8 mx-auto shadow rounded mt-3"> -->

      <div class="col-md-8 mx-auto shadow mb-4">
        <div class="row">
          <div class="col list-group-item list-group-item-action border-bottom " to="/">
            <div class="row justify-content-between">
              <span class="col-1 fw-bold">#</span>
              <span class="col-4 text-start fw-bold">Name</span>
              <span class="col-2 text-center text-success fw-bold">Wins</span>
              <span class="col-2 text-center text-danger">Loses</span>
              <span class="col-1 fw-bold">%</span>
              <span v-if="admin" class="col-1 fw-bold"></span>
            </div>
          </div>
        </div>
        <div class="row" :class="{ 'bg-light': (index % 2) === 0 }" v-for="(player, index) in players" :key="player.id">
          <div class="col list-group-item list-group-item-action">
            <div class="row justify-content-between">
              <span v-if="index === 0 && champion" class="col-1 my-auto"><i
                  class="text-warning bi bi-trophy-fill"></i></span>
              <span v-else class="col-1 my-auto">{{ index + 1 }}</span>
              <span class="col-4 text-start d-flex justify-content-start align-items-center">
                <img v-if="player.photoURL" :src="player.photoURL"
                  style="max-width: 30px; max-height: 30px; cursor: pointer;" alt="photoURL"
                  class="img-fluid rounded me-1" data-bs-toggle="modal" :data-bs-target="`#lightbox${index}`" />
                <i v-else class="bi bi-person-circle me-1"></i>
                {{ player.name }}
              </span>
              <teleport to="body">
                <div class="modal fade" :id="`lightbox${index}`" tabindex="-1" aria-labelledby="modal"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <i class="fas fa-lg fa-times" data-bs-dismiss="modal" aria-label="Close"></i>
                      </div>
                      <div class="modal-body mx-auto">
                        <img v-if="player.photoURL" :src="player.photoURL" alt="photoURL" class="img-fluid rounded" />
                      </div>
                    </div>
                  </div>
                </div>
              </teleport>
              <span class="col-2 text-center text-success fw-bold my-auto">{{ player.wins }}<span
                  v-if="check" class="fst-italic fw-lighter fs-6">({{ player.checkWins }})</span></span>
              <span class="col-2 text-center text-danger my-auto">{{ player.loses }}<span v-if="check"
                  class="fst-italic fw-lighter fs-6">({{ player.checkLoses }})</span></span>
              <span class="col-1 fw-bold my-auto">{{ player.percent }}</span>
              <span v-if="admin" class="col-1 fw-bold my-auto">
                <EditPlayerModal :modalId="`editPlayerModal${index}`" :player="player" :season="seasons[0]" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 mx-auto">
        <div class="col d-flex justify-content-start mt-4">
          <h4>Χωρίς Κατάταξη</h4>
        </div>
        <div class="row">
          <div class="col list-group-item list-group-item-action border-bottom ">
            <div class="row justify-content-between">
              <span class="col-1 fw-bold">#</span>
              <span class="col-4 text-start fw-bold">Name</span>
              <span class="col-2 text-center text-success fw-bold">Wins</span>
              <span class="col-2 text-center text-danger">Loses</span>
              <span class="col-1 fw-bold">%</span>
              <span v-if="admin" class="col-1 fw-bold"></span>
            </div>
          </div>
        </div>
        <div class="row" :class="{ 'bg-light': (index % 2) === 0 }" v-for="(player, index) in unorderedPlayers"
          :key="player.id">
          <div class="col list-group-item list-group-item-action">
            <div class="row justify-content-between">
              <span class="col-1 fw-bold my-auto">-</span>
              <span class="col-4 text-start d-flex justify-content-start align-items-center">
                <img v-if="player.photoURL" :src="player.photoURL" style="max-width: 30px; max-height: 30px;"
                  alt="photoURL" class="img-fluid rounded me-1" data-bs-toggle="modal"
                  :data-bs-target="`#lightbox2${index}`" />
                <i v-else class="bi bi-person-circle me-1"></i>
                {{ player.name }}
              </span>
              <teleport to="body">
                <div class="modal fade" :id="`lightbox2${index}`" tabindex="-1" aria-labelledby="modal"
                  aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <i class="fas fa-lg fa-times" data-bs-dismiss="modal" aria-label="Close"></i>
                      </div>
                      <div class="modal-body mx-auto">
                        <img v-if="player.photoURL" :src="player.photoURL" alt="photoURL" class="img-fluid rounded" />
                      </div>
                    </div>
                  </div>
                </div>
              </teleport>
              <span class="col-2 text-center text-success fw-bold my-auto">{{ player.wins }}<span v-if="check"
                  class="fst-italic fw-lighter fs-6">({{ player.checkWins }})</span></span>
              <span class="col-2 text-center text-danger my-auto">{{ player.loses }}<span v-if="check"
                  class="fst-italic fw-lighter fs-6">({{ player.checkLoses }})</span></span>
              <span class="col-1 fw-bold my-auto">-</span>
              <span v-if="admin" class="col-1 fw-bold my-auto">
                <EditPlayerModal :modalId="`editPlayerModal${index}`" :player="player" :season="seasons[0]" />

              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!champion && admin" class="container d-flex justify-content-center mt-3">
        <Modal :btnClass="'btn btn-outline-danger'" :buttonText="'Τερματισμός Πρωταθλήματος'"
          :title="'Τερματισμός Πρωταθλήματος;'" :modalId="'endSeasonModal'" ref="closeEndSeasonModal"
          @handleClick="finishSeason" />
      </div>
      <div v-if="champion && admin" class="container d-flex justify-content-center mt-3">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addSeasonModal">
          Δημιουργία Πρωταθλήματος
        </button>

        <!-- Modal -->
        <div class="modal fade" id="addSeasonModal" tabindex="-1" aria-labelledby="addSeasonModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="addSeasonModalLabel">Νέο Πρωτάθλημα</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <form class=" mx-4 pt-3 rounded" @submit.prevent="addSeason">
                  <div class="mb-3">
                    <label for="name" class="form-label">Τίτλος</label>
                    <input type="text" class="form-control" :class="{ 'is-invalid': !isValid.name }" id="name"
                      v-model="seasonName">
                  </div>
                  <div class="col-auto">
                    <button v-if="!spinner" type="submit" class="btn btn-primary mb-3">Δημιουργία</button>
                    <div v-else class="spinner-border text-danger" role="status">
                      <span class="visually-hidden"></span>
                    </div>
                    <button class="visually-hidden" ref="closeModal" type="button" data-bs-dismiss="modal"></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-start mt-3"></div>
  <div class="container d-flex justify-content-start">
    <div class="col-md-6"></div>
    <div class="col-md"></div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex"
// import { watchEffect, onBeforeMount } from "vue";
// @ is an alias to /src
import { db } from "../firebase/config";
import { collection, getDocs, query, orderBy, doc, setDoc, getDoc, addDoc, updateDoc, serverTimestamp, increment, deleteDoc } from "firebase/firestore";
import getSeasons from "../composables/getSeasons";
import Spinner from "../components/Spinner.vue";
import Modal from "../components/Modal.vue";
import EditPlayerModal from "../components/EditPlayerModal";
import router from "../router";
// import { randomBytes } from "crypto";

export default {
  name: "Home",
  components: { Spinner, Modal, EditPlayerModal },
  setup() {
    const store = useStore()
    store.commit('setSidebarToggle', true)

    const options = ref(['list', 'of', 'options'])

    const isReady = computed(() => {
      return store.state.isReady
    })

    const seasons = computed(() => {
      return store.state.season
    })
    const user = computed(() => {
      return store.state.user
    })
    const admin = computed(() => {
      if (user.value) {
        return user.value.admin
      } else return false
    })
    const users = computed(() => {
      return store.state.users
    })
    console.log(users.value);
    const players = computed(() => {
      return Object.values(store.state.players)
    })
    const unorderedPlayers = computed(() => {
      return store.state.unorderedPlayers
    })
    const totalPlayers = computed(() => {
      return store.state.totalPlayers
    })
    const teams = computed(() => {
      return store.state.teams
    })
    const totalTeams = computed(() => {
      return store.state.totalTeams
    })
    const unorderedTeams = computed(() => {
      return store.state.unorderedTeams
    })
    const orderedTeams = computed(() => {
      return Object.values(store.state.orderedTeams)
    })
    const games = computed(() => {
      return store.state.games
    })
    const error = computed(() => {
      return store.state.error
    })
    const isPending = computed(() => {
      return store.state.isPending
    })

    const spinner = ref(false)
    const closeModal = ref(null)
    const seasonName = ref(null)
    const isValid = ref({
      name: true
    })


    // check if user already exists in the tournament
    const playerExists = computed(() => {
      if (user.value) {
        return totalPlayers.value[user.value.uid]
        // return totalPlayers.value.find(player => {
        //   if (player.user) {
        //     return player.user.id === user.value.uid
        //   }
        // })
      }
    })

    // check if tournament has finished
    const champion = computed(() => {
      return !!seasons.value[0].champion
    })

    const toggleAddPlayer = ref(false)
    const addSelected = ref([])
    const addPlayerSession = ref(false)
    const closeEndSeasonModal = ref(null);


    const filteredPlayers = computed(() => {
      if (users.value) {
        // const totalPlayers = players.value.concat(unorderedPlayers.value)
        return Object.values(users.value).filter(user => !Object.values(totalPlayers.value).map(player => {
          if (player.user) {
            return player.user.id
          } else {
            return player.id
          }
        }).includes(user.id))
      }
    })

    // add players from users list into table to participate in current season
    const addSelectedPlayers = () => {
      let selectedPlayer = Object.values(users.value).filter(user => addSelected.value.includes(user.id))

      if (selectedPlayer.length) {
        selectedPlayer.forEach(async (player) => {
          const userRef = await doc(db, 'users', player.id)
          await setDoc(doc(db, 'season', seasons.value[0].id, 'players', player.id), {
            user: userRef,
            wins: 0,
            loses: 0
          })
        })
        // const colRefPlayers = collection(db, "season", seasons.value[0].id, "players");
        // store.dispatch('getPlayers', colRefPlayers)
        addPlayerSession.value = false
      } else {
        store.commit('setError', 'Επίλεξε έναν παίκτη...')
        store.commit('setAlertColor', 'alert-danger')
      }

    }

    // Finish season
    const finishSeason = async () => {
      const refSeason = doc(db, "season", seasons.value[0].id)
      try {
        await updateDoc(refSeason, { champion: players.value[0].user.id, teamChampion: orderedTeams.value[0].team.id })
        Object.values(totalPlayers.value).forEach(async player => {
          const refUser = doc(db, "users", player.id)
          await updateDoc(refUser, { wins: increment(player.wins), loses: increment(player.loses) })
        })

        Object.values(totalTeams.value).forEach(async team => {
          const refTeam = doc(db, "teams", team.id)
          await updateDoc(refTeam, { wins: increment(team.wins), loses: increment(team.loses) })
        })

        store.dispatch("getAllStats").then(async () => {
          await addStats();
          await addTeamStats()
        })
        
        // call function closeMdl of #endGameModal to close modal
        closeEndSeasonModal.value.closeMdl();
        store.dispatch('reloadSeasons', seasons.value[0].id)

      } catch (err) {
        store.commit('setError', 'Κάτι πήγε λάθος...')
        store.commit('setAlertColor', 'alert-danger')
      }

    }

    // User joins tournament
    const join = async () => {
      if (!user.value) {
        router.push('/login')
      } else {
        try {
          const userRef = await doc(db, 'users', user.value.uid)
          await setDoc(doc(db, 'season', seasons.value[0].id, 'players', user.value.uid), {
            user: userRef,
            wins: 0,
            loses: 0
          })

        } catch (err) {
          store.commit('setError', 'Κάτι πήγε λάθος...')
          store.commit('setAlertColor', 'alert-danger')
        }
      }

    }

    const addSeason = async () => {
      if (!seasonName.value) {
        isValid.value.name = false
        return
      }
      spinner.value = ref(true)
      await addDoc(collection(db, "season"), {
        name: seasonName.value,
        timestamp: serverTimestamp()
      }).then((doc) => {
        store.dispatch("reloadSeasons", doc.id)
        spinner.value = ref(false)
        closeModal.value.click()
      })
    }

    // const seedTeams = () => {
    //   store.dispatch('seedTeams', teams.value)
    // }

    // add head2-to-head stats to db
    const addStats = () => {
      let allPlayers = Object.values(store.state.totalPlayers);
      let allStats = store.state.allStats;
      let docs = []
      let docSnap = null
      let player1Win = 0;
      let player2Win = 0;
      let index
      for (let i = 0; i < allPlayers.length; i++) {
        for (let j = i + 1; j < allPlayers.length; j++) {
          let getGames = Object.values(store.state.games)
            .filter(
              (game) =>
                (game.player1.id === allPlayers[i].id || game.player2.id === allPlayers[i].id) &&
                (game.player3.id === allPlayers[j].id || game.player4.id === allPlayers[j].id)
            )
            .concat(
              Object.values(store.state.games).filter(
                (game) =>
                  (game.player1.id === allPlayers[j].id || game.player2.id === allPlayers[j].id) &&
                  (game.player3.id === allPlayers[i].id || game.player4.id === allPlayers[i].id)
              )
            );

          let getStat = allStats
            .filter(stat => (stat.player1.user.id === allPlayers[i].user.id) || (stat.player2.user.id === allPlayers[i].user.id))
            .filter(stat => (stat.player1.user.id === allPlayers[j].user.id) || (stat.player2.user.id === allPlayers[j].user.id))[0]

          const findStat = (stat) => {
            return ((stat.player1.user.id === allPlayers[i].user.id) || (stat.player2.user.id === allPlayers[i].user.id)) && ((stat.player1.user.id === allPlayers[j].user.id) || (stat.player2.user.id === allPlayers[j].user.id))
          }

          player1Win = 0;
          player2Win = 0;
          getGames.forEach((game) => {
            switch (allPlayers[i].id) {
              case game.player1.id:
                game.player1.win ? player1Win++ : player2Win++;
                break;
              case game.player2.id:
                game.player2.win ? player1Win++ : player2Win++;
                break;
              case game.player3.id:
                game.player3.win ? player1Win++ : player2Win++;
                break;
              case game.player4.id:
                game.player4.win ? player1Win++ : player2Win++;
                break;
            }
          });

          if (getStat) {
            let totalWins1 = 0
            let totalWins2 = 0
            const statsRef = doc(db, "allstats", "48HCLMNKEIarqlz6iU76", "h2h", getStat.id)
            index = allStats.findIndex(findStat)
            if (allPlayers[i].user.id === getStat.player1.user.id) {
              totalWins1 = getStat.player1.wins + player1Win
              totalWins2 = getStat.player2.wins + player2Win
              docSnap = {
                player1: {
                  user: getStat.player1.user,
                  wins: totalWins1
                },
                player2: {
                  user: getStat.player2.user,
                  wins: totalWins2
                },
              }
              updateDoc(statsRef, docSnap).then(() => {
                docs.push({ ...docSnap, id: getStat.id })
              })
            } else if (allPlayers[i].user.id === getStat.player2.user.id) {
              totalWins1 = getStat.player2.wins + player2Win
              totalWins2 = getStat.player1.wins + player1Win
              docSnap = {
                player1: {
                  user: getStat.player2.user,
                  wins: totalWins2
                },
                player2: {
                  user: getStat.player1.user,
                  wins: totalWins1
                },
              }
              updateDoc(statsRef, docSnap).then(() => {
                docs.push({ ...docSnap, id: getStat.id })
              })
            }
          } else {
            docSnap = {
              player1: {
                user: allPlayers[i].user,
                wins: player1Win
              },
              player2: {
                user: allPlayers[j].user,
                wins: player2Win
              },
            }
            addDoc(collection(db, "allstats", "48HCLMNKEIarqlz6iU76", "h2h"), docSnap).then((snap) => {
              docs.push({ ...docSnap, id: snap.id })
            })

          }
        }
        // store.commit("setAllStats", docs)
      }

    }

    // add head2-to-head team stats to db
    const addTeamStats = () => {
      // store.dispatch("getAllGames", { p1: player1.value, p2: player2.value })
      let allTeams = Object.values(store.state.totalTeams);
      let allTeamStats = store.state.allTeamStats;
      let docs = []
      let docSnap = null
      let team1Win = 0;
      let team2Win = 0;
      let index
      for (let i = 0; i < allTeams.length; i++) {
        for (let j = i + 1; j < allTeams.length; j++) {
          let getGames = Object.values(store.state.games)
            .filter(
              (game) =>
                (game.team1.id === allTeams[i].id) &&
                (game.team2.id === allTeams[j].id)
            )
            .concat(
              Object.values(store.state.games).filter(
                (game) =>
                  (game.team1.id === allTeams[j].id) &&
                  (game.team2.id === allTeams[i].id)
              )
            );

          let getStat = allTeamStats
            .filter(stat => (stat.team1.team.id === allTeams[i].team.id) || (stat.team2.team.id === allTeams[i].team.id))
            .filter(stat => (stat.team1.team.id === allTeams[j].team.id) || (stat.team2.team.id === allTeams[j].team.id))[0]

          const findStat = (stat) => {
            return ((stat.team1.team.id === allTeams[i].team.id) || (stat.team2.team.id === allTeams[i].team.id)) && ((stat.team1.team.id === allTeams[j].team.id) || (stat.team2.team.id === allTeams[j].team.id))
          }

          team1Win = 0;
          team2Win = 0;
          getGames.forEach((game) => {
            switch (allTeams[i].id) {
              case game.team1.id:
                game.team1.win ? team1Win++ : team2Win++;
                break;
              case game.team2.id:
                game.team2.win ? team1Win++ : team2Win++;
                break;
            }
          });

          if (getStat) {
            let totalWins1 = 0
            let totalWins2 = 0
            const statsRef = doc(db, "allstats", "48HCLMNKEIarqlz6iU76", "teamH2h", getStat.id)
            index = allTeamStats.findIndex(findStat)
            if (allTeams[i].team.id === getStat.team1.team.id) {
              totalWins1 = getStat.team1.wins + team1Win
              totalWins2 = getStat.team2.wins + team2Win
              docSnap = {
                team1: {
                  team: getStat.team1.team,
                  wins: totalWins1
                },
                team2: {
                  team: getStat.team2.team,
                  wins: totalWins2
                },
              }
              updateDoc(statsRef, docSnap).then(() => {
                docs.push({ ...docSnap, id: getStat.id })
              })
            } else if (allTeams[i].team.id === getStat.team2.team.id) {
              totalWins1 = getStat.team2.wins + team2Win
              totalWins2 = getStat.team1.wins + team1Win
              docSnap = {
                team1: {
                  team: getStat.team2.team,
                  wins: totalWins2
                },
                team2: {
                  team: getStat.team1.team,
                  wins: totalWins1
                },
              }
              updateDoc(statsRef, docSnap).then(() => {
                docs.push({ ...docSnap, id: getStat.id })
              })
            }
          } else {
            docSnap = {
              team1: {
                team: allTeams[i].team,
                wins: team1Win
              },
              team2: {
                team: allTeams[j].team,
                wins: team2Win
              },
            }
            addDoc(collection(db, "allstats", "48HCLMNKEIarqlz6iU76", "teamH2h"), docSnap).then((snap) => {
              docs.push({ ...docSnap, id: snap.id })
            })

          }
        }
        // store.commit("setAllTeamStats", docs)
      }
    }

    const addWins = () => {
      teams.value.forEach(team => {
        const findTeam = totalTeams.value.find(find => find.team.id === team.id)
        if (findTeam) {
          updateDoc(doc(db, "teams", team.id), {
            wins: increment(findTeam.wins),
            loses: increment(findTeam.loses)
          }).then(() => console.log("update"))
        }
      })
      users.value.forEach(user => {
        const player = totalPlayers.value.find(player => player.user.id === user.id);
        if (player) {
          updateDoc(doc(db, "users", user.id), {
            wins: increment(player.wins),
            loses: increment(player.loses)
          }).then(() => console.log("update"))
        }

      })

    }

    const check = ref(false);

    const checkTotalWins = () => {
      check.value = true;
      let checkPlayers = totalPlayers.value
      Object.values(checkPlayers).forEach(player => {
        // player = { ...player }; // Create a shallow copy of the player object
        player.checkWins = 0
        player.checkLoses = 0
        // checkPlayers[player.id] = player
      })

      Object.values(games.value).forEach(game => {
        if (!game.live) {
          game.player1.win ? checkPlayers[game.player1.id].checkWins++ : checkPlayers[game.player1.id].checkLoses++
          game.player2.win ? checkPlayers[game.player2.id].checkWins++ : checkPlayers[game.player2.id].checkLoses++
          game.player3.win ? checkPlayers[game.player3.id].checkWins++ : checkPlayers[game.player3.id].checkLoses++
          game.player4.win ? checkPlayers[game.player4.id].checkWins++ : checkPlayers[game.player4.id].checkLoses++
        }
      })

    }

    return { user, admin, seasons, playerExists, champion, join, addStats, addTeamStats, addWins, users, players, unorderedPlayers, totalPlayers, orderedTeams, unorderedTeams, games, toggleAddPlayer, filteredPlayers, addSelected, addPlayerSession, check, addSelectedPlayers, addSeason, seasons, error, isPending, options, finishSeason, addSeason, checkTotalWins, closeEndSeasonModal, spinner, closeModal, seasonName, isValid, isReady };
  },
};
</script>
<style scoped>
ul {
  list-style-type: none;
}

.bold {
  font-weight: 600;
}

.subMenu-enter-active {
  transition: all 1s ease;
}

.subMenu-enter-from,
.subMenu-leave-to {
  opacity: 0;
}

.subMenu-move {
  transition: all 0.5s ease;
}
</style>
