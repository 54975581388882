<template>
    <div class="container-fluid p-1">
        <div class="row">
            <div class="d-flex col-md-8 mx-auto justify-content-start mt-3">
                <h3>Ομάδες</h3>
            </div>
        </div>
        <Spinner v-if="!isReady" color="primary" />
        <div v-else class="col-md-8 mx-auto">
            <!-- <h6 class="text-danger text-start">*Τα στατιστικά υπολογίζονται από 5-1-2022</h6> -->
            <div class="shadow mb-4">
                <button v-if="!champion && admin" class="btn btn-outline-success" @click="checkTotalWins">Check</button>
                <div class="row">
                    <div class="col list-group-item list-group-item-action border-bottom ">
                        <div class="row justify-content-between">
                            <span class="col-1 fw-bold">#</span>
                            <span class="col-4 text-start fw-bold">Name</span>
                            <span class="col-2 text-end text-success fw-bold">Wins</span>
                            <span class="col-2 text-end text-danger">Loses</span>
                            <span class="col-1 fw-bold">%</span>
                            <span class="col-1 fw-bold"></span>
                        </div>
                    </div>
                </div>
                <div class="row" :class="{ 'bg-light': (index % 2) === 0 }" v-for="(team, index) in orderedTeams"
                    :key="team.id">
                    <div class="col list-group-item list-group-item-action" to="/teams">
                        <div class="row justify-content-between">
                            <span v-if="index === 0 && seasons[0].teamChampion" class="col-1 my-auto"><i
                                    class="text-warning bi bi-trophy-fill"></i></span>
                            <span v-else class="col-1">{{ index + 1 }}</span>
                            <span class="col-4 text-start">{{ team.name }}</span>
                            <span class="col-2 text-end text-success fw-bold">{{ team.wins }}<span v-if="check"
                                    class="fst-italic fw-lighter fs-6">({{ team.checkWins }})</span></span>
                            <span class="col-2 text-end text-danger">{{ team.loses }}<span v-if="check"
                                    class="fst-italic fw-lighter fs-6">({{ team.checkLoses }})</span></span>
                            <span class="col-1 fw-bold">{{ team.percent }}</span>
                            <span v-if="admin" class="col-1 fw-bold my-auto">
                                <EditTeamModal :modalId="`editTeamModal${index}`" :team="team" :season="seasons[0]" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="col d-flex justify-content-start mt-4">
                    <h4>Χωρίς Κατάταξη</h4>
                </div>
                <div class="row justify-content-between border-bottom border-2">
                    <span class="col-1 fw-bold">#</span>
                    <span class="col-4 text-start fw-bold">Name</span>
                    <span class="col-2 text-end text-success fw-bold">Wins</span>
                    <span class="col-2 text-end text-danger">Loses</span>
                    <span class="col-2 fw-bold">%</span>
                </div>
                <div class="row" :class="{ 'bg-light': (index % 2) === 0 }" v-for="(team, index) in unorderedTeams"
                    :key="team.id">
                    <router-link class="col btn" to="/teams">
                        <div class="row justify-content-between">
                            <span class="col-1 fw-bold">-</span>
                            <span class="col-4 text-start">{{ team.name }}</span>
                            <span class="col-2 text-end text-success fw-bold">{{ team.wins }}<span v-if="check"
                                    class="fst-italic fw-lighter fs-6">({{ team.checkWins }})</span></span>
                            <span class="col-2 text-end text-danger">{{ team.loses }}<span v-if="check"
                                    class="fst-italic fw-lighter fs-6">({{ team.checkLoses }})</span></span>
                            <span class="col-1 fw-bold">-</span>
                            <span v-if="admin" class="col-1 fw-bold my-auto">
                                <EditTeamModal :modalId="`editTeamModal${index}`" :team="team" :season="seasons[0]" />
                            </span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { db } from "../firebase/config";
import { collection, getDocs, query, orderBy, doc, setDoc, } from "firebase/firestore";
import getSeasons from "../composables/getSeasons";
import Spinner from "../components/Spinner.vue";
import EditTeamModal from "../components/EditTeamModal";
import { useStore } from "vuex";

export default {
    name: 'Teams',
    components: { Spinner, EditTeamModal },
    setup() {
        const store = useStore()
        store.commit('setSidebarToggle', true)

        // store.commit('setIsPending', true)

        const isReady = computed(() => {
            return store.state.isReady;
        });

        const error = computed(() => {
            return store.state.error;
        });
        const isPending = computed(() => {
            return store.state.isPending;
        });
        const seasons = computed(() => {
            return store.state.season;
        });
        const user = computed(() => {
            return store.state.user
        })
        const admin = computed(() => {
            if (user.value) {
                return user.value.admin
            } else return false
        })
        // check if tournament has finished
        const champion = computed(() => {
            return !!seasons.value[0].champion
        })
        // const players = computed(() => {
        //     return store.state.totalPlayers;
        // });
        // const games = computed(() => {
        //     return store.state.games
        // })
        const totalTeams = computed(() => {
            return store.state.totalTeams;
        });
        const unorderedTeams = computed(() => {
            return store.state.unorderedTeams
        })
        const orderedTeams = computed(() => {
            return Object.values(store.state.orderedTeams)
        })
        const games = computed(() => {
            return store.state.games
        })

        const check = ref(false)

        const checkTotalWins = () => {
            check.value = true;
            let checkTeams = totalTeams.value
            Object.values(checkTeams).forEach(team => {
                // team = { ...team }; // Create a shallow copy of the team object
                team.checkWins = 0
                team.checkLoses = 0
                // checkteams[team.id] = team
            })

            Object.values(games.value).forEach(game => {
                if (!game.live) {
                    game.player1.win ? (checkTeams[game.team1.id].checkWins++, checkTeams[game.team2.id].checkLoses++) : (checkTeams[game.team1.id].checkLoses++, checkTeams[game.team2.id].checkWins++)
                }
            })

        }

        return { error, isPending, orderedTeams, unorderedTeams, seasons, isReady, admin, champion, check, checkTotalWins }


    }

}
</script>
<style></style>