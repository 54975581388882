<template>
    <div class="container-fluid p-1">
        <div class="row">
            <div class="d-flex col-md-8 justify-content-start mx-auto mt-3">
                <h3 class="text-dark">Head to Head</h3>
            </div>
        </div>
        <Spinner v-if="!isReady" color="primary" />
        <div v-else class="row col-md-8 mx-auto">
            <ul class="nav nav-pills mx-2">
                <li class="nav-item">
                    <a class="nav-link" :class="{ active: statsTab === 'player' }" aria-current="page" href="#"
                        @click="toggleStats('player')">Παικτών</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" :class="{ active: statsTab === 'teams' }" href="#"
                        @click="toggleStats('teams')">Ομάδων</a>
                </li>
            </ul>
            <!-- <h6 class="text-danger text-start">*Τα στατιστικά υπολογίζονται από 5-1-2022</h6> -->
            <div v-if="statsTab === 'player'" class="row">
                <div class="col-md my-2 input-group">
                    <label class="input-group-text" for="selectP1">Παίκτης 1</label>
                    <select class="form-select bg-primary text-white" id="selectP1" v-model="player1Id"
                        @change="player2Id = null">
                        <option class="form-select" v-for="(player, index) in users" :key="player.id"
                            :value="player.id">{{ player.name }}</option>
                    </select>
                </div>
                <div class="col-md my-2 input-group">
                    <label class="input-group-text" for="selectP2">Παίκτης 2</label>
                    <select class="form-select bg-danger text-white" id="selectP2" v-model="player2Id"
                        @change="handleP2change" :disabled="!player1Id">
                        <option class="form-select" v-for="(player, index) in filtP2" :key="player.id"
                            :value="player.id">{{ player.name }}</option>
                    </select>
                </div>
            </div>
            <div v-if="player2Id" class="row mx-auto mt-3">
                <div v-if="isPending" class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden"></span>
                </div>
                <div v-else>
                    <h5 class="text-secondary">Νίκες</h5>
                    <div>
                        <span class="input-group-text mx-auto">Τρέχουσα σεζόν</span>
                        <div class="progress" style="height: 40px;">
                            <div class="progress-bar pe-2 fw-bold fs-5" role="progressbar"
                                :style="'width: ' + barPlayer1 + '%;'" :aria-valuenow="barPlayer1" aria-valuemin="0"
                                aria-valuemax="100">
                                <span>{{ player1h2hWins }}</span>
                            </div>
                            <div class="progress-bar bg-danger fw-bold fs-5" role="progressbar"
                                :style="'width: ' + barPlayer2 + '%;'" :aria-valuenow="barPlayer2" aria-valuemin="0"
                                aria-valuemax="100">
                                <span>{{ player2h2hWins }}</span>
                            </div>
                        </div>
                    </div>
                    <hr class="text-primary">
                    <div>
                        <span class="input-group-text mx-auto">All time</span>
                        <div class="progress" style="height: 40px;">
                            <div class="progress-bar pe-2 fw-bold fs-5" role="progressbar"
                                :style="'width: ' + barPlayer1All + '%;'" :aria-valuenow="barPlayer1All"
                                aria-valuemin="0" aria-valuemax="100">
                                <span>{{ player1AllWins }}</span>
                            </div>
                            <div class="progress-bar bg-danger fw-bold fs-5" role="progressbar"
                                :style="'width: ' + barPlayer2All + '%;'" :aria-valuenow="barPlayer2All"
                                aria-valuemin="0" aria-valuemax="100">
                                <span>{{ player2AllWins }}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-if="statsTab === 'teams'" class="row">

                <div class="input-group my-2">
                    <label class="input-group-text bg-primary text-white" for="team1">Ομάδα 1</label>
                </div>
                <v-select class="col text-primary" :options="filtTeam1" label="name" id="team1" v-model="selectedTeam1">
                </v-select>
                <div class="input-group my-2">
                    <label class="input-group-text bg-danger text-white" for="team2">Ομάδα 1</label>
                </div>
                <v-select class="col text-danger" :options="filtTeam2" label="name" id="team2" v-model="selectedTeam2" >
                </v-select>
                <!-- <div class="dropdown input-group my-2">
                    <label class="input-group-text" for="player1">Ομάδα 1</label>
                    <input class="form-select bg-primary text-white" type="text" name id="player1" aria-expanded="false"
                        data-bs-toggle="dropdown" v-model="team1Name" @change="handleTeam1Change" autocomplete="off" />
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <li class="btn list-group-item list-group-item-action" v-for="(team, index) in searchTeam1"
                            :key="team.id" @click="selectTeam(index)">{{ team.name }}</li>
                    </ul>
                </div> -->
                <!-- <div class="dropdown input-group my-2">
                    <label class="input-group-text" for="player2" :class="{ 'text-secondary': !team1Name }">Ομάδα
                        2</label>
                    <input class="form-select bg-danger text-white" type="text" name id="player1" aria-expanded="false"
                        data-bs-toggle="dropdown" v-model="team2Name" @change="handleTeam2Change" :disabled="!team1Name"
                        autocomplete="off" />
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <li class="btn list-group-item list-group-item-action" v-for="(team, index) in filtTeam2"
                            :key="team.id" @click="selectTeam2(index)">{{ team.name }}</li>
                    </ul>
                </div> -->
                <div v-if="showTeams" class="me-auto mt-3">
                    <div v-if="isPending" class="spinner-border text-secondary" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                    <div v-else>
                        <h5 class="text-secondary">Νίκες</h5>
                        <div>
                            <span class="input-group-text mx-auto">Τρέχουσα σεζόν</span>
                            <div class="progress" style="height: 40px;">
                                <div class="progress-bar pe-2 fw-bold fs-5" role="progressbar"
                                    :style="'width: ' + barTeam1 + '%;'" :aria-valuenow="barTeam1" aria-valuemin="0"
                                    aria-valuemax="100">
                                    <span>{{ team1.h2hWins }}</span>
                                </div>
                                <div class="progress-bar bg-danger fw-bold fs-5" role="progressbar"
                                    :style="'width: ' + barTeam2 + '%;'" :aria-valuenow="barTeam2" aria-valuemin="0"
                                    aria-valuemax="100">
                                    <span>{{ team2.h2hWins }}</span>
                                </div>
                            </div>
                        </div>
                        <hr class="text-primary">
                        <div>
                            <span class="input-group-text mx-auto">All time</span>
                            <div class="progress" style="height: 40px;">
                                <div class="progress-bar pe-2 fw-bold fs-5" role="progressbar"
                                    :style="'width: ' + barTeam1All + '%;'" :aria-valuenow="barTeam1All"
                                    aria-valuemin="0" aria-valuemax="100">
                                    <span>{{ team1.allWins }}</span>
                                </div>
                                <div class="progress-bar bg-danger fw-bold fs-5" role="progressbar"
                                    :style="'width: ' + barTeam2All + '%;'" :aria-valuenow="barTeam2All"
                                    aria-valuemin="0" aria-valuemax="100">
                                    <span>{{ team2.allWins }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex"
import { watchEffect, watch } from "vue";
import Spinner from "../components/Spinner.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// @ is an alias to /src
import { db } from "../firebase/config";
import { doc, getDocs, query, orderBy, serverTimestamp, collection, addDoc, onSnapshot } from "firebase/firestore";
import { useRoute } from "vue-router";
import getSeasons from "../composables/getSeasons";
import router from "../router";

export default {
    name: 'Head2Head',
    components: { Spinner, vSelect },
    setup() {
        const store = useStore()
        store.commit('setSidebarToggle', true)
        if(!store.state.allStats.length) {
            store.dispatch('getAllStats')
        }

        const isReady = computed(() => {
            return store.state.isReady
        })

        const seasons = computed(() => {
            return store.state.season
        })
        const users = computed(() => {
            return store.state.users
        })
        const players = computed(() => {
            return store.state.players
        })
        const unorderedPlayers = computed(() => {
            return store.state.unorderedPlayers
        })
        const totalPlayers = computed(() => {
            return store.state.totalPlayers
        })
        const teams = computed(() => {
            return store.state.teams
        })
        const totalTeams = computed(() => {
            return store.state.totalTeams
        })
        const unorderedTeams = computed(() => {
            return store.state.unorderedTeams
        })
        const orderedTeams = computed(() => {
            return store.state.orderedTeams
        })
        const games = computed(() => {
            return store.state.games
        })
        const allGames = computed(() => {
            return store.state.allGames
        })
        const allStats = computed(() => {
            return store.state.allStats
        })
        const allTeamStats = computed(() => {
            return store.state.allTeamStats
        })
        const error = computed(() => {
            return store.state.error
        })
        const isPending = computed(() => {
            return store.state.isPending
        })
        const player1Id = ref()
        const player2Id = ref()
        const team1 = ref()
        const team2 = ref()
        const selectedTeam1 = ref()
        const selectedTeam2 = ref()
        // const searchTeam1 = ref()
        const team1Name = ref()
        const team2Name = ref()
        const barPlayer1 = ref()
        const barPlayer2 = ref()
        const barPlayer2All = ref()
        const barPlayer1All = ref()
        const barTeam1 = ref()
        const barTeam2 = ref()
        const barTeam1All = ref()
        const barTeam2All = ref()
        const statsTab = ref('player')

        let getStats
        const player1h2hWins = ref(0)
        const player2h2hWins = ref(0)
        const player1AllWins = ref(0)
        const player2AllWins = ref(0)


        // filter player2 list from player1 value
        const filtP2 = computed(() => {
            if (player1Id.value) {
                return Object.fromEntries(Object.entries(users.value).filter(([user]) => user !== player1Id.value))
            }
        })

        // filter team1 list from team2 value
        const filtTeam1 = computed(() => {
            if (selectedTeam2.value) {
                let teams1 = Object.values(teams.value).filter((team) => team.player1.id !== selectedTeam2.value.player1.id && team.player1.id !== selectedTeam2.value.player2.id)
                    .filter((team) => team.player2.id !== selectedTeam2.value.player1.id && team.player2.id !== selectedTeam2.value.player2.id)
                return teams1
            } else return Object.values(teams.value)
        })

        // filter team2 list from team1 value
        const filtTeam2 = computed(() => {
            if (selectedTeam1.value) {
                let teams2 = Object.values(teams.value).filter((team) => team.player1.id !== selectedTeam1.value.player1.id && team.player1.id !== selectedTeam1.value.player2.id)
                    .filter((team) => team.player2.id !== selectedTeam1.value.player1.id && team.player2.id !== selectedTeam1.value.player2.id)
                return teams2
            } else return Object.values(teams.value)
        })
        // const filtTeam2 = computed(() => {
        //     if (team1.value) {
        //         let teams2 = teams.value.filter(team => team.player1.id !== team1.value.player1.id && team.player1.id !== team1.value.player2.id)
        //             .filter(team => team.player2.id !== team1.value.player1.id && team.player2.id !== team1.value.player2.id)
        //         if (team2Name.value) {
        //             const search = teams2.filter(team => team.name.toUpperCase().includes(team2Name.value.toUpperCase()))
        //             return search
        //         } else return teams2
        //     }
        // })

        const player1 = computed(() => {
            return totalPlayers.value[player1Id.value]
        })

        const player2 = computed(() => {
            return totalPlayers.value[player2Id.value]
        })

        const user1 = computed(() => {
            return users.value[player1Id.value]
        })

        const user2 = computed(() => {
            return users.value[player2Id.value]
        })

        const handleP2change = () => {
            store.commit("setIsPending", true);
            let player1Win = 0
            let player2Win = 0
            let getGames = []

            // get every game where the players are rivals
            if (player1.value && player2.value) {
                getGames = Object.values(games.value).filter(game => (game.player1.id === player1.value.id || game.player2.id === player1.value.id) && (game.player3.id === player2.value.id || game.player4.id === player2.value.id))
                    .concat(Object.values(games.value).filter(game => (game.player1.id === player2.value.id || game.player2.id === player2.value.id) && (game.player3.id === player1.value.id || game.player4.id === player1.value.id)))
                player1Win = 0
                player2Win = 0

                getGames.forEach(game => {
                    switch (player1.value.id) {
                        case game.player1.id:
                            game.player1.win ? player1Win++ : player2Win++
                            break;
                        case game.player2.id:
                            game.player2.win ? player1Win++ : player2Win++
                            break;
                        case game.player3.id:
                            game.player3.win ? player1Win++ : player2Win++
                            break;
                        case game.player4.id:
                            game.player4.win ? player1Win++ : player2Win++
                            break;
                    }
                })
            }


            if (!!seasons.value[0].champion) {
                getGames = []
            }

            if (!getGames.length) {
                store.commit("setIsPending", false);
                player1h2hWins.value = 0
                player2h2hWins.value = 0
                barPlayer1.value = 50
                barPlayer2.value = 50
            } else {
                barPlayer1.value = Math.ceil((player1Win / getGames.length) * 100)
                barPlayer2.value = Math.floor((player2Win / getGames.length) * 100)
                if (barPlayer1.value === 100) {
                    barPlayer1.value = 90
                    barPlayer2.value = 10
                } else if (barPlayer1.value === 0) {
                    barPlayer1.value = 10
                    barPlayer2.value = 90
                }
                player1h2hWins.value = player1Win
                player2h2hWins.value = player2Win
            }


            getStats = allStats.value
                .filter(stat => (stat.player1.user.id === user1.value.id) || (stat.player2.user.id === user1.value.id))
                .filter(stat => (stat.player1.user.id === user2.value.id) || (stat.player2.user.id === user2.value.id))[0]

            if (!getStats) {
                store.commit("setIsPending", false);
                player1AllWins.value = player1h2hWins.value
                player2AllWins.value = player2h2hWins.value
                barPlayer1All.value = barPlayer1.value
                barPlayer2All.value = barPlayer2.value
            } else {
                if (!getGames.length) {
                    player1Win = 0
                    player2Win = 0
                }
                if (getStats.player1.user.id === user1.value.id) {
                    player1AllWins.value = player1Win + getStats.player1.wins
                    player2AllWins.value = player2Win + getStats.player2.wins
                    let allGames = getStats.player1.wins + getStats.player2.wins + getGames.length
                    barPlayer1All.value = Math.ceil((player1AllWins.value / allGames) * 100) || 50
                    barPlayer2All.value = Math.floor((player2AllWins.value / allGames) * 100) || 50
                } else if (getStats.player1.user.id === user2.value.id) {
                    player2AllWins.value = player2Win + getStats.player1.wins
                    player1AllWins.value = player1Win + getStats.player2.wins
                    let allGames = getStats.player1.wins + getStats.player2.wins + getGames.length
                    barPlayer2All.value = Math.ceil((player2AllWins.value / allGames) * 100) || 50
                    barPlayer1All.value = Math.floor((player1AllWins.value / allGames) * 100) || 50
                }

                if (barPlayer1All.value === 100) {
                    barPlayer1All.value = 90
                    barPlayer2All.value = 10
                } else if (barPlayer1All.value === 0) {
                    barPlayer1All.value = 10
                    barPlayer2All.value = 90
                }
            }

            store.commit("setIsPending", false);
        }

        const toggleStats = (stats) => {
            statsTab.value = stats
            player1Id.value = null
            player2Id.value = null
        }

        const handleTeam1Change = () => {
            team2Name.value = null
            team2.value = null
        }
        const handleTeam2Change = () => {
            team2.value = null
        }

        const searchTeam1 = computed(() => {
            if (team1Name.value) {
                const search = teams.value.filter(team => team.name.toUpperCase().includes(team1Name.value.toUpperCase()))
                return search;
            } else return teams.value
        })

        const selectTeam = (index) => {
            console.log(selectedTeam1.value);
            team1.value = selectedTeam1.value
            // team1.value = searchTeam1.value[index]
            team1Name.value = team1.value.name
        }

        const selectTeam2 = () => {
            store.commit("setIsPending", true);
            let team1Win = 0
            let team2Win = 0
            let getGames = []

            team1.value = selectedTeam1.value
            team2.value = selectedTeam2.value
            team1.value.team = Object.values(totalTeams.value).find(team => team.team.id === team1.value.id)
            team2.value.team = Object.values(totalTeams.value).find(team => team.team.id === team2.value.id)
            team2Name.value = team2.value.name

            if (team1.value.team && team2.value.team) {
                getGames = Object.values(games.value).filter(game => game.team1.id === team1.value.team.id && game.team2.id === team2.value.team.id)
                    .concat(Object.values(games.value).filter(game => game.team1.id === team2.value.team.id && game.team2.id === team1.value.team.id))

                getGames.forEach(game => {
                    switch (team1.value.team.id) {
                        case game.team1.id:
                            game.team1.win ? team1Win++ : team2Win++
                            break;
                        case game.team2.id:
                            game.team2.win ? team1Win++ : team2Win++
                            break;
                    }
                })
            }

            if (!!seasons.value[0].champion) {
                getGames = []
            }

            if (!getGames.length) {
                store.commit("setIsPending", false);
                team1.value.h2hWins = 0
                team2.value.h2hWins = 0
                barTeam1.value = 50
                barTeam2.value = 50
            } else {
                barTeam1.value = Math.ceil((team1Win / getGames.length) * 100)
                barTeam2.value = Math.floor((team2Win / getGames.length) * 100)
                if (barTeam1.value === 100) {
                    barTeam1.value = 90
                    barTeam2.value = 10
                } else if (barTeam1.value === 0) {
                    barTeam1.value = 10
                    barTeam2.value = 90
                }
                team1.value.h2hWins = team1Win
                team2.value.h2hWins = team2Win
            }

            getStats = allTeamStats.value
                .filter(stat => (stat.team1.team.id === team1.value.id) || (stat.team2.team.id === team1.value.id))
                .filter(stat => (stat.team1.team.id === team2.value.id) || (stat.team2.team.id === team2.value.id))[0]

            if (!getStats) {
                store.commit("setIsPending", false);
                team1.value.allWins = team1.value.h2hWins
                team2.value.allWins = team2.value.h2hWins
                barTeam1All.value = barTeam1.value
                barTeam2All.value = barTeam2.value
            } else {
                if (!getGames.length) {
                    team1Win = 0
                    team2Win = 0
                }
                if (getStats.team1.team.id === team1.value.id) {
                    team1.value.allWins = team1Win + getStats.team1.wins
                    team2.value.allWins = team2Win + getStats.team2.wins
                    let allGames = getStats.team1.wins + getStats.team2.wins + getGames.length
                    barTeam1All.value = Math.ceil((team1.value.allWins / allGames) * 100) || 50
                    barTeam2All.value = Math.floor((team2.value.allWins / allGames) * 100) || 50
                } else if (getStats.team1.team.id === team2.value.id) {
                    team2.value.allWins = team2Win + getStats.team1.wins
                    team1.value.allWins = team1Win + getStats.team2.wins
                    let allGames = getStats.team1.wins + getStats.team2.wins + getGames.length
                    barTeam2All.value = Math.ceil((team2.value.allWins / allGames) * 100) || 50
                    barTeam1All.value = Math.floor((team1.value.allWins / allGames) * 100) || 50
                }

                if (barTeam1All.value === 100) {
                    barTeam1All.value = 90
                    barTeam2All.value = 10
                } else if (barTeam1All.value === 0) {
                    barTeam1All.value = 10
                    barTeam2All.value = 90
                }
            }


            store.commit("setIsPending", false);

        }

        // const selectTeam2 = (index) => {
        //     console.log(selectedTeam1.value);

        //     store.commit("setIsPending", true);
        //     let team1Win = 0
        //     let team2Win = 0

        //     team2.value = filtTeam2.value[index]
        //     team2Name.value = team2.value.name

        //     getGames = games.value.filter(game => game.team1.id === team1.value.id && game.team2.id === team2.value.id)
        //         .concat(games.value.filter(game => game.team1.id === team2.value.id && game.team2.id === team1.value.id))

        //     getGames.forEach(game => {
        //         switch (team1.value.id) {
        //             case game.team1.id:
        //                 game.team1.win ? team1Win++ : team2Win++
        //                 break;
        //             case game.team2.id:
        //                 game.team2.win ? team1Win++ : team2Win++
        //                 break;
        //         }
        //     })

        //     if (!!seasons.value[0].champion) {
        //         getGames = []
        //     }

        //     if (!getGames.length) {
        //         store.commit("setIsPending", false);
        //         team1.value.h2hWins = 0
        //         team2.value.h2hWins = 0
        //         barTeam1.value = 50
        //         barTeam2.value = 50
        //     } else {
        //         barTeam1.value = Math.ceil((team1Win / getGames.length) * 100)
        //         barTeam2.value = Math.floor((team2Win / getGames.length) * 100)
        //         if (barTeam1.value === 100) {
        //             barTeam1.value = 90
        //             barTeam2.value = 10
        //         } else if (barTeam1.value === 0) {
        //             barTeam1.value = 10
        //             barTeam2.value = 90
        //         }
        //         team1.value.h2hWins = team1Win
        //         team2.value.h2hWins = team2Win
        //     }

        //     getStats = allTeamStats.value
        //         .filter(stat => (stat.team1.team.id === team1.value.team.id) || (stat.team2.team.id === team1.value.team.id))
        //         .filter(stat => (stat.team1.team.id === team2.value.team.id) || (stat.team2.team.id === team2.value.team.id))[0]

        //     if (!getStats) {
        //         store.commit("setIsPending", false);
        //         team1.value.allWins = team1.value.h2hWins
        //         team2.value.allWins = team2.value.h2hWins
        //         barTeam1All.value = barTeam1.value
        //         barTeam2All.value = barTeam2.value
        //     } else {
        //         if (!getGames.length) {
        //             team1Win = 0
        //             team2Win = 0
        //         }
        //         if (getStats.team1.team.id === team1.value.team.id) {
        //             team1.value.allWins = team1Win + getStats.team1.wins
        //             team2.value.allWins = team2Win + getStats.team2.wins
        //             let allGames = getStats.team1.wins + getStats.team2.wins + getGames.length
        //             barTeam1All.value = Math.ceil((team1.value.allWins / allGames) * 100) || 50
        //             barTeam2All.value = Math.floor((team2.value.allWins / allGames) * 100) || 50
        //         } else if (getStats.team1.team.id === team2.value.team.id) {
        //             team2.value.allWins = team2Win + getStats.team1.wins
        //             team1.value.allWins = team1Win + getStats.team2.wins
        //             let allGames = getStats.team1.wins + getStats.team2.wins + getGames.length
        //             barTeam2All.value = Math.ceil((team2.value.allWins / allGames) * 100) || 50
        //             barTeam1All.value = Math.floor((team1.value.allWins / allGames) * 100) || 50
        //         }

        //         if (barTeam1All.value === 100) {
        //             barTeam1All.value = 90
        //             barTeam2All.value = 10
        //         } else if (barTeam1All.value === 0) {
        //             barTeam1All.value = 10
        //             barTeam2All.value = 90
        //         }
        //     }


        //     store.commit("setIsPending", false);
        // }

        const showTeams = computed(() => {
            return (selectedTeam1.value && selectedTeam2.value);
        })

        watchEffect(() => {
            handleTeam1Change(team1Name.value)
        })

        watch([selectedTeam1, selectedTeam2], ([team1value, team2value], [prevteam1, prevteam2]) => {
            if (team1value && team2value) {
                selectTeam2()
            } 
        })

        return { totalPlayers, users, player1, player2, player1h2hWins, player2h2hWins, player1AllWins, player2AllWins, player1Id, player2Id, filtP2, barPlayer1, barPlayer2, barPlayer1All, barPlayer2All, barTeam1, barTeam2, barTeam1All, barTeam2All, statsTab, team1Name, team2Name, filtTeam1, filtTeam2, team1, team2, teams, selectedTeam1, selectedTeam2, searchTeam1, handleP2change, toggleStats, handleTeam1Change, handleTeam2Change, selectTeam, showTeams, selectTeam2, error, isPending, isReady }
    }

}
</script>
<style lang="">
    
</style>