<template>
    <div class="container-fluid p-1 d-flex justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9">

            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-lg-8 mx-auto">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-4">Δημιουργία Λογαριασμού!</h1>
                                </div>
                                <form class="user" @submit.prevent="handleSubmit">
                                    <div class="form-group">
                                        <input type="email" class="form-control form-control-user" id="email"
                                            :class="{ 'is-invalid': !isValid.email }" aria-describedby="emailHelp"
                                            v-model="email" placeholder="Email Address">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control form-control-user" id="username"
                                            :class="{ 'is-invalid': !isValid.username }" v-model="username"
                                            aria-describedby="username" placeholder="Display name">
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-6 mb-3 mb-sm-0">
                                            <input type="password" class="form-control form-control-user" id="password"
                                                :class="{ 'is-invalid': !isValid.password }" v-model="password"
                                                placeholder="Password">
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="password" class="form-control form-control-user"
                                                id="passwordRepeat" :class="{ 'is-invalid': !isValid.passwordRepeat }"
                                                v-model="passwordRepeat" placeholder="Repeat Password">
                                        </div>
                                    </div>
                                    <Spinner v-if="isPending" color="primary" />
                                    <button v-else type="submit" class="btn btn-primary btn-user btn-block">
                                        Εγγραφή!
                                    </button>
                                </form>
                                <hr>
                                <div class="text-center">
                                    <router-link class="small" to="/login">Έχετε ήδη λογαριασμό; Είσοδος!</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- <form class="col col-md-6 text-start" @submit.prevent="handleSubmit">
            <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input type="email" class="form-control" id="email" :class="{ 'is-invalid': !isValid.email }"
                    aria-describedby="emailHelp" v-model="email" />
            </div>
            <div class="mb-3">
                <label for="username" class="form-label">Display name</label>
                <input type="text" class="form-control" id="username" :class="{ 'is-invalid': !isValid.username }"
                    v-model="username" />
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" id="password" :class="{ 'is-invalid': !isValid.password }"
                    v-model="password" />
            </div>
            <Spinner v-if="isPending" color="primary" />
            <button v-else type="submit" class="btn btn-outline-primary">Εγγραφή</button>
        </form> -->
    </div>
</template>
<script>
import { ref, computed, onUnmounted } from "vue"
import { useRouter } from "vue-router"
import useSignup from "../composables/useSignup"
import Spinner from "../components/Spinner.vue"
// import fetchUser from "../composables/fetchUser"
import { updateProfile } from "firebase/auth"
import { auth, db } from "../firebase/config";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useStore } from "vuex"

export default {
    name: "Signup",
    components: { Spinner },
    setup() {
        const email = ref(null)
        const password = ref(null)
        const passwordRepeat = ref(null)
        const username = ref(null)
        const isValid = ref({
            email: true,
            password: true,
            passwordRepeat: true,
            username: true
        })
        const router = useRouter()
        const store = useStore()
        store.commit('setSidebarToggle', true)

        const error = computed(() => {
            return store.state.error
        })
        const isPending = computed(() => {
            return store.state.isPending
        })

        // const { signup, error, isPending } = useSignup()

        const handleSubmit = async () => {
            isValid.value.email = false
            isValid.value.password = false
            isValid.value.username = false
            store.commit("setIsPending", true);

            try {
                if (password.value !== passwordRepeat.value) {
                    isValid.value.password = false
                    isValid.value.passwordRepeat = false
                    store.commit("setIsPending", false);
                    store.commit('setError', 'Ο κωδικός δεν είναι ίδιος')
                    store.commit('setAlertColor', 'alert-danger')
                    return
                }
                if (email.value && password.value && passwordRepeat.value && username.value) {
                    await store.dispatch('signup', {
                        email: email.value,
                        password: password.value,
                    }).then(() => {
                        updateProfile(store.state.user, {
                            displayName: username.value
                        }).then(async () => {
                            // create a new user in users collection
                            const newUser = await setDoc(doc(db, 'users', store.state.user.uid), {
                                name: username.value,
                                admin: false,
                                verified: false,
                                wins: 0,
                                loses: 0
                            }).then(async () => {
                                const refUser = doc(db, "users", store.state.user.uid);
                                await getDoc(refUser).then((docSnap) => {
                                    store.dispatch('authChange', { ...docSnap.data(), ...auth.currentUser })
                                });
                            })
                            router.push('/')
                            store.commit('setError', 'Επιτυχής εγγραφή χρήστη')
                            store.commit('setAlertColor', 'alert-primary')
                            store.commit("setIsPending", false);
                            email.value = null
                            password.value = null
                            username.value = null
                        })
                    })
                    // await signup(email.value, password.value).then(() => {
                    //     const { user } = fetchUser()
                    //     updateProfile(user.value, {
                    //         displayName: username.value
                    //     }).then(() => {
                    //         // create a new user in users collection
                    //         setDoc(doc(db, 'users', user.value.uid), {
                    //             name: username.value,
                    //             admin: false
                    //         })
                    //         router.push('/')
                    //         email.value = null
                    //         password.value = null
                    //         username.value = null
                    //     })
                    // })
                } else {
                    email.value ? isValid.value.email = true : isValid.value.email = false
                    password.value ? isValid.value.password = true : isValid.value.password = false
                    passwordRepeat.value ? isValid.value.passwordRepeat = true : isValid.value.passwordRepeat = false
                    username.value ? isValid.value.username = true : isValid.value.username = false
                    store.commit("setIsPending", false);
                    store.commit('setError', 'Συπληρώστε όλα τα πεδία...')
                    store.commit('setAlertColor', 'alert-danger')
                }

            } catch (err) {
                store.commit("setIsPending", false);

                store.commit('setError', err.message)
                store.commit('setAlertColor', 'alert-danger')
            }

        }

        onUnmounted(() => {
            email.value = null
            password.value = null
            username.value = null
            store.commit('setError', null)
            isValid.value.email = true
            isValid.value.password = true
            store.commit("setIsPending", false);

        })

        return { email, password, passwordRepeat, username, handleSubmit, isValid, error, isPending }
    }


}
</script>
<style>
</style>