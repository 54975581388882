<template>
    <div class="container-fluid p-1">
        <div class="container" v-if="info">
            <h5 class="text-start text-success">{{ info }}</h5>
        </div>
        <Spinner v-if="!isReady" color="primary" />
        <div v-else class="row col-md-8 mt-3">
            <div class="col-md-4 border-right">
                <div class="d-flex flex-column align-items-center text-center p-3">
                    <h3 class="font-weight-bold">{{ user.displayName }}</h3>
                    <label class="btn" for="imgUpload">
                        <img v-if="user.photoURL" class="my-3 rounded" style="max-width: 150px;" :src="user.photoURL" />
                        <i v-else class="bi bi-person-circle" style="font-size:100px; opacity: 0.6;"></i>
                        <div v-if="progress" class="progress">
                            <div class="progress-bar  progress-bar-striped progress-bar-animated" role="progressbar"
                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="`width: ${progress}%`">
                            </div>
                        </div>
                        <input type="file" id="imgUpload" @change="handleChange" hidden />
                        <p v-if="!user.photoURL"><small><em>Πατήστε στο avatar για να αλλάξετε την εικόνα</em></small></p>
                    </label>
                    <button v-if="user.photoURL" class="btn btn-outline-danger" @click="deleteImg">Αφαίρεση
                        εικόνας<i class='fas fa-trash mx-1'></i></button>
                </div>
            </div>
            <!-- <div class="card col-3">
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/diloti-ffb39.appspot.com/o/users%2FIMG_20201123_111132.jpg?alt=media&token=2009d8e6-a9b1-4957-843b-820b0aaa2f01"
                    alt="avatar"
                    style="max-width: 100px;"
                />
                <div class="card-body">
                    <label class="btn btn-outline-primary" for="imgUpload">
                        <i class="bi bi-file-arrow-up-fill"></i>
                        <input type="file" id="imgUpload" hidden />
                    </label>
                </div>
            </div>-->
            <div class="col-md-8 mt-md-4 border-right">
                <div class="p-3">
                    <div class="mb-3 text-start">
                        <label for="changeName">Αλλαγή ονόματος</label>
                        <div class="input-group mt-2">
                            <input type="text" class="form-control" id="changeName" :placeholder="user.displayName"
                                aria-label="Display name" aria-describedby="display-name" v-model="name" />
                            <button class="btn btn-outline-primary" type="button" @click="changeName">Αλλαγή</button>
                        </div>
                    </div>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne">Αλλαγή Κωδικού</button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="mb-3 text-start">
                                        <label for="oldPassword">Παλιός κωδικός</label>
                                        <div class="input-group mt-2">
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': !isValid.oldPassword }" id="oldPassword"
                                                placeholder="******" aria-label="Display name"
                                                aria-describedby="display-name" v-model="oldPassword" />
                                        </div>
                                    </div>
                                    <div class="mb-3 text-start">
                                        <label for="changePassword">Νέος κωδικός</label>
                                        <div class="input-group mt-2">
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': !isValid.password }" id="changePassword"
                                                placeholder="******" aria-label="Display name"
                                                aria-describedby="display-name" v-model="password" />
                                        </div>
                                    </div>
                                    <div class="mb-3 text-start">
                                        <label for="changePassword">Επανάληψη κωδικού</label>
                                        <div class="input-group mt-2">
                                            <input type="text" class="form-control"
                                                :class="{ 'is-invalid': !isValid.passwordRepeat }" id="changePassword"
                                                placeholder="******" aria-label="Display name"
                                                aria-describedby="display-name" v-model="passwordRepeat" />
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-primary" type="button"
                                        @click="submitPass">Αλλαγή</button>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-outline-danger mt-3" type="button" @click="logout">Αποσύνδεση<i
                                class="fas fa-sign-out-alt mx-1"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { updatePassword, updateProfile, EmailAuthProvider, reauthenticateWithCredential, signOut } from "firebase/auth"
import { db, auth, storage, storageRef } from "../firebase/config";
import { doc, updateDoc, getDoc } from "firebase/firestore";
// import fetchUser from "../composables/fetchUser";
import Spinner from "../components/Spinner.vue";
import { ref as refer, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage"
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    name: "Home",
    components: { Spinner },
    setup() {
        const store = useStore()
        store.commit('setSidebarToggle', true)

        const router = useRouter()

        const isReady = computed(() => {
            return store.state.isReady
        })
        const name = ref('')
        const oldPassword = ref('')
        const password = ref('')
        const passwordRepeat = ref('')
        const isValid = ref({
            oldPassword: true,
            password: true,
            passwordRepeat: true
        })
        const img = ref()
        const fileError = ref(null)
        // getDownloadURL(imageRef).then(url => {
        //     img.value = url
        // })

        const user = computed(() => {
            return store.state.user
        })

        const error = computed(() => {
            return store.state.error
        })

        const info = computed(() => {
            return store.state.info
        })

        const isPending = computed(() => {
            return store.state.isPending
        })

        const seasons = computed(() => {
            return store.state.season;
        });

        //allowed types
        const types = ['image/png', 'image/jpeg']

        const progress = ref(0)

        const handleChange = async (e) => {
            let selected = e.target.files[0]
            let oldImgRef = store.state.user.photoRef

            if (selected && types.includes(selected.type)) {
                if (selected.size < 10485760) {
                    const progressInterval = setInterval(() => {
                        progress.value++
                        if (progress.value === 100) {
                            clearInterval(progressInterval)
                            progress.value = 0
                        }
                    }, 10)
                    const imageRef = refer(storage, 'users/' + selected.name)
                    const refUser = doc(db, "users", store.state.user.uid);
                    await uploadBytes(imageRef, selected).then(snap => {
                        getDownloadURL(imageRef).then(async url => {
                            img.value = url
                            await updateProfile(auth.currentUser, {
                                photoURL: url
                            }).then(async () => {
                                await updateDoc(doc(db, 'users', store.state.user.uid), {
                                    photoURL: url,
                                    photoRef: 'users/' + selected.name
                                })
                                if (oldImgRef) {
                                    deleteObject(refer(storage, oldImgRef))
                                }
                                await getDoc(refUser).then((docSnap) => {
                                    store.dispatch('authChange', { ...auth.currentUser, ...docSnap.data() })
                                    // store.dispatch('seasonStats', seasons.value[0].id)
                                });
                            })
                        })
                    })
                    clearInterval(progressInterval)
                    progress.value = 0
                    store.commit('setError', 'Η εικόνα προφίλ άλλαξε επιτυχώς...')
                    store.commit('setAlertColor', 'alert-success')
                } else {
                    selected = null
                    store.commit('setError', 'Το μέγεθος δεν μπορεί να υπερβαίνει τα 10mb')
                    store.commit('setAlertColor', 'alert-danger')
                }
            } else {
                selected = null
                store.commit('setError', 'Παρακαλώ επιλέξετε αρχείο εικόνας (png ή jpeg)')
                store.commit('setAlertColor', 'alert-danger')
            }
        }

        const changeName = async () => {
            if (name.value) {
                name.value = name.value.trim()
                await updateProfile(auth.currentUser, {
                    displayName: name.value
                }).then(async () => {
                    await updateDoc(doc(db, 'users', store.state.user.uid), {
                        name: name.value
                    })
                    const refUser = doc(db, "users", store.state.user.uid);
                    await getDoc(refUser).then((docSnap) => {
                        store.dispatch('authChange', { ...auth.currentUser, ...docSnap.data() })
                        // store.dispatch('seasonStats', seasons.value[0].id)
                        store.commit('setError', 'Το όνομα άλλαξε')
                        store.commit('setAlertColor', 'alert-success')
                    });
                    name.value = ''
                })

            }
        }

        const submitPass = async () => {
            if (password.value.length < 6) {
                store.commit('setError', 'Ο κωδικός πρέπει να περιέχει τουλάχιστον 6 χαρακτήρες')
                store.commit('setAlertColor', 'alert-danger')
                isValid.value.password = false
                return
            }
            if (password.value !== passwordRepeat.value) {
                store.commit('setError', 'Ο κωδικός δεν είναι ίδιος...')
                store.commit('setAlertColor', 'alert-danger')
                isValid.value.passwordRepeat = false
            } else {
                const credential = EmailAuthProvider.credential(auth.currentUser.email, oldPassword.value)
                reauthenticateWithCredential(auth.currentUser, credential).then(async () => {
                    await updatePassword(auth.currentUser, password.value).then(() => {
                        store.commit('setError', 'Ο κωδικός άλλαξε.')
                        store.commit('setAlertColor', 'alert-success')
                        isValid.value.password = true
                        isValid.value.passwordRepeat = true
                        oldPassword.value = ''
                        password.value = ''
                        passwordRepeat.value = ''
                    }).catch(err => {
                        if (err.message = 'Firebase: Error (auth/requires-recent-login).') {
                            console.log('auth/requires-recent-login');
                        }
                    })

                }).catch(err => {
                    if (err.message = 'Firebase: Error (auth/wrong-password).') {
                        store.commit('setError', 'Λάθος κωδικός...')
                        store.commit('setAlertColor', 'alert-danger')
                        isValid.value.oldPassword = false
                    } else {
                        store.commit('setError', 'Κάτι πήγε στραβά...')
                        store.commit('setAlertColor', 'alert-danger')
                    }
                })

            }
        }

        const deleteImg = () => {
            const imageRef = refer(storage, store.state.user.photoRef)
            deleteObject(imageRef).then(async () => {
                store.commit('setError', 'Η εικόνα αφαιρέθηκε...')
                store.commit('setAlertColor', 'alert-success')
                await updateProfile(auth.currentUser, {
                    photoURL: ''
                }).then(async () => {
                    await updateDoc(doc(db, 'users', store.state.user.uid), {
                        photoURL: '',
                        photoRef: null
                    })
                    store.dispatch('authChange', {
                        ...user.value, photoURL: '', photoRef: null
                    })
                }).catch(err => {
                    store.commit('setError', 'Κάτι πήγε στραβά...')
                    store.commit('setAlertColor', 'alert-danger')
                })
            })
        }

        const logout = async () => {
            await signOut(auth).then(() => {
                router.push('/login')
            })
        }


        return { user, error, info, name, img, oldPassword, password, passwordRepeat, isValid, changeName, handleChange, submitPass, deleteImg, logout, isPending, progress, isReady }
    }

}
</script>
<style>
</style>