<template>
    <i type="button" data-bs-toggle="modal" :data-bs-target="`#${modalId}`" class="bi bi-pencil-square">
    </i>
    <teleport to="body">
        <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="editPlayerModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="editTeamModalLabel">{{ team.name }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form class=" mx-4 pt-3 rounded">
                            <div class="mb-3">
                                <div class="input-group mb-3">
                                    <span class="input-group-text" id="wins">Wins</span>
                                    <input type="number" class="form-control" aria-label="Team's wins"
                                        aria-describedby="wins" v-model="teamWins">
                                </div>
                                <div class="input-group mb-3">
                                    <span class="input-group-text" id="loses">Loses</span>
                                    <input type="number" class="form-control" aria-label="Team's loses"
                                        aria-describedby="loses" v-model="teamLoses">
                                </div>
                            </div>
                            <div class="col-auto justify-content-center">
                                <button v-if="!spinner" type="button" class="col btn btn-primary mb-3 mx-auto"
                                    @click="handleButton">Αποθήκευση</button>
                                <div v-else class="spinner-border text-danger" role="status">
                                    <span class="visually-hidden"></span>
                                </div>
                                <button class="visually-hidden" ref="closeModal" type="button"
                                    data-bs-dismiss="modal"></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>
<script>
import { computed, ref, onMounted } from "@vue/reactivity";
import { db } from "../firebase/config";
import { doc, updateDoc } from "firebase/firestore";

export default {
    name: "EditTeamModal",
    props: ["modalId", "team", "season"],
    // emits: ['handleClick'],
    setup(props, context) {
        const spinner = ref(false)
        const closeModal = ref(null)
        const modalText = ref()
        const teamWins = ref(props.team.wins)
        const teamLoses = ref(props.team.loses)
        modalText.value = props.buttonText

        function closeMdl() {
            spinner.value = false
            closeModal.value.click()
        }

        const handleButton = async () => {
            // context.emit('handleClick', teamWins.value)
            spinner.value = true
            const refteam = doc(db, "season", props.season.id, "teams", props.team.id)
            try {
                await updateDoc(refteam, {
                    wins: teamWins.value,
                    loses: teamLoses.value
                }).then(() => {
                    closeMdl()
                })
            } catch (error) {
                console.log(error);
                closeMdl()
            }
        }

        // onMounted(() => {
        //     spinner.value = false
        // })

        return { spinner, closeModal, modalText, teamWins, teamLoses, closeMdl, handleButton }
    }

}
</script>
<style></style>