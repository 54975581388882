<template>
    <div class="home container-fluid p-1">
        <Spinner v-if="!isReady" color="primary" />
        <div v-else>
            <div class="row card shadow">
                <div class="d-flex card-header mx-auto justify-content-between">
                    <h3 v-if="season" class="text-dark col-md-8">Αρχείο {{  season.name  }}</h3>
                </div>
                <div class="col-md-8 card-body mx-auto rounded mt-3">
                    <div class="row">
                        <div class="col list-group-item list-group-item-action border-bottom ">
                            <div class="row justify-content-between">
                                <span class="col-1 fw-bold">#</span>
                                <span class="col-3 text-start fw-bold">Name</span>
                                <span class="col-2 text-end text-success fw-bold">Wins</span>
                                <span class="col-2 text-end text-danger">Loses</span>
                                <span class="col-2 fw-bold">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="row" :class="{ 'bg-light': (index % 2) === 0 }" v-for="(player, index) in players"
                        :key="player.id">
                        <div class="col list-group-item list-group-item-action">
                            <div class="row justify-content-between">
                                <span v-if="index === 0 && champion" class="col-1 my-auto"><i
                                        class="text-warning bi bi-trophy-fill"></i></span>
                                <span v-else class="col-1 my-auto">{{  index + 1  }}</span>
                                <span class="col-4 text-start d-flex justify-content-start align-items-center">
                                    <img v-if="player.photoURL" :src="player.photoURL"
                                        style="max-width: 30px; max-height: 30px;" alt="photoURL"
                                        class="img-fluid rounded me-1" data-bs-toggle="modal"
                                        :data-bs-target="`#lightbox${index}`" />
                                    <i v-else class="bi bi-person-circle me-1"></i>
                                    {{  player.name  }}
                                </span>
                                <teleport to="body">
                                    <div class="modal fade" :id="`lightbox${index}`" tabindex="-1"
                                        aria-labelledby="modal" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered modal-lg">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <i class="fas fa-lg fa-times" data-bs-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </div>
                                                <div class="modal-body mx-auto">
                                                    <img v-if="player.photoURL" :src="player.photoURL" alt="photoURL"
                                                        class="img-fluid rounded" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </teleport>
                                <span class="col-2 text-center text-success fw-bold my-auto">{{  player.wins  }}</span>
                                <span class="col-2 text-center text-danger my-auto">{{  player.loses  }}</span>
                                <span class="col-2 fw-bold my-auto">{{  player.percent  }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 mx-auto">
                    <div class="col d-flex justify-content-start mt-4">
                        <h4>Χωρίς Κατάταξη</h4>
                    </div>
                    <div class="row justify-content-between border-bottom border-2">
                        <span class="col-1 fw-bold">#</span>
                        <span class="col-3 text-start fw-bold">Name</span>
                        <span class="col-2 text-end text-success fw-bold">Wins</span>
                        <span class="col-2 text-end text-danger">Loses</span>
                        <span class="col-2 fw-bold">%</span>
                    </div>
                    <div class="row" :class="{ 'bg-light': (index % 2) === 0 }"
                        v-for="(player, index) in unorderedPlayers" :key="player.id">
                        <div class="col list-group-item list-group-item-action">
                            <div class="row justify-content-between">
                                <span class="col-1 fw-bold my-auto">-</span>
                                <span class="col-4 text-start d-flex justify-content-start align-items-center">
                                    <img v-if="player.photoURL" :src="player.photoURL"
                                        style="max-width: 30px; max-height: 30px;" alt="photoURL"
                                        class="img-fluid rounded me-1" data-bs-toggle="modal"
                                        :data-bs-target="`#lightbox2${index}`" />
                                    <i v-else class="bi bi-person-circle me-1"></i>
                                    {{  player.name  }}
                                </span>
                                <teleport to="body">
                                    <div class="modal fade" :id="`lightbox2${index}`" tabindex="-1"
                                        aria-labelledby="modal" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered modal-lg">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <i class="fas fa-lg fa-times" data-bs-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </div>
                                                <div class="modal-body mx-auto">
                                                    <img v-if="player.photoURL" :src="player.photoURL" alt="photoURL"
                                                        class="img-fluid rounded" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </teleport>
                                <span class="col-2 text-center text-success fw-bold my-auto">{{  player.wins  }}</span>
                                <span class="col-2 text-center text-danger my-auto">{{  player.loses  }}</span>
                                <span class="col-2 fw-bold my-auto">-</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Teams -->
            <div class="row card shadow">
                <div class="d-flex card-header mx-auto justify-content-start">
                    <h3 class="col-md-8">Ομάδες</h3>
                </div>
                <div class="card-body col-md-8 mx-auto">
                    <!-- <h6 class="text-danger text-start">*Τα στατιστικά υπολογίζονται από 5-1-2022</h6> -->
                    <div class="mb-4">
                        <div class="row">
                            <div class="col list-group-item list-group-item-action border-bottom ">
                                <div class="row justify-content-between">
                                    <span class="col-1 fw-bold">#</span>
                                    <span class="col-4 text-start fw-bold">Name</span>
                                    <span class="col-2 text-end text-success fw-bold">Wins</span>
                                    <span class="col-2 text-end text-danger">Loses</span>
                                    <span class="col-2 fw-bold">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" :class="{ 'bg-light': (index % 2) === 0 }"
                            v-for="(team, index) in orderedTeams" :key="team.id">
                            <div class="col list-group-item list-group-item-action" to="/teams">
                                <div class="row justify-content-between">
                                    <span v-if="index === 0 && season.teamChampion" class="col-1 my-auto"><i
                                            class="text-warning bi bi-trophy-fill"></i></span>
                                    <span v-else class="col-1">{{  index + 1  }}</span>
                                    <span class="col-4 text-start">{{  team.name  }}</span>
                                    <span class="col-2 text-end text-success fw-bold">{{  team.wins  }}</span>
                                    <span class="col-2 text-end text-danger">{{  team.loses  }}</span>
                                    <span class="col-2 fw-bold">{{  team.percent  }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="col d-flex justify-content-start mt-4">
                            <h4>Χωρίς Κατάταξη</h4>
                        </div>
                        <div class="row justify-content-between border-bottom border-2">
                            <span class="col-1 fw-bold">#</span>
                            <span class="col-4 text-start fw-bold">Name</span>
                            <span class="col-2 text-end text-success fw-bold">Wins</span>
                            <span class="col-2 text-end text-danger">Loses</span>
                            <span class="col-2 fw-bold">%</span>
                        </div>
                        <div class="row" :class="{ 'bg-light': (index % 2) === 0 }"
                            v-for="(team, index) in unorderedTeams" :key="team.id">
                            <router-link class="col btn" to="/teams">
                                <div class="row justify-content-between">
                                    <span class="col-1 fw-bold">-</span>
                                    <span class="col-4 text-start">{{  team.name  }}</span>
                                    <span class="col-2 text-end text-success fw-bold">{{  team.wins  }}</span>
                                    <span class="col-2 text-end text-danger">{{  team.loses  }}</span>
                                    <span class="col-2 fw-bold">-</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Stats -->
            <div class="row card shadow">
                <div class="d-flex card-header justify-content-start mx-auto">
                    <h3 class="col-md-8">Στατιστικά</h3>
                </div>
                <div class="card-body col-md-8 mx-auto" id="stats">
                    <ul class="nav nav-pills mx-2">
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: statsTab === 'player' }" aria-current="page"
                                href="#stats" @click="toggleStats('player')">Παικτών</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: statsTab === 'teams' }" href="#stats"
                                @click="toggleStats('teams')">Ομάδων</a>
                        </li>
                    </ul>
                    <!-- <h6 class="text-danger text-start">*Τα στατιστικά υπολογίζονται από 5-1-2022</h6> -->
                    <!-- accordion -->
                    <div v-if="statsTab === 'player'" class="accordion" id="accordionExample">
                        <div v-if="playersStats.dekakalo[0]" class="accordion-item my-2">
                            <div class="card text-start">
                                <div class="card-body bg-light bg-light">
                                    <h5 class="card-title text-secondary" id="headingOne">Δέκα καλό</h5>
                                    <button data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne"
                                        class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary align-items-center">
                                        <span class="col">
                                            <img v-if="playersStats.dekakalo[0].photoURL"
                                                :src="playersStats.dekakalo[0].photoURL" style="max-width: 30px;"
                                                alt="photoURL" class="img-fluid rounded me-1" />
                                            <i v-else class="bi bi-person-circle me-1"></i>
                                            {{  playersStats.dekakalo[0].name  }}
                                        </span>
                                        <span class="fw-bold mx-1">{{  playersStats.dekakalo[0].dekakalo  }} %</span>
                                        <!-- <span><i class="fas fa-lg fa-chevron-right"></i></span> -->
                                    </button>
                                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne">
                                        <div class="    ">
                                            <table class="table shadow rounded">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="text-start">Name</th>
                                                        <th scope="col" class="text-end">
                                                            <img src="../../public/10-diamond.png" alt="10-diamond"
                                                                class="mx-auto img-responsive img-fluid"
                                                                style="max-width: 30px;" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(player, index) in playersStats.dekakalo"
                                                        :key="player.id">
                                                        <td class="text-start">
                                                            <img v-if="player.photoURL" :src="player.photoURL"
                                                                style="max-width: 30px;" alt="photoURL"
                                                                class="img-fluid rounded me-1" />
                                                            <i v-else class="bi bi-person-circle me-1"></i>
                                                            {{  player.name  }}
                                                        </td>
                                                        <td class="text-end">
                                                            <div class="tooltip-left"
                                                                :data-tooltip="player.totalGames >= 5 ? (player.count + '/' + player.countLots + ' σε ' + player.totalGames + (player.totalGames === 1 ? ' παιχνίδι' : ' παιχνίδια')) : ('Λιγότερα από 5 παιχνίδια...')">
                                                                <i class="bi bi-info-circle me-1" focusable="false"
                                                                    aria-hidden="true"></i>
                                                            </div>
                                                            <span class="fw-bold">{{  player.dekakalo  }}%</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="playersStats.winsSeries[0]" class="accordion-item my-2">
                            <div class="card text-start">
                                <div class="card-body bg-light">
                                    <h5 class="card-title text-secondary" id="headingTwo">Μεγαλύτερο σερί νικών</h5>
                                    <button data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true"
                                        aria-controls="collapseTwo"
                                        class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                        <span class="col">
                                            <img v-if="playersStats.winsSeries[0].photoURL"
                                                :src="playersStats.winsSeries[0].photoURL" style="max-width: 30px;"
                                                alt="photoURL" class="img-fluid rounded me-1" />
                                            <i v-else class="bi bi-person-circle me-1"></i>
                                            {{  playersStats.winsSeries[0].name  }}
                                        </span>
                                        <span class="fw-bold mx-1">{{  playersStats.winsSeries[0].winsSeries  }}</span>
                                    </button>
                                    <div id="collapseTwo" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="">
                                            <table class="table shadow rounded">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="text-start">Name</th>
                                                        <th scope="col" class="text-end">Σερί νικών</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(player, index) in playersStats.winsSeries"
                                                        :key="player.id">
                                                        <td class="text-start">
                                                            <img v-if="player.photoURL" :src="player.photoURL"
                                                                style="max-width: 30px;" alt="photoURL"
                                                                class="img-fluid rounded me-1" />
                                                            <i v-else class="bi bi-person-circle me-1"></i>
                                                            {{  player.name  }}
                                                        </td>
                                                        <td class="text-end">{{  player.winsSeries  }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="playersStats.losesSeries[0]" class="accordion-item my-2">
                            <div class="card text-start">
                                <div class="card-body bg-light">
                                    <h5 class="card-title text-secondary" id="headingThree">Μεγαλύτερο σερί ηττών</h5>
                                    <button data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                        aria-expanded="true" aria-controls="collapseThree"
                                        class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                        <span class="col">
                                            <img v-if="playersStats.losesSeries[0].photoURL"
                                                :src="playersStats.losesSeries[0].photoURL" style="max-width: 30px;"
                                                alt="photoURL" class="img-fluid rounded me-1" />
                                            <i v-else class="bi bi-person-circle me-1"></i>
                                            {{  playersStats.losesSeries[0].name  }}
                                        </span>
                                        <span class="fw-bold mx-1">{{  playersStats.losesSeries[0].lostSeries  }}</span>
                                    </button>
                                    <div id="collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="">
                                            <table class="table shadow rounded">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="text-start">Name</th>
                                                        <th scope="col" class="text-end">Σερί ηττών</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(player, index) in playersStats.losesSeries"
                                                        :key="player.id">
                                                        <td class="text-start">
                                                            <img v-if="player.photoURL" :src="player.photoURL"
                                                                style="max-width: 30px;" alt="photoURL"
                                                                class="img-fluid rounded me-1" />
                                                            <i v-else class="bi bi-person-circle me-1"></i>
                                                            {{  player.name  }}
                                                        </td>
                                                        <td class="text-end">{{  player.lostSeries  }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="longest[0]" class="accordion-item my-2">
                            <div class="card text-start">
                                <div class="card-body bg-light">
                                    <h5 class="card-title text-secondary" id="headingFour">Μεγαλύτερη διάρκεια</h5>
                                    <button data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                        aria-expanded="true" aria-controls="collapseFour"
                                        class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                        <span class="col">Περισσότερες παρτίδες:</span>
                                        <span class="fw-bold mx-1">{{  longest[0].totalLots  }}</span>
                                    </button>
                                    <div id="collapseFour" class="accordion-collapse collapse"
                                        aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div class="">
                                            <router-link v-if="longest.length"
                                                class="list-group-item list-group-item-action"
                                                v-for="(game, index) in longest" :key="index"
                                                :to="{ name: 'GameDetails', params: { id: game.id } }">
                                                <div class="row">
                                                    <div class="col-2 d-flex align-items-center border-end"
                                                        style="min-width: 100px;">{{
                                                         game.timestamp 
                                                        }}</div>
                                                    <div class="col">
                                                        <div class="col d-flex justify-content-between"
                                                            :class="{ bold: game.team1.win }">
                                                            <span>{{  game.teamName1  }}</span>
                                                            <span>{{  game.team1.sum  }}</span>
                                                        </div>
                                                        <div class="col d-flex justify-content-between"
                                                            :class="{ bold: game.team2.win }">
                                                            <span>{{  game.teamName2  }}</span>
                                                            <span>{{  game.team2.sum  }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="statsTab === 'teams'" class="accordion mx-auto" id="accordionTeams">
                        <div v-if="teamsStats.dekakalo[0]" class="accordion-item my-2">
                            <div class="card text-start">
                                <div class="card-body bg-light bg-light">
                                    <h5 class="card-title text-secondary" id="headingTeamsOne">Δέκα καλό</h5>
                                    <button data-bs-toggle="collapse" data-bs-target="#collapseTeamsOne"
                                        aria-expanded="true" aria-controls="collapseTeamsOne"
                                        class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                        <span class="col">{{  teamsStats.dekakalo[0].name  }}</span>
                                        <span class="fw-bold mx-1">{{  teamsStats.dekakalo[0].dekakalo  }} %</span>
                                    </button>
                                    <div id="collapseTeamsOne" class="accordion-collapse collapse"
                                        aria-labelledby="headingTeamsOne" data-bs-parent="#accordionTeams">
                                        <div class="">
                                            <table class="table shadow rounded">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="text-start">Name</th>
                                                        <th scope="col" class="text-end">
                                                            <img src="../../public/10-diamond.png" alt="10-diamond"
                                                                class="img-thumbnail mx-auto img-responsive img-fluid"
                                                                style="max-width: 30px;" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(team, index) in teamsStats.dekakalo" :key="team.id">
                                                        <td class="text-start">{{  team.name  }}</td>
                                                        <td class="text-end">
                                                            <div class="tooltip-left"
                                                                :data-tooltip="team.count + '/' + team.countLots + ' σε ' + team.totalGames + (team.totalGames === 1 ? ' παιχνίδι' : ' παιχνίδια')">
                                                                <i class="bi bi-info-circle me-1" focusable="false"
                                                                    aria-hidden="true"></i>
                                                            </div>
                                                            <span class="fw-bold">{{  team.dekakalo  }}%</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="teamsStats.winsSeries[0]" class="accordion-item my-2">
                            <div class="card text-start">
                                <div class="card-body bg-light">
                                    <h5 class="card-title text-secondary" id="headingTeamsTwo">Μεγαλύτερο σερί νικών
                                    </h5>
                                    <button data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                        aria-expanded="true" aria-controls="collapseThree"
                                        class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                        <span class="col">{{  teamsStats.winsSeries[0].name  }}</span>
                                        <span class="fw-bold mx-1">{{  teamsStats.winsSeries[0].winsSeries  }}</span>
                                    </button>
                                    <div id="collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="headingTeamsTwo" data-bs-parent="#accordionTeams">
                                        <div class="">
                                            <table class="table shadow rounded">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="text-start">Name</th>
                                                        <th scope="col" class="text-end">Σερί νικών</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(team, index) in teamsStats.winsSeries" :key="team.id">
                                                        <td class="text-start">{{  team.name  }}</td>
                                                        <td class="text-end">{{  team.winsSeries  }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div v-if="teamsStats.losesSeries[0]" class="accordion-item my-2">
                            <div class="card text-start">
                                <div class="card-body bg-light">
                                    <h5 class="card-title text-secondary" id="headingTeamsFour">Μεγαλύτερο σερί ηττών
                                    </h5>
                                    <button data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                        aria-expanded="true" aria-controls="collapseFour"
                                        class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                        <span class="col">{{  teamsStats.losesSeries[0].name  }}</span>
                                        <span class="fw-bold mx-1">{{  teamsStats.losesSeries[0].lostSeries  }}</span>
                                    </button>
                                    <div id="collapseFour" class="accordion-collapse collapse"
                                        aria-labelledby="headingTeamsFour" data-bs-parent="#accordionTeams">
                                        <div class="">
                                            <table class="table shadow rounded">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="text-start">Name</th>
                                                        <th scope="col" class="text-end">Σερί ηττών</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(team, index) in teamsStats.losesSeries" :key="team.id">
                                                        <td class="text-start">{{  team.name  }}</td>
                                                        <td class="text-end">{{  team.lostSeries  }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Head2Head -->
            <div class="row card shadow">
                <div class="d-flex card-header justify-content-start mx-auto">
                    <h3 class="col-md-8">Head to Head</h3>
                </div>
                <div class="row card-body col-md-8 mx-auto" id="head2head">
                    <ul class="nav nav-pills mx-2">
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: statsTab === 'player' }" aria-current="page"
                                href="#head2head" @click="toggleHead2head('player')">Παικτών</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: statsTab === 'teams' }" href="#head2head"
                                @click="toggleHead2head('teams')">Ομάδων</a>
                        </li>
                    </ul>
                    <h6 class="text-danger text-start">*Τα στατιστικά υπολογίζονται από 5-1-2022</h6>
                    <div v-if="statsTab === 'player'" class="row">
                        <div class="col-md my-2 input-group">
                            <label class="input-group-text" for="selectP1">Παίκτης 1</label>
                            <select class="form-select bg-primary text-white" id="selectP1" v-model="player1Id"
                                @change="player2Id = null">
                                <option class="form-select" v-for="(player, index) in totalPlayers" :key="player.id"
                                    :value="player.id">{{  player.name  }}</option>
                            </select>
                        </div>
                        <div class="col-md my-2 input-group">
                            <label class="input-group-text" for="selectP2">Παίκτης 2</label>
                            <select class="form-select bg-danger text-white" id="selectP2" v-model="player2Id"
                                @change="handleP2change" :disabled="!player1Id">
                                <option class="form-select" v-for="(player, index) in filtP2" :key="player.id"
                                    :value="player.id">{{  player.name  }}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="player2Id" class="row me-auto mt-3">
                        <div v-if="isPending" class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden"></span>
                        </div>
                        <div v-else>
                            <h5 class="text-secondary">Νίκες</h5>
                            <div class="progress" style="height: 40px;">
                                <div class="progress-bar pe-2 fw-bold fs-5" role="progressbar"
                                    :style="'width: ' + barPlayer1 + '%;'" :aria-valuenow="barPlayer1" aria-valuemin="0"
                                    aria-valuemax="100">
                                    <span>{{  player1.h2hWins  }}</span>
                                </div>
                                <div class="progress-bar bg-danger fw-bold fs-5" role="progressbar"
                                    :style="'width: ' + barPlayer2 + '%;'" :aria-valuenow="barPlayer2" aria-valuemin="0"
                                    aria-valuemax="100">
                                    <span>{{  player2.h2hWins  }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="statsTab === 'teams'" class="row">
                        <div class="dropdown input-group my-2">
                            <label class="input-group-text" for="player1">Ομάδα 1</label>
                            <input class="form-select bg-primary text-white" type="text" name id="player1"
                                aria-expanded="false" data-bs-toggle="dropdown" v-model="team1Name"
                                @change="handleTeam1Change" autocomplete="off" />
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <li class="btn list-group-item list-group-item-action"
                                    v-for="(team, index) in searchTeam1" :key="team.id" @click="selectTeam(index)">{{
                                     team.name  }}</li>
                            </ul>
                        </div>
                        <div class="dropdown input-group my-2">
                            <label class="input-group-text" for="player2"
                                :class="{ 'text-secondary': !team1Name }">Ομάδα
                                2</label>
                            <input class="form-select bg-danger text-white" type="text" name id="player1"
                                aria-expanded="false" data-bs-toggle="dropdown" v-model="team2Name"
                                @change="handleTeam2Change" :disabled="!team1Name" autocomplete="off" />
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <li class="btn list-group-item list-group-item-action"
                                    v-for="(team, index) in filtTeam2" :key="team.id" @click="selectTeam2(index)">{{
                                     team.name  }}</li>
                            </ul>
                        </div>
                        <div v-if="team2" class="me-auto mt-3">
                            <div v-if="isPending" class="spinner-border text-secondary" role="status">
                                <span class="visually-hidden"></span>
                            </div>
                            <div v-else>
                                <h5 class="text-secondary">Νίκες</h5>
                                <div class="progress" style="height: 40px;">
                                    <div class="progress-bar pe-2 fw-bold fs-5" role="progressbar"
                                        :style="'width: ' + barTeam1 + '%;'" :aria-valuenow="barTeam1" aria-valuemin="0"
                                        aria-valuemax="100">
                                        <span>{{  team1.h2hWins  }}</span>
                                    </div>
                                    <div class="progress-bar bg-danger fw-bold fs-5" role="progressbar"
                                        :style="'width: ' + barTeam2 + '%;'" :aria-valuenow="barTeam2" aria-valuemin="0"
                                        aria-valuemax="100">
                                        <span>{{  team2.h2hWins  }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Games -->
            <div class="row card shadow">
                <div class="d-flex card-header mx-auto justify-content-start">
                    <h3 class="col-md-8">Παιχνίδια</h3>
                </div>
                <div class="card-body col-md-8 mx-auto">
                    <div class="row">
                        <div class="col">
                            <nav aria-label="Page navigation">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: activePage === 1 }">
                                        <button class="page-link" @click="activePage = 1" :disabled="activePage === 1">
                                            <span aria-hidden="true">&laquo;</span>
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: activePage === 1 }">
                                        <button class="page-link" @click="activePage--" :disabled="activePage === 1">
                                            <span aria-hidden="true">&lt;</span>
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ active: activePage === page }"
                                        v-for="page in computedPages" :key="page">
                                        <button class="page-link" @click="activePage = page">{{  page  }}</button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: activePage === pages }">
                                        <button class="page-link" @click="activePage++"
                                            :disabled="activePage === pages">
                                            <span aria-hidden="true">&gt;</span>
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: activePage === pages }">
                                        <button class="page-link" @click="activePage = pages"
                                            :disabled="activePage === pages">
                                            <span aria-hidden="true">&raquo;</span>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <router-link v-if="games" class="list-group-item list-group-item-action"
                                v-for="(game, index) in paginatedGames" :key="index"
                                :to="{ name: 'GameDetails', params: { id: game.id } }">
                                <div class="row">
                                    <div v-if="game.live"
                                        class="col-2 d-flex justify-content-center align-items-center border-end"
                                        style="min-width: 100px;">
                                        <h4 class="text-danger pulse">LIVE</h4>
                                    </div>
                                    <div v-else class="col-2 d-flex align-items-center border-end"
                                        style="min-width: 100px;">{{
                                         game.timestamp 
                                        }}</div>
                                    <div class="col">
                                        <div class="col d-flex justify-content-between"
                                            :class="{ bold: game.team1.win }">
                                            <span>{{  game.teamName1  }}</span>
                                            <span>{{  game.team1.sum  }}</span>
                                        </div>
                                        <div class="col d-flex justify-content-between"
                                            :class="{ bold: game.team2.win }">
                                            <span>{{  game.teamName2  }}</span>
                                            <span>{{  game.team2.sum  }}</span>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <nav aria-label="Page navigation">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: activePage === 1 }">
                                        <button class="page-link" @click="activePage = 1" :disabled="activePage === 1">
                                            <span aria-hidden="true">&laquo;</span>
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: activePage === 1 }">
                                        <button class="page-link" @click="activePage--" :disabled="activePage === 1">
                                            <span aria-hidden="true">&lt;</span>
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ active: activePage === page }"
                                        v-for="page in computedPages" :key="page">
                                        <button class="page-link" @click="activePage = page">{{  page  }}</button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: activePage === pages }">
                                        <button class="page-link" @click="activePage++"
                                            :disabled="activePage === pages">
                                            <span aria-hidden="true">&gt;</span>
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: activePage === pages }">
                                        <button class="page-link" @click="activePage = pages"
                                            :disabled="activePage === pages">
                                            <span aria-hidden="true">&raquo;</span>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex"
import { onUnmounted, watchEffect } from "vue";
// @ is an alias to /src
import { db } from "../firebase/config";
import { collection, getDocs, query, orderBy, doc, setDoc, getDoc, addDoc, updateDoc } from "firebase/firestore";
import getSeasons from "../composables/getSeasons";
import Spinner from "../components/Spinner.vue";
import Modal from "../components/Modal.vue";
import { useRoute } from "vue-router";

export default {
    name: "SeasonArchive",
    components: { Spinner, Modal },
    setup() {
        const store = useStore()
        const route = useRoute()
        store.commit('setSidebarToggle', true)
        const seasonId = route.params.seasonId
        console.log(seasonId);

        store.dispatch("seasonStats", seasonId)

        const playersStats = computed(() => {
            count = 0
            countLots = 0
            docs = []
            winsDocs = []
            losesDocs = []
            winsSeries = 0
            countWins = 0
            lostSeries = 0
            countLoses = 0
            totalPlayers.value.forEach((player) => {
                // get every game in which the player has played
                getGames = games.value.filter(game => game.player1.id === player.id || game.player2.id === player.id || game.player3.id === player.id || game.player4.id === player.id)
                if (getGames.length) {
                    // count how many times the player had deka kalo
                    count = getGames.map(game => game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === player.id).length).reduce((prev, next) => prev + next)
                    // count every lot the player has played
                    getGames.forEach((game, index) => {
                        countLots = countLots + game.lots.length
                        switch (player.id) {
                            case game.player1.id:
                                game.player1.win ? winsFunction(index, game) : lostFunction(index, game)
                                break;
                            case game.player2.id:
                                game.player2.win ? winsFunction(index, game) : lostFunction(index, game)
                                break;
                            case game.player3.id:
                                game.player3.win ? winsFunction(index, game) : lostFunction(index, game)
                                break;
                            case game.player4.id:
                                game.player4.win ? winsFunction(index, game) : lostFunction(index, game)
                                break;
                        }
                    })
                }
                // calculate deka kalo percent for each player and turn it to number
                if (getGames.length >= 5) {
                    player.dekakalo = parseFloat((((count / countLots) || 0) * 100).toFixed(2))
                } else player.dekakalo = 0
                player.totalGames = getGames.length
                player.count = count
                player.countLots = countLots
                player.winsSeries = winsSeries
                player.lostSeries = lostSeries
                if (!docs) {
                    docs.push(player)
                } else {
                    let i = 0;
                    while (i < docs.length && player.dekakalo < docs[i].dekakalo) {
                        i++
                    }
                    docs.splice(i, 0, player)
                }
                if (!winsDocs) {
                    winsDocs.push(player)
                } else {
                    let i = 0;
                    while (i < winsDocs.length && player.winsSeries < winsDocs[i].winsSeries) {
                        i++
                    }
                    winsDocs.splice(i, 0, player)
                }
                if (!losesDocs) {
                    losesDocs.push(player)
                } else {
                    let i = 0;
                    while (i < losesDocs.length && player.lostSeries < losesDocs[i].lostSeries) {
                        i++
                    }
                    losesDocs.splice(i, 0, player)
                }

                countLots = 0;
                count = 0;
                winsSeries = 0
                countWins = 0
                lostSeries = 0
                countLoses = 0
            })

            return { dekakalo: docs, winsSeries: winsDocs, losesSeries: losesDocs }
            // orderByDekakalo.value = docs
            // orderByWinsSeries.value = winsDocs
            // orderByLosesSeries.value = losesDocs
        })

        const teamsStats = computed(() => {
            count = 0
            countLots = 0
            docsTeams = []
            winsTeamsDocs = []
            losesTeamsDocs = []
            winsSeries = 0
            countWins = 0
            lostSeries = 0
            countLoses = 0
            teams.value.forEach(team => {
                getGames = games.value.filter(game => game.team1.id === team.id || game.team2.id === team.id)

                getGames.forEach((game, index) => {
                    countLots = countLots + game.lots.length
                    if (team.id === game.team1.id) {
                        count = count + game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === game.player1.id).length + game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === game.player2.id).length
                        game.team1.win ? winsFunction(index, game) : lostFunction(index, game)

                    } else if (team.id === game.team2.id) {
                        count = count + game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === game.player3.id).length + game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === game.player4.id).length
                        game.team2.win ? winsFunction(index, game) : lostFunction(index, game)
                    }
                })
                team.dekakalo = parseFloat((((count / countLots) || 0) * 100).toFixed(2))
                team.count = count
                team.countLots = countLots
                team.totalGames = getGames.length
                team.winsSeries = winsSeries
                team.lostSeries = lostSeries

                if (!docsTeams) {
                    docsTeams.push(team)
                } else {
                    let i = 0;
                    while (i < docsTeams.length && team.dekakalo < docsTeams[i].dekakalo) {
                        i++
                    }
                    docsTeams.splice(i, 0, team)
                }
                if (!winsTeamsDocs) {
                    winsTeamsDocs.push(team)
                } else {
                    let i = 0;
                    while (i < winsTeamsDocs.length && team.winsSeries < winsTeamsDocs[i].winsSeries) {
                        i++
                    }
                    winsTeamsDocs.splice(i, 0, team)
                }
                if (!losesTeamsDocs) {
                    losesTeamsDocs.push(team)
                } else {
                    let i = 0;
                    while (i < losesTeamsDocs.length && team.lostSeries < losesTeamsDocs[i].lostSeries) {
                        i++
                    }
                    losesTeamsDocs.splice(i, 0, team)
                }

                countLots = 0;
                count = 0;
                winsSeries = 0
                countWins = 0
                lostSeries = 0
                countLoses = 0
            })

            return { dekakalo: docsTeams, winsSeries: winsTeamsDocs, losesSeries: losesTeamsDocs }

            // orderTeamsByDekakalo.value = docsTeams
            // orderTeamsByWinsSeries.value = winsTeamsDocs
            // orderTeamsByLosesSeries.value = losesTeamsDocs

        })

        const longest = computed(() => {
            if (games.value.length) {
                let docs = []
                // find longest games
                games.value.forEach(game => {
                    if (!docs.length) {
                        docs.push({ totalLots: game.lots.length, ...game })
                    } else {
                        let i = 0;
                        while (i < docs.length && game.lots.length < docs[i].totalLots) {
                            i++
                        }
                        docs.splice(i, 0, { totalLots: game.lots.length, ...game })
                    }
                })
                let i = 0;
                if (docs.length > 1) {
                    while (i < docs.length && docs[i].totalLots === docs[i + 1].totalLots) {
                        i++
                    }
                    docs.splice(i + 1, docs.length - i)
                }

                return docs

            } else {
                store.commit('setError', 'Δεν υπάρχουν ακόμη παιχνίδια...')
                store.commit('setAlertColor', 'alert-danger')
            }

        })


        const season = computed(() => {
            return store.state.season.find(doc => doc.id === seasonId)
        })
        const user = computed(() => {
            return store.state.user
        })
        const admin = computed(() => {
            if (user.value) {
                return user.value.admin
            } else return false
        })
        const users = computed(() => {
            return store.state.users
        })
        const players = computed(() => {
            return Object.values(store.state.players)
        })
        const unorderedPlayers = computed(() => {
            return Object.values(store.state.unorderedPlayers)
        })
        const totalPlayers = computed(() => {
            return Object.values(store.state.totalPlayers)
        })
        const teams = computed(() => {
            return Object.values(store.state.totalTeams)
        })
        const unorderedTeams = computed(() => {
            return Object.values(store.state.unorderedTeams)
        })
        const orderedTeams = computed(() => {
            return Object.values(store.state.orderedTeams)
        })
        const games = computed(() => {
            return Object.values(store.state.games)
        })
        const error = computed(() => {
            return store.state.error
        })
        const isPending = computed(() => {
            return store.state.isPending
        })

        const isReady = computed(() => {
            return store.state.isReady
        })

        const champion = computed(() => {
            return !!season.value.champion
        })

        const orderByDekakalo = ref([])
        const orderByWinsSeries = ref([])
        const orderByLosesSeries = ref([])
        const orderTeamsByDekakalo = ref([])
        const orderTeamsByWinsSeries = ref([])
        const orderTeamsByLosesSeries = ref([])

        // const longest = ref([])
        const statsTab = ref('player')

        let getGames
        let count = 0
        let countLots = 0
        let docs = []
        let winsDocs = []
        let losesDocs = []
        let docsTeams = []
        let winsTeamsDocs = []
        let losesTeamsDocs = []
        let winsSeries = 0
        let countWins = 0
        let lostSeries = 0
        let countLoses = 0



        function winsFunction(index, game) {
            countLoses = 0
            countWins++
            if (winsSeries < countWins) {
                winsSeries = countWins
            }

        }

        function lostFunction(index, game) {
            countWins = 0
            countLoses++
            if (lostSeries < countLoses) {
                lostSeries = countLoses
            }

        }

        // const loadData = () => {



        const toggleStats = (stats) => {
            statsTab.value = stats
        }

        const player1Id = ref()
        const player2Id = ref()
        const team1 = ref()
        const team2 = ref()
        // const searchTeam1 = ref()
        const team1Name = ref()
        const team2Name = ref()
        const barPlayer1 = ref()
        const barPlayer2 = ref()
        const barTeam1 = ref()
        const barTeam2 = ref()

        // filter player2 list from player1 value
        const filtP2 = computed(() => {
            if (player1Id.value) {
                return totalPlayers.value.filter(player => player.id !== player1Id.value)
            }
        })

        // filter team2 list from team1 value
        const filtTeam2 = computed(() => {
            if (team1.value) {
                let teams2 = teams.value.filter(team => team.player1.id !== team1.value.player1.id && team.player1.id !== team1.value.player2.id)
                    .filter(team => team.player2.id !== team1.value.player1.id && team.player2.id !== team1.value.player2.id)
                if (team2Name.value) {
                    const search = teams2.filter(team => team.name.toUpperCase().includes(team2Name.value.toUpperCase()))
                    return search
                } else return teams2
            }
        })

        const player1 = computed(() => {
            return totalPlayers.value.filter(player => player.id === player1Id.value)[0]
        })

        const player2 = computed(() => {
            return totalPlayers.value.filter(player => player.id === player2Id.value)[0]
        })

        const handleP2change = () => {
            store.commit("setIsPending", true);
            // get every game where the players are rivals
            getGames = games.value.filter(game => (game.player1.id === player1.value.id || game.player2.id === player1.value.id) && (game.player3.id === player2.value.id || game.player4.id === player2.value.id))
                .concat(games.value.filter(game => (game.player1.id === player2.value.id || game.player2.id === player2.value.id) && (game.player3.id === player1.value.id || game.player4.id === player1.value.id)))

            if (!getGames.length) {
                store.commit("setIsPending", false);
                return (player1.value.h2hWins = 'Δε βρέθηκαν παιχνίδια', player2.value.h2hWins = '', barPlayer1.value = 100, barPlayer2.value = 0)
            }
            let player1Win = 0
            let player2Win = 0
            getGames.forEach(game => {
                switch (player1.value.id) {
                    case game.player1.id:
                        game.player1.win ? player1Win++ : player2Win++
                        break;
                    case game.player2.id:
                        game.player2.win ? player1Win++ : player2Win++
                        break;
                    case game.player3.id:
                        game.player3.win ? player1Win++ : player2Win++
                        break;
                    case game.player4.id:
                        game.player4.win ? player1Win++ : player2Win++
                        break;
                }
            })

            barPlayer1.value = Math.ceil((player1Win / getGames.length) * 100)
            barPlayer2.value = Math.floor((player2Win / getGames.length) * 100)
            if (barPlayer1.value === 100) {
                barPlayer1.value = 90
                barPlayer2.value = 10
            } else if (barPlayer1.value === 0) {
                barPlayer1.value = 10
                barPlayer2.value = 90
            }
            player1.value.h2hWins = player1Win
            player2.value.h2hWins = player2Win
            store.commit("setIsPending", false);
        }

        const handleTeam1Change = () => {
            team2Name.value = null
            team2.value = null
        }
        const handleTeam2Change = () => {
            team2.value = null
        }

        const searchTeam1 = computed(() => {
            if (team1Name.value) {
                const search = teams.value.filter(team => team.name.toUpperCase().includes(team1Name.value.toUpperCase()))
                return search;
            } else return teams.value
        })

        const selectTeam = (index) => {
            team1.value = searchTeam1.value[index]
            team1Name.value = team1.value.name
        }

        const selectTeam2 = (index) => {
            store.commit("setIsPending", true);

            team2.value = filtTeam2.value[index]
            team2Name.value = team2.value.name

            getGames = games.value.filter(game => game.team1.id === team1.value.id && game.team2.id === team2.value.id)
                .concat(games.value.filter(game => game.team1.id === team2.value.id && game.team2.id === team1.value.id))

            if (!getGames.length) {
                store.commit("setIsPending", false);

                return (team1.value.h2hWins = 'Δε βρέθηκαν παιχνίδια', team2.value.h2hWins = '', barTeam1.value = 100, barTeam2.value = 0)
            }

            let team1Win = 0
            let team2Win = 0

            getGames.forEach(game => {
                switch (team1.value.id) {
                    case game.team1.id:
                        game.team1.win ? team1Win++ : team2Win++
                        break;
                    case game.team2.id:
                        game.team2.win ? team1Win++ : team2Win++
                        break;
                }
            })

            barTeam1.value = Math.ceil((team1Win / getGames.length) * 100)
            barTeam2.value = Math.floor((team2Win / getGames.length) * 100)
            if (barTeam1.value === 100) {
                barTeam1.value = 90
                barTeam2.value = 10
            } else if (barTeam1.value === 0) {
                barTeam1.value = 10
                barTeam2.value = 90
            }
            team1.value.h2hWins = team1Win
            team2.value.h2hWins = team2Win
            store.commit("setIsPending", false);
        }

        const toggleHead2head = (value) => {
            statsTab.value = value
            player1Id.value = null
            player2Id.value = null
        }

        watchEffect(() => {
            handleTeam1Change(team1Name.value)
        })

        const activePage = ref(1)

        const pages = computed(() => {
            if (games.value) {
                // divide games in pages of 10 results - add 1 page if there is a remainder (%)
                return Math.floor(games.value.length / 10) + Math.ceil((games.value.length % 10) / 10)
            }
        })

        // compute which games to be displayed
        const paginatedGames = computed(() => {
            let i = activePage.value - 1
            return games.value.slice(i * 10, (i * 10) + 10)
        })

        // compute pagination
        const computedPages = computed(() => {
            switch (pages.value) {
                case 1:
                    return 1
                    break;
                case 2:
                    return 2
                    break;
            }
            if (pages.value) {
                if (activePage.value === 1) {
                    return 3
                } else if (activePage.value === pages.value) {
                    return [pages.value - 2, pages.value - 1, pages.value]
                } else {
                    return [activePage.value - 1, activePage.value, activePage.value + 1]
                }
            }
        })


        onUnmounted(() => {
            store.dispatch("seasonStats", store.state.season[0].id)
        })


        return {
            user,
            admin,
            season,
            champion,
            users,
            players,
            unorderedPlayers,
            totalPlayers,
            orderedTeams,
            unorderedTeams,
            games,
            error,
            isPending,
            isReady,
            toggleStats,
            orderByDekakalo,
            orderByLosesSeries,
            orderByWinsSeries,
            orderTeamsByDekakalo,
            orderTeamsByLosesSeries,
            orderTeamsByWinsSeries,
            orderedTeams,
            longest,
            statsTab,
            player1,
            player2,
            player1Id,
            player2Id,
            filtP2,
            barPlayer1,
            barPlayer2,
            barTeam1,
            barTeam2,
            team1Name,
            team2Name,
            filtTeam2,
            team1,
            team2,
            searchTeam1,
            handleP2change,
            handleTeam1Change,
            handleTeam2Change,
            selectTeam,
            selectTeam2,
            toggleHead2head,
            activePage,
            pages,
            computedPages,
            paginatedGames,
            playersStats,
            teamsStats
        };

    }


}
</script>
<style lang="">
    
</style>