<template>
    <div class="container-fluid p-1">
        <Spinner v-if="!isReady" color="primary" />
        <div v-else>
            <div class="row">
                <div class="d-flex col-md-8 justify-content-start mx-auto mt-3">
                    <h3 class="text-dark">Στατιστικά</h3>
                </div>
            </div>
            <div v-if="isPending" class="spinner-border text-secondary" role="status">
                <span class="visually-hidden"></span>
            </div>
            <div v-else class="col-md-8 mx-auto">
                <ul class="nav nav-pills mx-2">
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: statsTab === 'player' }" aria-current="page" href="#"
                            @click="toggleStats('player')">Παικτών</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: statsTab === 'teams' }" href="#"
                            @click="toggleStats('teams')">Ομάδων</a>
                    </li>
                </ul>
                <!-- <h6 class="text-danger text-start">*Τα στατιστικά υπολογίζονται από 5-1-2022</h6> -->
                <!-- accordion -->
                <div v-if="statsTab === 'player'" class="accordion" id="accordionExample">
                    <div v-if="playersStats.dekakalo[0]" class="accordion-item my-2">
                        <div class="card text-start">
                            <div class="card-body bg-light bg-light">
                                <h5 class="card-title text-secondary" id="headingOne">Δέκα καλό</h5>
                                <button data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne"
                                    class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary align-items-center">
                                    <span class="col">
                                        <img v-if="playersStats.dekakalo[0].photoURL"
                                            :src="playersStats.dekakalo[0].photoURL" style="max-width: 30px;"
                                            alt="photoURL" class="img-fluid rounded me-1" />
                                        <i v-else class="bi bi-person-circle me-1"></i>
                                        {{ playersStats.dekakalo[0].name }}
                                    </span>
                                    <span class="fw-bold mx-1">{{ playersStats.dekakalo[0].dekakalo }} %</span>
                                    <!-- <span><i class="fas fa-lg fa-chevron-right"></i></span> -->
                                </button>
                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne">
                                    <div class="    ">
                                        <table class="table shadow rounded">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-start">Name</th>
                                                    <th scope="col" class="text-end">
                                                        <img src="../../public/10-diamond.png" alt="10-diamond"
                                                            class="mx-auto img-responsive img-fluid"
                                                            style="max-width: 30px;" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(player, index) in playersStats.dekakalo" :key="player.id">
                                                    <td class="text-start">
                                                        <img v-if="player.photoURL" :src="player.photoURL"
                                                            style="max-width: 30px;" alt="photoURL"
                                                            class="img-fluid rounded me-1" />
                                                        <i v-else class="bi bi-person-circle me-1"></i>
                                                        {{ player.name }}
                                                    </td>
                                                    <td class="text-end">
                                                        <div class="tooltip-left"
                                                            :data-tooltip="player.totalGames >= 5 ? (player.count + '/' + player.countLots + ' σε ' + player.totalGames + (player.totalGames === 1 ? ' παιχνίδι' : ' παιχνίδια')) : ('Λιγότερα από 5 παιχνίδια...')">
                                                            <i class="bi bi-info-circle me-1" focusable="false"
                                                                aria-hidden="true"></i>
                                                        </div>
                                                        <span class="fw-bold">{{ player.dekakalo }}%</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="playersStats.form[0]" class="accordion-item my-2">
                        <div class="card text-start">
                            <div class="card-body bg-light">
                                <h5 class="card-title text-secondary" id="headingForma">Φόρμα</h5>
                                <button data-bs-toggle="collapse" data-bs-target="#collapseForma" aria-expanded="true"
                                    aria-controls="collapseForma"
                                    class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                    <span class="col">
                                        <img v-if="playersStats.form[0].photoURL" :src="playersStats.form[0].photoURL"
                                            style="max-width: 30px;" alt="photoURL" class="img-fluid rounded me-1" />
                                        <i v-else class="bi bi-person-circle me-1"></i>
                                        {{ playersStats.form[0].name }}
                                    </span>
                                    <span class="mx-1">
                                        <div v-for="(item, index) in playersStats.form[0].form" :key="index"
                                            class="tooltip-top"
                                            :data-tooltip="item.game.teamName1 + ' ' + item.game.team1.sum + ' - ' + item.game.team2.sum + ' ' + item.game.teamName2">
                                            <i class="mx-1 fs-5"
                                                :class="{ 'bi bi-check-square-fill text-success': item.win === true, 'bi bi-x-square-fill text-danger': item.win === false }"></i>
                                        </div>
                                    </span>
                                </button>
                                <div id="collapseForma" class="accordion-collapse collapse"
                                    aria-labelledby="headingForma" data-bs-parent="#accordionExample">

                                    <table class="table shadow rounded">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="text-start">Name</th>
                                                <th scope="col" class="text-end">Φόρμα</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(player, index) in playersStats.form" :key="player.id">
                                                <td class="text-start">
                                                    <img v-if="player.photoURL" :src="player.photoURL"
                                                        style="max-width: 30px;" alt="photoURL"
                                                        class="img-fluid rounded me-1" />
                                                    <i v-else class="bi bi-person-circle me-1"></i>
                                                    {{ player.name }}
                                                </td>
                                                <td class="text-end">
                                                    <div v-for="(item, index) in player.form" :key="index"
                                                        class="tooltip-top"
                                                        :data-tooltip="item.game.teamName1 + ' ' + item.game.team1.sum + ' - ' + item.game.team2.sum + ' ' + item.game.teamName2">
                                                        <i class="mx-1 fs-5"
                                                            :class="{ 'bi bi-check-square-fill text-success': item.win === true, 'bi bi-x-square-fill text-danger': item.win === false }"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-if="playersStats.winsSeries[0]" class="accordion-item my-2">
                        <div class="card text-start">
                            <div class="card-body bg-light">
                                <h5 class="card-title text-secondary" id="headingTwo">Μεγαλύτερο σερί νικών</h5>
                                <button data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true"
                                    aria-controls="collapseTwo"
                                    class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                    <span class="col">
                                        <img v-if="playersStats.winsSeries[0].photoURL"
                                            :src="playersStats.winsSeries[0].photoURL" style="max-width: 30px;"
                                            alt="photoURL" class="img-fluid rounded me-1" />
                                        <i v-else class="bi bi-person-circle me-1"></i>
                                        {{ playersStats.winsSeries[0].name }}
                                    </span>
                                    <span class="fw-bold mx-1">{{ playersStats.winsSeries[0].winsSeries }}</span>
                                </button>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="">
                                        <table class="table shadow rounded">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-start">Name</th>
                                                    <th scope="col" class="text-end">Σερί νικών</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(player, index) in playersStats.winsSeries" :key="player.id">
                                                    <td class="text-start">
                                                        <img v-if="player.photoURL" :src="player.photoURL"
                                                            style="max-width: 30px;" alt="photoURL"
                                                            class="img-fluid rounded me-1" />
                                                        <i v-else class="bi bi-person-circle me-1"></i>
                                                        {{ player.name }}
                                                    </td>
                                                    <td class="text-end">{{ player.winsSeries }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="playersStats.losesSeries[0]" class="accordion-item my-2">
                        <div class="card text-start">
                            <div class="card-body bg-light">
                                <h5 class="card-title text-secondary" id="headingThree">Μεγαλύτερο σερί ηττών</h5>
                                <button data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true"
                                    aria-controls="collapseThree"
                                    class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                    <span class="col">
                                        <img v-if="playersStats.losesSeries[0].photoURL"
                                            :src="playersStats.losesSeries[0].photoURL" style="max-width: 30px;"
                                            alt="photoURL" class="img-fluid rounded me-1" />
                                        <i v-else class="bi bi-person-circle me-1"></i>
                                        {{ playersStats.losesSeries[0].name }}
                                    </span>
                                    <span class="fw-bold mx-1">{{ playersStats.losesSeries[0].lostSeries }}</span>
                                </button>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="">
                                        <table class="table shadow rounded">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-start">Name</th>
                                                    <th scope="col" class="text-end">Σερί ηττών</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(player, index) in playersStats.losesSeries"
                                                    :key="player.id">
                                                    <td class="text-start">
                                                        <img v-if="player.photoURL" :src="player.photoURL"
                                                            style="max-width: 30px;" alt="photoURL"
                                                            class="img-fluid rounded me-1" />
                                                        <i v-else class="bi bi-person-circle me-1"></i>
                                                        {{ player.name }}
                                                    </td>
                                                    <td class="text-end">{{ player.lostSeries }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="longest[0]" class="accordion-item my-2">
                        <div class="card text-start">
                            <div class="card-body bg-light">
                                <h5 class="card-title text-secondary" id="headingFour">Μεγαλύτερη διάρκεια</h5>
                                <button data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true"
                                    aria-controls="collapseFour"
                                    class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                    <span class="col">Περισσότερες παρτίδες:</span>
                                    <span class="fw-bold mx-1">{{ longest[0].totalLots }}</span>
                                </button>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample">
                                    <div class="">
                                        <router-link v-if="longest.length"
                                            class="list-group-item list-group-item-action"
                                            v-for="(game, index) in longest" :key="index"
                                            :to="{ name: 'GameDetails', params: { id: game.id } }">
                                            <div class="row">
                                                <div class="col-2 d-flex align-items-center border-end"
                                                    style="min-width: 100px;">{{
                                                        game.timestamp
                                                    }}</div>
                                                <div class="col">
                                                    <div class="col d-flex justify-content-between"
                                                        :class="{ bold: game.team1.win }">
                                                        <span>{{ game.teamName1 }}</span>
                                                        <span>{{ game.team1.sum }}</span>
                                                    </div>
                                                    <div class="col d-flex justify-content-between"
                                                        :class="{ bold: game.team2.win }">
                                                        <span>{{ game.teamName2 }}</span>
                                                        <span>{{ game.team2.sum }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="statsTab === 'teams'" class="accordion mx-auto" id="accordionTeams">
                    <div v-if="teamsStats.dekakalo[0]" class="accordion-item my-2">
                        <div class="card text-start">
                            <div class="card-body bg-light bg-light">
                                <h5 class="card-title text-secondary" id="headingTeamsOne">Δέκα καλό</h5>
                                <button data-bs-toggle="collapse" data-bs-target="#collapseTeamsOne"
                                    aria-expanded="true" aria-controls="collapseTeamsOne"
                                    class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                    <span class="col">{{ teamsStats.dekakalo[0].name }}</span>
                                    <span class="fw-bold mx-1">{{ teamsStats.dekakalo[0].dekakalo }} %</span>
                                </button>
                                <div id="collapseTeamsOne" class="accordion-collapse collapse"
                                    aria-labelledby="headingTeamsOne" data-bs-parent="#accordionTeams">
                                    <div class="">
                                        <table class="table shadow rounded">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-start">Name</th>
                                                    <th scope="col" class="text-end">
                                                        <img src="../../public/10-diamond.png" alt="10-diamond"
                                                            class="img-thumbnail mx-auto img-responsive img-fluid"
                                                            style="max-width: 30px;" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(team, index) in teamsStats.dekakalo" :key="team.id">
                                                    <td class="text-start">{{ team.name }}</td>
                                                    <td class="text-end">
                                                        <div class="tooltip-left"
                                                        :data-tooltip="team.totalGames >= 5 ? (team.count + '/' + team.countLots + ' σε ' + team.totalGames + (team.totalGames === 1 ? ' παιχνίδι' : ' παιχνίδια')) : ('Λιγότερα από 5 παιχνίδια...')">
                                                            <i class="bi bi-info-circle me-1" focusable="false"
                                                                aria-hidden="true"></i>
                                                        </div>
                                                        <span class="fw-bold">{{ team.dekakalo }}%</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-if="teamsStats.form[0]" class="accordion-item my-2">
                        <div class="card text-start">
                            <div class="card-body bg-light">
                                <h5 class="card-title text-secondary" id="headingTeamsForma">Φόρμα</h5>
                                <button data-bs-toggle="collapse" data-bs-target="#collapseTeamsForma"
                                    aria-expanded="true" aria-controls="collapseTeamsForma"
                                    class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                    <span class="col">{{ teamsStats.form[0].name }}</span>
                                    <span class="mx-1">
                                        <div v-for="(item, index) in teamsStats.form[0].form" :key="index"
                                            class="tooltip-top"
                                            :data-tooltip="item.game.teamName1 + ' ' + item.game.team1.sum + ' - ' + item.game.team2.sum + ' ' + item.game.teamName2">
                                            <i class="mx-1 fs-5"
                                                :class="{ 'bi bi-check-square-fill text-success': item.win === true, 'bi bi-x-square-fill text-danger': item.win === false }"></i>
                                        </div>
                                    </span>
                                </button>
                                <div id="collapseTeamsForma" class="accordion-collapse collapse"
                                    aria-labelledby="headingTeamsForma" data-bs-parent="#accordionTeams">
                                    <div class="">
                                        <table class="table shadow rounded">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-start">Name</th>
                                                    <th scope="col" class="text-end">Φόρμα</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(team, index) in teamsStats.form" :key="team.id">
                                                    <td class="text-start">{{ team.name }}</td>
                                                    <td class="text-end">
                                                        <div v-for="(item, index) in team.form" :key="index"
                                                            class="tooltip-top"
                                                            :data-tooltip="item.game.teamName1 + ' ' + item.game.team1.sum + ' - ' + item.game.team2.sum + ' ' + item.game.teamName2">
                                                            <i class="mx-1 fs-5"
                                                                :class="{ 'bi bi-check-square-fill text-success': item.win === true, 'bi bi-x-square-fill text-danger': item.win === false }"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-if="teamsStats.winsSeries[0]" class="accordion-item my-2">
                        <div class="card text-start">
                            <div class="card-body bg-light">
                                <h5 class="card-title text-secondary" id="headingTeamsTwo">Μεγαλύτερο σερί νικών</h5>
                                <button data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true"
                                    aria-controls="collapseThree"
                                    class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                    <span class="col">{{ teamsStats.winsSeries[0].name }}</span>
                                    <span class="fw-bold mx-1">{{ teamsStats.winsSeries[0].winsSeries }}</span>
                                </button>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingTeamsTwo" data-bs-parent="#accordionTeams">
                                    <div class="">
                                        <table class="table shadow rounded">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-start">Name</th>
                                                    <th scope="col" class="text-end">Σερί νικών</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(team, index) in teamsStats.winsSeries" :key="team.id">
                                                    <td class="text-start">{{ team.name }}</td>
                                                    <td class="text-end">{{ team.winsSeries }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-if="teamsStats.losesSeries[0]" class="accordion-item my-2">
                        <div class="card text-start">
                            <div class="card-body bg-light">
                                <h5 class="card-title text-secondary" id="headingTeamsFour">Μεγαλύτερο σερί ηττών</h5>
                                <button data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true"
                                    aria-controls="collapseFour"
                                    class="accordion-button collapsed card-text shadow d-flex justify-content-between my-2 p-2 border rounded border-primary">
                                    <span class="col">{{ teamsStats.losesSeries[0].name }}</span>
                                    <span class="fw-bold mx-1">{{ teamsStats.losesSeries[0].lostSeries }}</span>
                                </button>
                                <div id="collapseFour" class="accordion-collapse collapse"
                                    aria-labelledby="headingTeamsFour" data-bs-parent="#accordionTeams">
                                    <div class="">
                                        <table class="table shadow rounded">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="text-start">Name</th>
                                                    <th scope="col" class="text-end">Σερί ηττών</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(team, index) in teamsStats.losesSeries" :key="team.id">
                                                    <td class="text-start">{{ team.name }}</td>
                                                    <td class="text-end">{{ team.lostSeries }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { watchEffect, watch, onUnmounted } from 'vue'
import { db } from "../firebase/config";
import { collection, getDocs, query, orderBy, doc, setDoc, } from "firebase/firestore";
import getSeasons from "../composables/getSeasons";
import { useStore } from "vuex";

export default {
    name: 'Stats',
    setup() {
        const store = useStore()
        store.commit('setSidebarToggle', true)

        store.commit('setIsPending', true)

        const error = computed(() => {
            return store.state.error;
        });
        const isPending = computed(() => {
            return store.state.isPending;
        });
        const isReady = computed(() => {
            return store.state.isReady;
        });
        const seasons = computed(() => {
            return store.state.season;
        });
        const orderByDekakalo = ref([])
        const orderByWinsSeries = ref([])
        const orderByLosesSeries = ref([])
        const orderByForm = ref([])
        const orderTeamsByDekakalo = ref([])
        const orderTeamsByWinsSeries = ref([])
        const orderTeamsByLosesSeries = ref([])
        const orderTeamsByForm = ref([])

        const players = computed(() => {
            return store.state.totalPlayers;
        });
        const games = computed(() => {
            return store.state.games;
        })
        const teams = computed(() => {
            return store.state.totalTeams;
        });

        let getGames
        let count = 0
        let countLots = 0
        let docs = []
        let winsDocs = []
        let losesDocs = []
        let formDocs = []
        let docsTeams = []
        let winsTeamsDocs = []
        let losesTeamsDocs = []
        let formTeamsDocs = []
        let winsSeries = 0
        let countWins = 0
        let lostSeries = 0
        let countLoses = 0
        let form = []

        const playersStats = computed(() => {
            // count = 0
            // countLots = 0
            // // docs = []
            // // winsDocs = []
            // // losesDocs = []
            // // formDocs = []
            // winsSeries = 0
            // countWins = 0
            // lostSeries = 0
            // countLoses = 0
            // form = []
            Object.values(players.value).forEach((player) => {
                // get every game in which the player has played
                getGames = Object.values(games.value).filter(game => game.player1.id === player.id || game.player2.id === player.id || game.player3.id === player.id || game.player4.id === player.id)
                if (getGames.length) {
                    // count how many times the player had deka kalo
                    count = getGames.map(game => game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === player.id).length).reduce((prev, next) => prev + next)
                    // count every lot the player has played
                    getGames.forEach((game, index) => {
                        countLots = countLots + game.lots.length
                        switch (player.id) {
                            case game.player1.id:
                                game.player1.win ? winsFunction(index, game) : lostFunction(index, game)
                                break;
                            case game.player2.id:
                                game.player2.win ? winsFunction(index, game) : lostFunction(index, game)
                                break;
                            case game.player3.id:
                                game.player3.win ? winsFunction(index, game) : lostFunction(index, game)
                                break;
                            case game.player4.id:
                                game.player4.win ? winsFunction(index, game) : lostFunction(index, game)
                                break;
                        }
                    })
                }
                // calculate deka kalo percent for each player and turn it to number
                if (getGames.length >= 5) {
                    player.dekakalo = parseFloat((((count / countLots) || 0) * 100).toFixed(2))
                } else player.dekakalo = 0
                player.totalGames = getGames.length
                player.count = count
                player.countLots = countLots
                player.winsSeries = winsSeries
                player.lostSeries = lostSeries
                player.form = form
                player.formWins = form.map(item => item.win).reduce((total, x) => (x === true ? total + 1 : total), 0)

                docs.push(player);                

                countLots = 0;
                count = 0;
                winsSeries = 0
                countWins = 0
                lostSeries = 0
                countLoses = 0
                form = []
            })

            const dekakalo = docs.slice().sort((a, b) => b.dekakalo - a.dekakalo);
            const winsDocs = docs.slice().sort((a, b) => b.winsSeries - a.winsSeries);
            const losesDocs = docs.slice().sort((a, b) => b.lostSeries - a.lostSeries);
            const formDocs = docs.slice().sort((a, b) => b.formWins - a.formWins);

            return { dekakalo, form: formDocs, winsSeries: winsDocs, losesSeries: losesDocs }
        })


        const playersStats2 = computed(() => {
            let count = 0;
            let countLots = 0;
            let winsSeries = 0;
            let countWins = 0;
            let lostSeries = 0;
            let countLoses = 0;
            let form = [];

            const docs = Object.values(players.value).map((player) => {
                const getGames = Object.values(games.value).filter(game => game.player1.id === player.id || game.player2.id === player.id || game.player3.id === player.id || game.player4.id === player.id)
                if (getGames.length) {
                    count = getGames.map(game => game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === player.id).length).reduce((prev, next) => prev + next);
                    countLots = getGames.reduce((prev, game) => prev + game.lots.length, 0);

                    getGames.forEach((game, index) => {
                        switch (player.id) {
                            case game.player1.id:
                                game.player1.win ? winsFunction(index, game) : lostFunction(index, game);
                                break;
                            case game.player2.id:
                                game.player2.win ? winsFunction(index, game) : lostFunction(index, game);
                                break;
                            case game.player3.id:
                                game.player3.win ? winsFunction(index, game) : lostFunction(index, game);
                                break;
                            case game.player4.id:
                                game.player4.win ? winsFunction(index, game) : lostFunction(index, game);
                                break;
                        }
                    });
                }

                player.dekakalo = getGames.length >= 5 ? parseFloat((((count / countLots) || 0) * 100).toFixed(2)) : 0;
                player.totalGames = getGames.length;
                player.count = count;
                player.countLots = countLots;
                player.winsSeries = winsSeries;
                player.lostSeries = lostSeries;
                player.form = form;
                player.formWins = form.filter(item => item.win).length;

                return player;
            });

            const dekakalo = docs.slice().sort((a, b) => b.dekakalo - a.dekakalo);
            const winsDocs = docs.slice().sort((a, b) => b.winsSeries - a.winsSeries);
            const losesDocs = docs.slice().sort((a, b) => b.lostSeries - a.lostSeries);
            const formDocs = docs.slice().sort((a, b) => b.formWins - a.formWins);

            return { dekakalo, form: formDocs, winsSeries: winsDocs, losesSeries: losesDocs };
        });

        const teamsStats = computed(() => {
            count = 0
            countLots = 0
            docsTeams = []
            // winsTeamsDocs = []
            // losesTeamsDocs = []
            // formTeamsDocs = []
            winsSeries = 0
            countWins = 0
            lostSeries = 0
            countLoses = 0
            form = []
            Object.values(teams.value).forEach(team => {
                getGames = Object.values(games.value).filter(game => game.team1.id === team.id || game.team2.id === team.id)

                getGames.forEach((game, index) => {
                    countLots = countLots + game.lots.length
                    if (team.id === game.team1.id) {
                        count = count + game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === game.player1.id).length + game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === game.player2.id).length
                        game.team1.win ? winsFunction(index, game) : lostFunction(index, game)

                    } else if (team.id === game.team2.id) {
                        count = count + game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === game.player3.id).length + game.lots.map(lot => lot.dekakalo).filter(dekakalo => dekakalo === game.player4.id).length
                        game.team2.win ? winsFunction(index, game) : lostFunction(index, game)
                    }
                })
                team.dekakalo = getGames.length >= 5 ? parseFloat((((count / countLots) || 0) * 100).toFixed(2)) : 0;
                team.count = count;
                team.countLots = countLots;
                team.totalGames = getGames.length;
                team.winsSeries = winsSeries;
                team.lostSeries = lostSeries;
                team.form = form;
                team.formWins = form.map(item => item.win).reduce((total, x) => (x === true ? total + 1 : total), 0);

                docsTeams.push(team);

                countLots = 0;
                count = 0;
                winsSeries = 0
                countWins = 0
                lostSeries = 0
                countLoses = 0
                form = []
            })

            const dekakalo = docsTeams.slice().sort((a, b) => b.dekakalo - a.dekakalo);
            const winsTeamsDocs = docsTeams.slice().sort((a, b) => b.winsSeries - a.winsSeries);
            const losesTeamsDocs = docsTeams.slice().sort((a, b) => b.lostSeries - a.lostSeries);
            const formTeamsDocs = docsTeams.slice().sort((a, b) => b.formWins - a.formWins);            

            return { dekakalo, form: formTeamsDocs, winsSeries: winsTeamsDocs, losesSeries: losesTeamsDocs }

        })


        const teamsStats2 = computed(() => {
            let count = 0;
            let countLots = 0;
            let docsTeams = [];
            let winsTeamsDocs = [];
            let losesTeamsDocs = [];
            let formTeamsDocs = [];
            let winsSeries = 0;
            let countWins = 0;
            let lostSeries = 0;
            let countLoses = 0;
            let form = [];

            Object.values(teams.value).forEach(team => {
                let getGames = Object.values(games.value).filter(game => game.team1.id === team.id || game.team2.id === team.id);

                getGames.forEach((game, index) => {
                    countLots += game.lots.length;
                    let lots = game.lots.map(lot => lot.dekakalo);
                    let teamId = (team.id === game.team1.id) ? game.player1.id : game.player3.id;
                    count += lots.filter(dekakalo => dekakalo === teamId).length;
                    teamId = (team.id === game.team1.id) ? game.player2.id : game.player4.id;
                    count += lots.filter(dekakalo => dekakalo === teamId).length;

                    if (team.id === game.team1.id) {
                        game.team1.win ? winsFunction(index, game) : lostFunction(index, game);
                    } else if (team.id === game.team2.id) {
                        game.team2.win ? winsFunction(index, game) : lostFunction(index, game);
                    }
                });

                team.dekakalo = parseFloat(((count / countLots) || 0) * 100).toFixed(2);
                team.count = count;
                team.countLots = countLots;
                team.totalGames = getGames.length;
                team.winsSeries = winsSeries;
                team.lostSeries = lostSeries;
                team.form = form;
                team.formWins = form.reduce((total, x) => (x.win ? total + 1 : total), 0);

                insertIntoArray(docsTeams, team, (a, b) => a.dekakalo < b.dekakalo);
                insertIntoArray(winsTeamsDocs, team, (a, b) => a.winsSeries < b.winsSeries);
                insertIntoArray(losesTeamsDocs, team, (a, b) => a.lostSeries < b.lostSeries);
                insertIntoArray(formTeamsDocs, team, (a, b) => a.formWins < b.formWins);

                countLots = 0;
                count = 0;
                winsSeries = 0;
                countWins = 0;
                lostSeries = 0;
                countLoses = 0;
                form = [];
            });

            return { dekakalo: docsTeams, form: formTeamsDocs, winsSeries: winsTeamsDocs, losesSeries: losesTeamsDocs };
        });


        const longest = computed(() => {
            if (!games.value) {
                store.commit('setError', 'Δεν υπάρχουν ακόμη παιχνίδια...');
                store.commit('setAlertColor', 'alert-danger');
                return [];
            }

            return Object.values(games.value)
                .map(game => ({ totalLots: game.lots.length, ...game }))
                .sort((a, b) => b.totalLots - a.totalLots)
                .filter((_, index, arr) => index === 0 || arr[index].totalLots === arr[0].totalLots)
        });


        const statsTab = ref('player')


        function winsFunction(index, game) {
            countLoses = 0
            countWins++
            if (winsSeries < countWins) {
                winsSeries = countWins
            }
            if (game.live) {
                if (index < 8 && game.live === false) {
                    form.push({ game, win: true })
                }
            } else if (index < 8) {
                form.push({ game, win: true })
            }
        }

        function lostFunction(index, game) {
            countWins = 0
            countLoses++
            if (lostSeries < countLoses) {
                lostSeries = countLoses
            } if (game.live) {
                if (index < 8 && game.live === false) {
                    form.push({ game, win: false })
                }
            } else if (index < 8) {
                form.push({ game, win: false })
            }
        }


        store.commit('setIsPending', false)
        // }

        // if (isReady.value) {
        //     loadData()
        // }

        // wait for store to be ready before loading stats
        // const unwatch = watchEffect(() => {
        //     if (isReady.value) {
        //         loadData()
        //     }
        // })

        const toggleStats = (stats) => {
            statsTab.value = stats
        }

        // onUnmounted(() => {
        //     unwatch()
        // })

        return { error, orderByDekakalo, orderByWinsSeries, orderByLosesSeries, orderByForm, orderTeamsByDekakalo, orderTeamsByWinsSeries, orderTeamsByLosesSeries, orderTeamsByForm, games, longest, statsTab, isPending, isReady, toggleStats, playersStats, teamsStats }
    }

}
</script>
<style scoped>
/* tooltip css */
[data-tooltip] {
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    position: relative;
}

[data-tooltip]::after {
    background-color: rgba(51, 51, 51, 0.9);
    border-radius: 0.3rem;
    color: #fff;
    content: attr(data-tooltip);
    font-size: 1rem;
    font-size: 85%;
    font-weight: normal;
    line-height: 1.15rem;
    opacity: 0;
    padding: 0.25rem 0.5rem;
    position: absolute;
    text-align: center;
    text-transform: none;
    transition: opacity 0.2s;
    visibility: hidden;
    white-space: nowrap;
    z-index: 1;
}

[data-tooltip].tooltip-left::before {
    border-style: solid;
    border-width: 0.3rem;
    content: "";
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: transparent transparent transparent rgba(51, 51, 51, 0.9);
    top: 0.3rem;
    right: calc(110% - 0.3rem);
    margin-top: -0.3rem;
}

[data-tooltip].tooltip-left::after {
    top: -0.3rem;
    right: calc(110% + 0.3rem);
}

[data-tooltip].tooltip-top::before {
    border-style: solid;
    border-width: 0.3rem;
    content: "";
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: transparent transparent transparent rgba(51, 51, 51, 0.9);
    right: 0.3rem;
    bottom: calc(110% - 0.3rem);
    margin-top: -0.3rem;
}

[data-tooltip].tooltip-top::after {
    right: -0.3rem;
    bottom: calc(110% + 0.3rem);
}

@media (max-width: 767px) {
    [data-tooltip].tooltip-mobile::before {
        display: none;
    }

    [data-tooltip].tooltip-mobile:after {
        font-size: 1rem;
        max-width: 20rem;
        position: fixed;
        bottom: auto;
        top: 50%;
        left: 50%;
        text-align: left;
        transform: translate(-50%);
        white-space: normal;
    }
}

[data-tooltip]:hover::after,
[data-tooltip][class*="tooltip-"]:hover::before {
    visibility: visible;
    opacity: 1;
}
</style>