<template>
    <tr>
        <th scope="row">{{ index + 1 }}</th>
        <td>
            <input
                class="form-check-input"
                type="radio"
                :value="player1.id"
                id="10kalo1-1"
                v-model="dekaKalo"
                disabled
            />
        </td>
        <td colspan="2">{{ scoreLot1 }}</td>
        <td>
            <input
                class="form-check-input"
                type="radio"
                :value="player2.id"
                id="10kalo1-1"
                v-model="dekaKalo"
                disabled
            />
        </td>
        <td>
            <input
                class="form-check-input"
                type="radio"
                :value="player3.id"
                id="10kalo1-1"
                v-model="dekaKalo"
                disabled
            />
        </td>
        <td colspan="2">{{ scoreLot2 }}</td>
        <td>
            <input
                class="form-check-input"
                type="radio"
                :value="player4.id"
                id="10kalo1-1"
                v-model="dekaKalo"
                disabled
            />
        </td>
        <td>
            <i
                class="bi btn btn-danger bi-dash-square"
                @click="removeLot"
            ></i>
        </td>
    </tr>
</template>
<script>
import { computed, ref, watch } from "@vue/reactivity";

export default {
    name: 'Lot',
    props: ['index', 'scoreLot1', 'scoreLot2', 'dekaKalo', 'player1', 'player2', 'player3', 'player4'],
    emits: ['remove'],
    setup(props, context) {

        const index = props.index

        function removeLot() {
            context.emit('remove', index)
        }

        // watch(lot, (lot) => {
        //     context.emit('emitLot', lot)
        // })

        return { removeLot }
    }

}
</script>
<style>
</style>