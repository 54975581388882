<template>
    <div>
        <div :class="'spinner-border text-' + color" role="status">
            <span class="visually-hidden"></span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Spinner',
    props: [ 'color' ]
}
</script>
<style>
</style>