<template>
    <div class="container-fluid p-1 d-flex justify-content-center">

        <div class="col-xl-10 col-lg-12 col-md-9">

            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-lg-8 mx-auto">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-4">Καλώς ήλθατε!</h1>
                                </div>
                                <form class="user" @submit.prevent="handleSubmit">
                                    <div class="form-group">
                                        <input type="email" class="form-control form-control-user"
                                            :class="{ 'is-invalid': !isValid.email }" id="email"
                                            aria-describedby="emailHelp" v-model="email" placeholder="Email...">
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control form-control-user"
                                            :class="{ 'is-invalid': !isValid.password }" id="password"
                                            v-model="password" placeholder="Password">
                                    </div>
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox small">
                                            <input type="checkbox" class="custom-control-input" id="customCheck">
                                            <label class="custom-control-label" for="customCheck">Να με θυμάσαι</label>
                                        </div>
                                    </div>
                                    <Spinner v-if="isPending" color="primary" />
                                    <button v-else type="submit" class="btn btn-primary btn-user btn-block">
                                        Είσοδος
                                    </button>
                                </form>
                                <hr>
                                <div class="text-center">
                                    <h6 class="small text-primary" type="button" data-bs-toggle="modal"
                                            data-bs-target="#resetModal">Ξέχασα τον κωδικό...
                                        <teleport to="body">
                                            <div class="modal fade" id="resetModal" tabindex="-1"
                                                aria-labelledby="modal" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title text-secondary" id="modalTitle">
                                                                Επαναφορά κωδικού</h5>
                                                            <button type="button" class="btn-close"
                                                                data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-footer d-flex justify-content-center">
                                                            <form class="col col-md-6 text-start">
                                                                <div class="mb-3">
                                                                    <label for="email" class="form-label">Email
                                                                        address</label>
                                                                    <input type="email" class="form-control"
                                                                        :class="{ 'is-invalid': !isValid.email }"
                                                                        id="emailReset" aria-describedby="emailReset"
                                                                        v-model="emailReset" />
                                                                </div>
                                                                <button class="btn btn-outline-primary px-4"
                                                                    v-if="!isPending" type="button"
                                                                    @click="handleReset">Αποστολή</button>
                                                                <div v-else class="spinner-border text-danger"
                                                                    role="status">
                                                                    <span class="visually-hidden"></span>
                                                                </div>
                                                            </form>
                                                            <button class="visually-hidden" ref="closeModal"
                                                                type="button" data-bs-dismiss="modal"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </teleport>
                                    </h6>
                                </div>
                                <div class="text-center">
                                    <h6 class="small text-primary" type="button"><router-link to="/signup">Δημιουργία λογαριασμού!</router-link></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <form class="col col-md-6 text-start" @submit.prevent="handleSubmit">
            <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input type="email" class="form-control" :class="{ 'is-invalid': !isValid.email }" id="email"
                    aria-describedby="emailHelp" v-model="email" />
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" :class="{ 'is-invalid': !isValid.password }" id="password"
                    v-model="password" />
            </div>
            <Spinner v-if="isPending" color="primary" />
            <button v-else type="submit" class="btn btn-primary me-3 mb-3">Είσοδος</button>
            <h6 class="d-sm-inline">
                Ή πατήστε
                <router-link to="/signup">εδώ</router-link> για να εγγραφείτε
            </h6>
            <h6>
                Ξέχασα τον κωδικό...
                <button type="button" class="btn text-primary" data-bs-toggle="modal"
                    data-bs-target="#resetModal">Επαναφορά</button>
                <teleport to="body">
                    <div class="modal fade" id="resetModal" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-secondary" id="modalTitle">Επαναφορά κωδικού</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-footer d-flex justify-content-center">
                                    <form class="col col-md-6 text-start">
                                        <div class="mb-3">
                                            <label for="email" class="form-label">Email address</label>
                                            <input type="email" class="form-control"
                                                :class="{ 'is-invalid': !isValid.email }" id="emailReset"
                                                aria-describedby="emailReset" v-model="emailReset" />
                                        </div>
                                        <button class="btn btn-outline-primary px-4" v-if="!isPending" type="button"
                                            @click="handleReset">Αποστολή</button>
                                        <div v-else class="spinner-border text-danger" role="status">
                                            <span class="visually-hidden"></span>
                                        </div>
                                    </form>
                                    <button class="visually-hidden" ref="closeModal" type="button"
                                        data-bs-dismiss="modal"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </teleport>
            </h6>
        </form> -->
    </div>
</template>
<script>
import { ref, computed, onUnmounted } from "vue"
import { useRouter } from "vue-router"
// import useLogin from "../composables/useLogin"
// import useReset from "../composables/useReset"
import Spinner from "../components/Spinner.vue"
import Modal from "../components/Modal.vue"
import { useStore } from "vuex"


export default {
    name: "Login",
    components: { Spinner, Modal },
    setup() {
        const email = ref(null)
        const password = ref(null)
        const emailReset = ref()
        const closeModal = ref(null)
        const router = useRouter()
        const store = useStore()
        store.commit('setSidebarToggle', true)

        const isValid = ref({
            email: true,
            password: true
        })

        // const { login, error, isPending } = useLogin()
        // const { reset } = useReset()

        const error = computed(() => {
            return store.state.error
        })
        const isPending = computed(() => {
            return store.state.isPending
        })

        const handleSubmit = async () => {
            store.commit('setIsPending', true)
            try {
                if (email.value && password.value) {
                    await store.dispatch('login', {
                        email: email.value,
                        password: password.value
                    }).then(() => {
                        if (!store.state.error) {
                            router.push('/')
                            email.value = null
                            password.value = null
                        } else {
                            store.commit('setError', 'Wrong username or password')
                            store.commit('setAlertColor', 'alert-danger')
                        }
                    })
                }
                // await login(email.value, password.value).then(() => {
                //     if (!error.value) {
                //         router.push('/')
                //         email.value = null
                //         password.value = null
                //     } else {
                //         error.value = 'Wrong username or password'
                //     }
                // })
                else {
                    email.value ? isValid.value.email = true : isValid.value.email = false
                    password.value ? isValid.value.password = true : isValid.value.password = false
                }
                store.commit('setIsPending', false)

            } catch (err) {
                store.commit('setIsPending', false)
                store.commit('setError', err.message)
            }
        }

        const handleReset = async () => {
            store.commit('setIsPending', true)

            // try {
            await store.dispatch("reset", { email: emailReset.value }).then(() => {
                store.commit('setIsPending', false)
                closeModal.value.click()
                store.commit('setError', 'Ακολουθήστε το σύνδεσμο που στάλθηκε στο e-mail για να επαναφέρετε τον κωδικό')
                store.commit('setAlertColor', 'alert-success')
            })
            // } catch (err) {
            //     store.commit('setIsPending', false)
            //     store.commit('setError', 'Κάτι πήγε στραβά...')
            //     store.commit('setAlertColor', 'alert-danger')
            // }


        }

        onUnmounted(() => {
            email.value = null
            password.value = null
            error.value = null
            isValid.value.email = true
            isValid.value.password = true
        })


        return { email, password, emailReset, closeModal, isValid, handleSubmit, handleReset, error, isPending }
    }


}
</script>
<style>
</style>