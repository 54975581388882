<template>
  <div class="about">
    <!-- Page Wrapper -->
    <div id="wrapper">
      <Sidebar />
      <!-- Content Wrapper -->
      <div id="content-wrapper" class="d-flex flex-column">

        <!-- Main Content -->
        <div id="content">

          <Topbar />
          <!-- <Navbar /> -->
          <Alert />
          <!-- <Placeholder v-if="!isReady" /> -->
          <router-view :key="componentKey" />
        </div>
        <!-- End of Main Content -->
        <Footer />
      </div>
      <!-- End of Content Wrapper -->
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { watch } from "vue";
// import { watchEffect, onBeforeMount } from "vue";
// @ is an alias to /src
import { auth, db, storage } from "./firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDoc, query, orderBy, doc, setDoc, onSnapshot, getDocs, } from "firebase/firestore";
import getSeasons from "./composables/getSeasons";
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import Alert from "./components/Alert.vue";
import Sidebar from "./components/Sidebar.vue";
import Topbar from "./components/Topbar.vue";
import Placeholder from "./components/Placeholder";
import { useRoute } from "vue-router";
import { useStore } from "vuex"
// import fetchUser from "./composables/fetchUser";

export default {
  components: { Navbar, Footer, Alert, Sidebar, Topbar, Placeholder },
  setup() {
    const games = ref({})
    const route = useRoute()
    const store = useStore()
    const componentKey = ref(0)
    // const { user } = fetchUser()

    const colRefSeasons = collection(db, "season");
    // order seasons (newest first)
    const q = query(colRefSeasons, orderBy("timestamp", "desc"));
    let seasons = [];

    const loadSeasons = async () => {
      await getDocs(q).then((snapshot) => {
        let docs = [];
        snapshot.docs.forEach(async (docSnap) => {
          docs.push({ ...docSnap.data(), id: docSnap.id });
        });
        seasons = docs;
      });
    };
    loadSeasons().then(async () => {
      console.log(seasons);
      await store.commit("setSeason", seasons);
      if (route.name !== "SeasonArchive") {
        await store.dispatch("seasonStats", seasons[0].id);
      }
      store.commit("setIsPending", false);
    });

    const storageRef = ref(storage, 'users')

    onAuthStateChanged(auth, async (_user) => {
      if (_user) {
        console.log("User state changed. Current user is:", _user.uid);
        const refUser = doc(db, "users", _user.uid);
        await getDoc(refUser).then((docSnap) => {
          store.dispatch('authChange', { ...docSnap.data(), ..._user })
          console.log(store.state.user);
        });
      } else {
        store.dispatch('authChange', _user)
      }
    });

    // // list all users
    // const listAllUsers = (nextPageToken) => {
    //   // List batch of users, 1000 at a time.
    //   auth
    //     .listUsers(1000, nextPageToken)
    //     .then((listUsersResult) => {
    //       listUsersResult.users.forEach((userRecord) => {
    //         console.log('user', userRecord.toJSON());
    //       });
    //       if (listUsersResult.pageToken) {
    //         // List next batch of users.
    //         listAllUsers(listUsersResult.pageToken);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log('Error listing users:', error);
    //     });
    // };
    // // Start listing users from the beginning, 1000 at a time.
    // listAllUsers();

    const isReady = computed(() => {
      return store.state.isReady
    })


    const reRender = () => {
      componentKey.value++
    }

    // watch for a change in route params to re-render the component
    watch(() => route.params, (toParams, prevParams) => {
      if (toParams.id !== prevParams.id) {
        componentKey.value++
      }
    })

    // detect swipes
    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
      return evt.touches ||             // browser API
        evt.originalEvent.touches; // jQuery
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    };

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
        if (xDiff > 0) {
          /* left swipe */
          store.commit('setSidebarToggle', true)

        } else {
          /* right swipe */
          store.commit('setSidebarToggle', false)


        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    };

    return { componentKey, isReady, reRender }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

router-link {
  color: black;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>