<template>
    <div class="home container-fluid p-1">
        <Spinner v-if="!isReady" color="primary" />
        <div v-else class="row">
            <div class="d-flex col-md-8 mx-auto justify-content-between mt-3">
                <h3 class="text-dark">Αρχείο</h3>
                <!-- <button class="btn btn-primary" @click="consoleLog">Console</button> -->
                <!-- <h3 v-if="seasons" class="text-dark">{{ seasons[0].name }}</h3> -->
                <button v-if="!allTimeScore" class="btn btn-primary" @click="allTimeScore = !allTimeScore">All Time
                    Score</button>
                <button v-if="allTimeScore" class="btn btn-outline-primary"
                    @click="allTimeScore = !allTimeScore">Ακύρωση</button>
            </div>

            <div v-if="!allTimeScore" class="col-md-8 mx-auto shadow rounded mt-3">
                <div v-if="!!seasons">
                    <div class="row justify-content-between border-bottom border-2">
                        <span class="col-3 my-2 fw-bold">Περίοδος</span>
                        <span class="col-3 my-2 fw-bold">Πρωταθλητής</span>
                        <span class="col-4 my-2 fw-bold text-end">Πρωταθλήτρια Ομάδα</span>
                        <span class="col-1"></span>

                    </div>
                    <div class="row" :class="{ 'bg-light': (index % 2) === 0 }" v-for="(season, index) in seasons"
                        :key="season.id">
                        <router-link v-if="season.champion" class="list-group-item list-group-item-action"
                            :to="{ name: 'SeasonArchive', params: { seasonId: season.id } }">
                            <div class="row justify-content-between my-3">
                                <span class="col-3 my-auto">{{ season.name }}</span>
                                <span class="col-3 d-flex my-auto">
                                    <img v-if="season.champion.photoURL" :src="season.champion.photoURL"
                                        style="max-width: 30px; max-height: 30px;" alt="photoURL"
                                        class="img-fluid rounded me-1" data-bs-toggle="modal"
                                        :data-bs-target="`#lightbox${index}`" />
                                    <i v-else class="bi bi-person-circle me-1"></i>
                                    {{ season.champion.name }}
                                </span>
                                <span class="col-4 my-auto">{{ season.teamChampion.name }}</span>
                                <i class="col-1 fs-3 text-primary my-auto bi bi-chevron-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-else class="row col-md-8 mx-auto">
                <ul class="nav nav-pills mx-2">
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: statsTab === 'player' }" aria-current="page" href="#"
                            @click="toggleStats('player')">Παικτών</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: statsTab === 'teams' }" href="#"
                            @click="toggleStats('teams')">Ομάδων</a>
                    </li>
                </ul>
                <!-- <h6 class="text-danger text-start">*Τα στατιστικά υπολογίζονται από 5-1-2022</h6> -->
                <div v-if="statsTab === 'player'">
                    <div class="mx-auto shadow mb-4">
                        <div class="row">
                            <div class="col list-group-item list-group-item-action border-bottom " to="/">
                                <div class="row justify-content-between">
                                    <span class="col-1 fw-bold">#</span>
                                    <span class="col-4 text-start fw-bold">Name</span>
                                    <span class="col-2 text-center text-success fw-bold">Wins</span>
                                    <span class="col-2 text-center text-danger">Loses</span>
                                    <span class="col-2 fw-bold">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" :class="{ 'bg-light': (index % 2) === 0 }" v-for="(player, index) in players"
                            :key="player.id">
                            <div class="col list-group-item list-group-item-action">
                                <div class="row justify-content-between">
                                    <span class="col-1 my-auto">{{ index + 1 }}</span>
                                    <span class="col-4 text-start d-flex justify-content-start align-items-center">
                                        <img v-if="player.photoURL" :src="player.photoURL"
                                            style="max-width: 30px; max-height: 30px; cursor: pointer;" alt="photoURL"
                                            class="img-fluid rounded me-1" data-bs-toggle="modal"
                                            :data-bs-target="`#lightbox${index}`" />
                                        <i v-else class="bi bi-person-circle me-1"></i>
                                        {{ player.name }}
                                    </span>
                                    <teleport to="body">
                                        <div class="modal fade" :id="`lightbox${index}`" tabindex="-1"
                                            aria-labelledby="modal" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <i class="fas fa-lg fa-times" data-bs-dismiss="modal"
                                                            aria-label="Close"></i>
                                                    </div>
                                                    <div class="modal-body mx-auto">
                                                        <img v-if="player.photoURL" :src="player.photoURL"
                                                            alt="photoURL" class="img-fluid rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </teleport>
                                    <span class="col-2 text-center text-success fw-bold my-auto">{{ player.wins
                                        }}</span>
                                    <span class="col-2 text-center text-danger my-auto">{{ player.loses }}</span>
                                    <span class="col-2 fw-bold my-auto">{{ player.percent }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-auto mb-4">
                        <div class="col d-flex justify-content-start mt-4">
                            <h4>Χωρίς Κατάταξη</h4>
                        </div>
                        <div class="row">
                            <div class="col list-group-item list-group-item-action border-bottom " to="/">
                                <div class="row justify-content-between">
                                    <span class="col-1 fw-bold">#</span>
                                    <span class="col-4 text-start fw-bold">Name</span>
                                    <span class="col-2 text-center text-success fw-bold">Wins</span>
                                    <span class="col-2 text-center text-danger">Loses</span>
                                    <span class="col-2 fw-bold">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" :class="{ 'bg-light': (index % 2) === 0 }"
                            v-for="(player, index) in unorderedPlayers" :key="player.id">
                            <div class="col list-group-item list-group-item-action">
                                <div class="row justify-content-between">
                                    <span class="col-1 my-auto">{{ index + 1 }}</span>
                                    <span class="col-4 text-start d-flex justify-content-start align-items-center">
                                        <img v-if="player.photoURL" :src="player.photoURL"
                                            style="max-width: 30px; max-height: 30px; cursor: pointer;" alt="photoURL"
                                            class="img-fluid rounded me-1" data-bs-toggle="modal"
                                            :data-bs-target="`#lightbox${index}`" />
                                        <i v-else class="bi bi-person-circle me-1"></i>
                                        {{ player.name }}
                                    </span>
                                    <teleport to="body">
                                        <div class="modal fade" :id="`lightbox${index}`" tabindex="-1"
                                            aria-labelledby="modal" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <i class="fas fa-lg fa-times" data-bs-dismiss="modal"
                                                            aria-label="Close"></i>
                                                    </div>
                                                    <div class="modal-body mx-auto">
                                                        <img v-if="player.photoURL" :src="player.photoURL"
                                                            alt="photoURL" class="img-fluid rounded" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </teleport>
                                    <span class="col-2 text-center text-success fw-bold my-auto">{{ player.wins
                                        }}</span>
                                    <span class="col-2 text-center text-danger my-auto">{{ player.loses }}</span>
                                    <span class="col-2 fw-bold my-auto">{{ player.percent }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="statsTab === 'teams'">
                    <div class="shadow mb-4">
                        <div class="row">
                            <div class="col list-group-item list-group-item-action border-bottom ">
                                <div class="row justify-content-between">
                                    <span class="col-1 fw-bold">#</span>
                                    <span class="col-4 text-start fw-bold">Name</span>
                                    <span class="col-2 text-end text-success fw-bold">Wins</span>
                                    <span class="col-2 text-end text-danger">Loses</span>
                                    <span class="col-2 fw-bold">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" :class="{ 'bg-light': (index % 2) === 0 }" v-for="(team, index) in teams"
                            :key="team.id">
                            <div class="col list-group-item list-group-item-action" to="/teams">
                                <div class="row justify-content-between">
                                    <span class="col-1">{{ index + 1 }}</span>
                                    <span class="col-4 text-start">{{ team.name }}</span>
                                    <span class="col-2 text-end text-success fw-bold">{{ team.wins }}</span>
                                    <span class="col-2 text-end text-danger">{{ team.loses }}</span>
                                    <span class="col-2 fw-bold">{{ team.percent }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="col d-flex justify-content-start mt-4">
                            <h4>Χωρίς Κατάταξη</h4>
                        </div>
                        <div class="row">
                            <div class="col list-group-item list-group-item-action border-bottom ">
                                <div class="row justify-content-between">
                                    <span class="col-1 fw-bold">#</span>
                                    <span class="col-4 text-start fw-bold">Name</span>
                                    <span class="col-2 text-end text-success fw-bold">Wins</span>
                                    <span class="col-2 text-end text-danger">Loses</span>
                                    <span class="col-2 fw-bold">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" :class="{ 'bg-light': (index % 2) === 0 }"
                            v-for="(team, index) in unorderedTeams" :key="team.id">
                            <div class="col list-group-item list-group-item-action" to="/teams">
                                <div class="row justify-content-between">
                                    <span class="col-1">{{ index + 1 }}</span>
                                    <span class="col-4 text-start">{{ team.name }}</span>
                                    <span class="col-2 text-end text-success fw-bold">{{ team.wins }}</span>
                                    <span class="col-2 text-end text-danger">{{ team.loses }}</span>
                                    <span class="col-2 fw-bold">{{ team.percent }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex"
// import { watchEffect } from "vue";
// @ is an alias to /src
import { db } from "../firebase/config";
import { collection, getDocs, query, orderBy, doc, setDoc, getDoc, addDoc, updateDoc } from "firebase/firestore";
import getSeasons from "../composables/getSeasons";
import Spinner from "../components/Spinner.vue";
import Modal from "../components/Modal.vue";

export default {
    name: "Archive",
    components: { Spinner, Modal },
    setup() {
        const store = useStore()
        store.commit('setSidebarToggle', true)

        const isReady = computed(() => {
            return store.state.isReady;
        });
        const options = ref(['list', 'of', 'options'])

        const seasons = computed(() => {
            if (isReady.value) {
                let docs = [];
                let teamChampion;
                store.state.season.forEach(season => {
                    if (season.champion) {
                        let champion = users.value[season.champion]
                        teamChampion = totalTeams.value[season.teamChampion]
                        // if (totalTeams.value[season.teamChampion]) {
                        //     teamChampion = totalTeams.value[season.teamChampion]
                        // } else {
                        //     const { team } = getTeamChampion(season)
                        //     console.log(team);
                        //     teamChampion = team
                        // }
                        docs.push({ ...season, champion, teamChampion })
                    }
                });
                // return store.state.archiveSeasons
                return docs;
            }
        })

        // check if tournament has finished
        const champion = computed(() => {
            return !!seasons.value[0].champion
        })

        // const getTeamChampion = async (season) => {
        //     const teamRef = doc(db, "season", season.id, "teams", season.teamChampion);
        //     const docSnap = await getDoc(teamRef);
        //     const team = docSnap.data();
        //     return { team }
        // }
        const user = computed(() => {
            return store.state.user
        })
        const admin = computed(() => {
            if (user.value) {
                return user.value.admin
            } else return false
        })
        const users = computed(() => {
            return store.state.users
        })
        const allUsersScore = computed(() => {
            console.log(users.value);
            let unsorted = {};
            let userTable = {};
            Object.values(users.value).forEach(player => {
                // Create a copy of the player object
                const playerCopy = { ...player };

                if (!champion && totalPlayers.value[player.id]) { // add current season wins if season has not ended yet
                    playerCopy.wins = playerCopy.wins + totalPlayers.value[player.id].wins;
                    playerCopy.loses = playerCopy.loses + totalPlayers.value[player.id].loses;
                }

                let totalGames = playerCopy.wins + playerCopy.loses;
                let percent = 0;
                totalGames == 0 ? percent = 0 : percent = ((playerCopy.wins / totalGames) * 100).toFixed(2);

                if (totalGames >= 30) {
                    // how many games user must complete to enter the table
                    userTable[playerCopy.id] = { ...playerCopy, percent, id: playerCopy.id };
                } else {
                    unsorted[playerCopy.id] = { ...playerCopy, percent, id: playerCopy.id };
                }
            });

            let sorted = {};
            Object.keys(userTable)
                .sort((a, b) => userTable[b].percent - userTable[a].percent)
                .forEach((id) => {
                    sorted[id] = userTable[id];
                });
            return { sorted, unsorted };
        })
        const players = computed(() => {
            const { sorted } = allUsersScore.value;
            return Object.values(sorted);
        })
        const unorderedPlayers = computed(() => {
            const { unsorted } = allUsersScore.value;
            return Object.values(unsorted);
        })
        const totalPlayers = computed(() => {
            return store.state.totalPlayers
        })
        const totalTeams = computed(() => {
            return store.state.teams
        })
        const totalSeasonTeams = computed(() => {
            return store.state.totalTeams
        })
        const allTeamsScore = computed(() => {
            let unsorted = {};
            let teamTable = {};
            Object.values(totalTeams.value).forEach(team => {
                // Create a copy of the team object
                const teamCopy = { ...team };
                if (!champion && totalSeasonTeams.value[team.id]) { // add current season wins if season has not ended yet
                    teamCopy.wins = teamCopy.wins + totalSeasonTeams.value[teamCopy.id].wins
                    teamCopy.loses = teamCopy.loses + totalSeasonTeams.value[team.id].loses
                }
                let totalGames = teamCopy.wins + teamCopy.loses;
                let percent = 0;
                totalGames == 0 ? percent = 0 : percent = ((teamCopy.wins / totalGames) * 100).toFixed(2);
                if (totalGames >= 30) {
                    // how many games user must complete to enter the table
                    teamTable[team.id] = { ...teamCopy, percent, id: teamCopy.id };
                } else {
                    unsorted[teamCopy.id] = { ...teamCopy, percent, id: teamCopy.id };
                }
            })
            let sorted = {};
            Object.keys(teamTable)
                .sort((a, b) => teamTable[b].percent - teamTable[a].percent)
                .forEach((id) => {
                    sorted[id] = teamTable[id];
                });
            return { sorted, unsorted };
        })
        const teams = computed(() => {
            const { sorted } = allTeamsScore.value;
            return Object.values(sorted);
        })
        const unorderedTeams = computed(() => {
            const { unsorted } = allTeamsScore.value;
            return Object.values(unsorted);
        })
        const orderedTeams = computed(() => {
            return store.state.orderedTeams
        })
        const games = computed(() => {
            return store.state.games
        })
        const error = computed(() => {
            return store.state.error
        })
        const isPending = computed(() => {
            return store.state.isPending
        })

        const allTimeScore = ref(false)
        const statsTab = ref('player')

        const toggleStats = (stats) => {
            statsTab.value = stats
        }


        return { user, seasons, users, players, teams, unorderedPlayers, totalPlayers, orderedTeams, unorderedTeams, games, seasons, allTimeScore, error, isPending, isReady, toggleStats, statsTab }
    }
}
</script>
<style lang="">

</style>