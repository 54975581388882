<template>
  <div class="container-fluid p-1">
    <div class="row">
      <div class="d-flex col-md-8 justify-content-between mx-auto mt-3">
        <h3 class="text-dark">Νέο Φιλικό Παιχνίδι</h3>
        <button v-if="!random && !startGame" class="btn btn-info" @click="random = true">Τυχαία επιλογη</button>
        <div v-if="startGame" class="col-md-3 mb-3">
          <Modal :btnClass="'btn btn-info'" :buttonText="'Διαγραφή ' + `<i class='fas fa-trash'></i>`"
            :title="'Διαγραφή Παιχνιδιού;'" :modalId="'resetModal'" ref="closeResetModal" @handleClick="handleReset" />
        </div>
      </div>
      <Spinner v-if="isPending" color="primary" />
      <div v-else class="col-md-8 mx-auto">
        <div v-if="!startGame">
          <div v-if="random">
            <div class="row justify-content-start">
              <div class="my-2 d-flex justify-content-between">
                <h4 class="d-inline text-start">Τυχαία Επιλογή:</h4>
                <button class="d-inline btn btn-outline-info"
                  @click="random = false, player1Id = null, player2Id = null, player3Id = null, player4Id = null">Ακύρωση</button>
              </div>
              <div class="col-md-3 my-2 input-group">
                <label class="input-group-text" for="selectP1">Παίκτης 1</label>
                <select class="form-select" id="selectP1" v-model="player1Id"
                  @change="player2Id = null, player3Id = null, player4Id = null">
                  <option class="form-select" v-for="(player, index) in users" :key="player.id" :value="player.id">{{
                      player.name
                  }}</option>
                </select>
              </div>
              <div class="col-md-3 my-2 input-group">
                <label class="input-group-text" for="selectP1">Παίκτης 2</label>
                <select class="form-select" id="selectP1" v-model="player2Id"
                  @change="player3Id = null, player4Id = null" :disabled="!player1Id">
                  <option class="form-select" v-for="(player, index) in filtP2" :key="player.id" :value="player.id">{{
                      player.name
                  }}</option>
                </select>
              </div>
              <div class="col-md-3 my-2 input-group">
                <label class="input-group-text" for="selectP1">Παίκτης 3</label>
                <select class="form-select" id="selectP1" v-model="player3Id" @change="player4Id = null"
                  :disabled="!player2Id">
                  <option class="form-select" v-for="(player, index) in filtP3" :key="player.id" :value="player.id">{{
                      player.name
                  }}</option>
                </select>
              </div>
              <div class="col-md-3 my-2 input-group">
                <label class="input-group-text" for="selectP1">Παίκτης 4</label>
                <select class="form-select" id="selectP1" v-model="player4Id" :disabled="!player3Id">
                  <option class="form-select" v-for="(player, index) in filtP4" :key="player.id" :value="player.id">{{
                      player.name
                  }}</option>
                </select>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row justify-content-center">
              <div class="my-2">
                <h4 class="text-center">Ομάδα 1:</h4>
              </div>
              <div class="col-md-6 my-2 input-group">
                <label class="input-group-text" for="selectP1">Παίκτης 1</label>
                <select class="form-select" id="selectP1" v-model="player1Id"
                  @change="player2Id = null, player3Id = null, player4Id = null">
                  <option class="form-select list-group-item list-group-item-action" v-for="(player, index) in users"
                    :key="player.id" :value="player.id">{{ player.name }}</option>
                </select>
              </div>
              <div class="col-md-6 my-2 input-group">
                <label class="input-group-text" for="selectP2">Παίκτης 2</label>
                <select class="form-select" id="selectP2" v-model="player2Id"
                  @change="player3Id = null, player4Id = null" :disabled="!player1Id">
                  <option class="form-select" v-for="(player, index) in filtP2" :key="player.id" :value="player.id">{{
                      player.name
                  }}</option>
                </select>
              </div>
            </div>
            <div v-if="player2Id" class="row justify-content-center">
              <div class="my-2">
                <h4 class="text-center">Ομάδα 2:</h4>
              </div>
              <div class="col-md-6 my-2 input-group">
                <label class="input-group-text" for="selectP3">Παίκτης 1</label>
                <select class="form-select" id="selectP3" v-model="player3Id" @change="player4Id = null">
                  <option class="form-select" v-for="(player, index) in filtP3" :key="player.id" :value="player.id">{{
                      player.name
                  }}</option>
                </select>
              </div>
              <div class="col-md-6 my-2 input-group">
                <label class="input-group-text" for="selectP4">Παίκτης 2</label>
                <select class="form-select" id="selectP4" v-model="player4Id" :disabled="!player3Id">
                  <option class="form-select" v-for="(player, index) in filtP4" :key="player.id" :value="player.id">{{
                      player.name
                  }}</option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="player4Id" class="col-md-8 justify-content-center">
            <button class="btn btn-primary" @click="handleStart">Έναρξη</button>
          </div>
        </div>
        <transition v-if="startGame">
          <div class="justify-content-center">
            <div class="row d-flex justify-content-center mt-3 mb-3 px-3">
              <div class="mb-4">
                <!-- tabs from bootstrap -->
                <ul class="nav nav-pills" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="score-tab" data-bs-toggle="tab" data-bs-target="#score"
                      type="button" role="tab" aria-controls="score" aria-selected="true">Σκορ</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="stats-tab" data-bs-toggle="tab" data-bs-target="#stats" type="button"
                      role="tab" aria-controls="stats" aria-selected="false">Στατιστικά</button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="score" role="tabpanel" aria-labelledby="score-tab">
                    <div class="row fw-bold">
                      <div class="col-2 border p-2">#</div>
                      <div class="col-4 border p-2">
                        {{ player1.name }} - {{ player2.name }}
                      </div>
                      <div class="col-4 border p-2">
                        {{ player3.name }} - {{ player4.name }}
                      </div>
                      <div class="col-2 border p-2"></div>
                    </div>
                    <transition-group name="lot">
                      <div class="row" v-for="(lot, index) in game.lots" :key="index">
                        <div class="col-2 border p-2">{{ index + 1 }}</div>
                        <div class="col-4 border p-2">{{ lot.score1 }}</div>
                        <div class="col-4 border p-2">{{ lot.score2 }}</div>
                        <i type="button" class="col-2 btn-outline-danger border bi bi-x-lg p-2" style="cursor: pointer"
                          data-bs-toggle="modal" :data-bs-target="`#removeLotModal${index}`"></i>
                        <teleport to="body">
                          <div class="modal fade" :id="`removeLotModal${index}`" tabindex="-1" aria-labelledby="modal"
                            aria-hidden="true">
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="modalTitle">Αφαίρεση παρτίδας</h5>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                                </div>
                                <div class="modal-footer d-flex justify-content-center">
                                  <button type="button" class="btn btn-outline-danger px-4" @click="removeLot(index)"
                                    data-bs-dismiss="modal">ΝΑΙ</button>
                                  <button class="visually-hidden" type="button" data-bs-dismiss="modal"></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </teleport>
                      </div>
                    </transition-group>
                    <div class="row fw-bold">
                      <div class="col-2 border p-2">Συν:</div>
                      <div class="col-4 border p-2">{{ sum1 }}</div>
                      <div class="col-4 border p-2">{{ sum2 }}</div>
                      <div class="col-2 border p-2"></div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="stats" role="tabpanel" aria-labelledby="stats-tab">
                    <div class="row fw-bold d-flex flex-nowrap">
                      <div class="col-2 border p-2 w-40px">#</div>
                      <div class="col border p-2 w-40px">{{ player1.name.slice(0, 4) }}</div>
                      <div class="col border p-2 w-40px">{{ player2.name.slice(0, 4) }}</div>
                      <div class="col border p-2 w-40px">{{ player3.name.slice(0, 4) }}</div>
                      <div class="col border p-2 w-40px">{{ player4.name.slice(0, 4) }}</div>
                    </div>
                    <div class="row d-flex flex-nowrap" v-for="(lot, index) in game.lots" :key="index">
                      <div class="col-2 border p-2 w-40px">{{ index + 1 }}</div>
                      <div class="col border p-2 w-40px">
                        <img v-if="lot.dekakalo == player1.id" src="../../public/10-diamond.png" alt="10-diamond"
                          class="img-thumbnail mx-auto img-responsive img-fluid" style="max-width: 30px" />
                      </div>
                      <div class="col border p-2 w-40px">
                        <img v-if="lot.dekakalo == player2.id" src="../../public/10-diamond.png" alt="10-diamond"
                          class="img-thumbnail mx-auto img-responsive img-fluid" style="max-width: 30px" />
                      </div>
                      <div class="col border p-2 w-40px">
                        <img v-if="lot.dekakalo == player3.id" src="../../public/10-diamond.png" alt="10-diamond"
                          class="img-thumbnail mx-auto img-responsive img-fluid" style="max-width: 30px" />
                      </div>
                      <div class="col border p-2 w-40px">
                        <img v-if="lot.dekakalo == player4.id" src="../../public/10-diamond.png" alt="10-diamond"
                          class="img-thumbnail mx-auto img-responsive img-fluid" style="max-width: 30px" />
                      </div>
                    </div>
                    <div class="row fw-bold d-flex flex-nowrap">
                      <div class="col-2 border p-2 w-40px">%</div>
                      <div class="col border p-2 w-40px">{{ Math.floor(dekaP1) }}</div>
                      <div class="col border p-2 w-40px">{{ Math.floor(dekaP2) }}</div>
                      <div class="col border p-2 w-40px">{{ Math.floor(dekaP3) }}</div>
                      <div class="col border p-2 w-40px">{{ Math.floor(dekaP4) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- add form to submit every lot of the game -->
              <form class="mx-4 pt-3 shadow rounded" @submit.prevent="addLot">
                <div class="col d-flex mb-3 p-1 border justify-content-around">
                  <label for="score1" class="col-6 col-form-label">{{ player1.name }} - {{ player2.name }}</label>
                  <input type="number" class="col-3" id="score1" v-model="scoreLot1" placeholder="0" />
                </div>
                <div class="col d-flex mb-3 p-1 border justify-content-around">
                  <label for="score2" class="col-6 col-form-label">{{ player3.name }} - {{ player4.name }}</label>
                  <input type="number" class="col-3" id="score2" v-model="scoreLot2" placeholder="0" />
                </div>
                <div class="col d-flex mb-3 p-2 border align-items-center">
                  <div class="col text-end">
                    <div class="m-2">
                      <input class="btn-check" type="radio" name="gridRadios" id="gridRadios1" :value="player1.id"
                        v-model="dekaKalo" />
                      <label class="w-100 btn btn-outline-danger" for="gridRadios1">
                        {{
                            player1.name
                        }}
                      </label>
                    </div>
                    <div class="m-2">
                      <input class="btn-check" type="radio" name="gridRadios" id="gridRadios2" :value="player2.id"
                        v-model="dekaKalo" />
                      <label class="w-100 btn btn-outline-danger" for="gridRadios2">
                        {{
                            player2.name
                        }}
                      </label>
                    </div>
                  </div>
                  <div class="col-2">
                    <img src="../../public/10-diamond.png" alt="10-diamond"
                      class="img-thumbnail mx-auto img-responsive img-fluid" />
                  </div>
                  <div class="col text-start">
                    <div class="m-2">
                      <input class="btn-check" type="radio" name="gridRadios" id="gridRadios3" :value="player3.id"
                        v-model="dekaKalo" />
                      <label class="w-100 btn btn-outline-danger" for="gridRadios3">
                        {{
                            player3.name
                        }}
                      </label>
                    </div>
                    <div class="m-2">
                      <input class="btn-check" type="radio" name="gridRadios" id="gridRadios4" :value="player4.id"
                        v-model="dekaKalo" />
                      <label class="w-100 btn btn-outline-danger" for="gridRadios4">
                        {{
                            player4.name
                        }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <button type="submit" class="col w-100 btn btn-success mb-3">Προσθήκη παρτίδας</button>
                </div>
              </form>
            </div>
            <div class="row justify-content-center">
              <!-- <div class="col-md-3 mb-3">
                <Modal :btnClass="'btn btn-info'" :buttonText="'Reset game'" :title="'Διαγραφή Παιχνιδιού;'"
                  :modalId="'resetModal'" ref="closeResetModal" @handleClick="handleReset" />
              </div> -->
              <div class="col-md-3">
                <Modal :btnClass="'btn btn-outline-danger'" :buttonText="'Τέλος Παιχνιδιού'"
                  :title="'Τερματισμός Παιχνιδιού;'" :modalId="'endGameModal'" ref="closeEndGameModal"
                  @handleClick="addData" />
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>

</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { watchEffect } from "vue";
// @ is an alias to /src
import { db } from "../firebase/config";
import {
  doc,
  collection,
  getDocs,
  addDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  increment,
  serverTimestamp,
  onSnapshot,
  arrayUnion,
  writeBatch
} from "firebase/firestore";
import Lot from "../components/Lot.vue";
import Modal from "../components/Modal.vue";
import Spinner from "../components/Spinner.vue";
// import fetchUser from "../composables/fetchUser"
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import getSeasons from "../composables/getSeasons";
import { start } from "repl";

export default {
  name: "NewFriendlyGame",
  components: { Lot, Modal, Spinner },
  setup(props) {
    const store = useStore();
    store.commit('setSidebarToggle', true)

    const batch = writeBatch(db);
    const user = computed(() => {
      return store.state.user;
    });
    const seasons = computed(() => {
      return store.state.season;
    });
    const friendlySeason = computed(() => {
      return store.state.season.filter(doc => doc.id === "mGi2x2WCOV3R3IHt27Bl")[0];
    });
    const teams = computed(() => {
      return store.state.totalTeams;
    });
    const players = computed(() => {
      return store.state.totalPlayers;
    });
    const users = computed(() => {
      return store.state.users;
    });
    const player1Id = ref();
    const player2Id = ref();
    const player3Id = ref();
    const player4Id = ref();
    const player1 = ref();
    const player2 = ref();
    const player3 = ref();
    const player4 = ref();
    const addedTeam = ref();
    const team1 = ref();
    const team2 = ref();
    const scoreLot1 = ref(null);
    const scoreLot2 = ref(null);
    const dekaKalo = ref([]);
    const lots = ref([]);
    const game = ref();
    const startGame = ref(false)
    const toggleAddTeam = ref(false);
    const toggleReset = ref(false);
    const toggleEndGame = ref(false);
    const spinner = ref(false);
    const random = ref(false)
    const closeModal = ref(null);
    const closeResetModal = ref(null);
    const closeEndGameModal = ref(null);
    const router = useRouter();
    const route = useRoute();
    const error = computed(() => {
      return store.state.error;
    });
    const isPending = computed(() => {
      return store.state.isPending;
    });

    let colRefTeams;
    let colRefPlayers;
    let colRefGames;
    let refGame;
    let getTeams;
    let gameId = route.query.id || null;


    // filter player2 list from player1 value
    const filtP2 = computed(() => {
      if (player1Id.value) {
        return users.value.filter((player) => player.id !== player1Id.value);
      }
    });

    // filter player3 list from filtP2
    const filtP3 = computed(() => {
      if (player2Id.value) {
        return filtP2.value.filter((player) => player.id !== player2Id.value);
      }
    });

    // filter player4 list from filtP3
    const filtP4 = computed(() => {
      if (player3Id.value) {
        return filtP3.value.filter((player) => player.id !== player3Id.value);
      }
    });

    // calculate total score for each team
    const sum1 = computed(() => {
      if (game.value.lots.length) {
        return game.value.lots
          .map((lot) => lot.score1)
          .reduce((prev, next) => prev + next);
      } else return 0;
    });
    const sum2 = computed(() => {
      if (game.value.lots.length) {
        return game.value.lots
          .map((lot) => lot.score2)
          .reduce((prev, next) => prev + next);
      } else return 0;
    });

    // calculate 10kalo percentage
    const dekaP1 = computed(() => {
      if (game.value.lots.length) {
        const count = game.value.lots.filter((lot) => lot.dekakalo === player1.value.id)
          .length;
        return ((count / game.value.lots.length) * 100).toFixed(2); // multiply by 100 and cut to two decimal numbers
      } else return 0;
    });
    const dekaP2 = computed(() => {
      if (game.value.lots.length) {
        const count = game.value.lots.filter((lot) => lot.dekakalo === player2.value.id)
          .length;
        return ((count / game.value.lots.length) * 100).toFixed(2);
      } else return 0;
    });
    const dekaP3 = computed(() => {
      if (game.value.lots.length) {
        const count = game.value.lots.filter((lot) => lot.dekakalo === player3.value.id)
          .length;
        return ((count / game.value.lots.length) * 100).toFixed(2);
      } else return 0;
    });
    const dekaP4 = computed(() => {
      if (game.value.lots.length) {
        const count = game.value.lots.filter((lot) => lot.dekakalo === player4.value.id)
          .length;
        return ((count / game.value.lots.length) * 100).toFixed(2);
      } else return 0;
    });

    // add new lot to lots array
    const addLot = async () => {
      refGame = await doc(db, "season", friendlySeason.value.id, "games", gameId); //!!! restore to seasons.value[0]
      // await getDoc(refGame).then((snapshot) => {
      //   game.value = snapshot.data()
      // })

      // game.value.lots.push({
      //   score1: scoreLot1.value || 0, // add team1 score or 0 if nothing entered
      //   score2: scoreLot2.value || 0,
      //   dekakalo: dekaKalo.value
      // })

      // lots.value.push({
      //   score1: scoreLot1.value || 0, // add team1 score or 0 if nothing entered
      //   score2: scoreLot2.value || 0,
      //   dekakalo: dekaKalo.value
      // })

      let lots = game.value.lots
      lots.push({
        score1: scoreLot1.value || 0, // add team1 score or 0 if nothing entered
        score2: scoreLot2.value || 0,
        dekakalo: dekaKalo.value,
      })

      await updateDoc(refGame, {
        lots: lots,
        "team1.sum": sum1.value, // the sum is not updated before current scoreLot is added 
        "team2.sum": sum2.value
      });

      scoreLot1.value = null;
      scoreLot2.value = null;
      dekaKalo.value = [];
    };

    // remove lot with the specific index from lots array
    const removeLot = async (index) => {
      refGame = await doc(db, "season", friendlySeason.value.id, "games", gameId); //!!! restore to seasons.value[0]
      game.value.lots.splice(index, 1);
      await updateDoc(refGame, {
        lots: game.value.lots,
        "team1.sum": sum1.value,
        "team2.sum": sum2.value
      });
    };

    //add data to database
    const addData = async () => {
      // spinner.value = true;
      //
      if (sum1.value === sum2.value) {
        store.commit("setError", "Το παιχνίδι δεν μπορεί να λήξει ισόπαλο...!");
        store.commit('setAlertColor', 'alert-danger')
        // call function closeMdl of #endGameModal to close modal
        closeEndGameModal.value.closeMdl();
        spinner.value = false;
        return;
      }
      // check if team 1 won
      const winnerTeam1 = sum1.value > sum2.value ? true : false;
      game.value = {
        live: false,
        lots: game.value.lots,
        team1: {
          player1: { id: player1.value.id, name: player1.value.name },
          player2: { id: player2.value.id, name: player2.value.name },
          win: winnerTeam1,
          sum: sum1.value,
        },
        team2: {
          player1: { id: player3.value.id, name: player3.value.name },
          player2: { id: player4.value.id, name: player4.value.name },
          win: !winnerTeam1,
          sum: sum2.value,
        },
        timestamp: serverTimestamp()
      };

      // try {
      //   error.value = null
      //   // add new document under games collection and retrieve the id
      //   const docRef = await addDoc(colRefGames, game).then((docRef) => {
      //     gameId = docRef.id
      //   })
      // } catch (err) {
      //   error.value = err.message
      // }


      updateDocs()

      // initialize all variables
      lots.value = [];
      team1.value = null;
      team2.value = null;
      player1.value = null;
      player2.value = null;
      player3.value = null;
      player4.value = null;
      player1Id.value = null;
      player2Id.value = null;
      player3Id.value = null;
      player4Id.value = null;
      scoreLot1.value = null;
      scoreLot2.value = null;
      dekaKalo.value = [];
      // error.value = null
      store.commit("setError", null);

      // call function closeMdl of #endGameModal to close modal
      closeEndGameModal.value.closeMdl();

      // redirect to Game details
      router.push({ name: "FriendlyGameDetails", params: { id: gameId } });
      spinner.value = false;
    };

    // function to update wins and loses for each player and team
    const updateDocs = async () => {
      try {
        // error.value = null
        store.commit("setError", null);
        refGame = await doc(db, "season", friendlySeason.value.id, "games", gameId);
        batch.update(refGame, game.value)
        // batch.update(doc(db, "season", friendlySeason.value.id, "players", team1.player1.id), { wins: increment(1) })
        // batch.update(doc(db, "season", friendlySeason.value.id, "players", team1.player2.id), { wins: increment(1) })
        // batch.update(doc(db, "season", friendlySeason.value.id, "players", team2.player1.id), { loses: increment(1) })
        // batch.update(doc(db, "season", friendlySeason.value.id, "players", team2.player2.id), { loses: increment(1) })
        // batch.update(doc(db, "season", friendlySeason.value.id, "teams", team1.id), { wins: increment(1) })
        // batch.update(doc(db, "season", friendlySeason.value.id, "teams", team2.id), { loses: increment(1) })
        await batch.commit()

        // store.dispatch('seasonStats', seasons.value[0].id)
      } catch (err) {
        // error.value = err.message
        store.commit("setError", err.message);
      }
    };

    // add team to db
    const addTeam = async (p1, p2) => {
      let teamName = "";
      const player1Ref = doc(db, "season", friendlySeason.value.id, "players", p1);
      const player2Ref = doc(db, "season", friendlySeason.value.id, "players", p2);
      colRefTeams = collection(db, "season", friendlySeason.value.id, "teams");
      teamName = players.value.filter(player => player.id === p1)[0].name + "-" + players.value.filter(player => player.id === p2)[0].name
      // await getDoc(player1Ref).then((docSnap) => {
      //   let name = docSnap.data().name;
      //   teamName = name;
      // });
      // await getDoc(player2Ref).then((docSnap) => {
      //   let name = docSnap.data().name;
      //   teamName = teamName + "-" + name;
      // });

      try {
        store.commit("setError", null);

        const team = {
          name: teamName,
          player1: player1Ref,
          player2: player2Ref,
          wins: 0,
          loses: 0,
        }

        const docRef = await addDoc(colRefTeams, team).then((docSnap) => {
          // store.dispatch('getTeams', colRefTeams)
          store.commit('setTotalTeams', [...teams.value, { ...team, id: docSnap.id }])
          store.commit('setUnorderedTeams', [...teams.value, { ...team, id: docSnap.id }])
          addedTeam.value = teams.value.filter((team) => team.id === docSnap.id)[0];

        });
      } catch (err) {
        store.commit("setError", err.message);
      }
    };

    const handleP2change = async () => {
      player3Id.value = null;
      let getTeam = [];
      getTeam = teams.value
        .filter(
          (team) =>
            team.player1.id === player1Id.value || team.player1.id === player2Id.value
        )
        .filter(
          (team) =>
            team.player2.id === player1Id.value || team.player2.id === player2Id.value
        )[0];
      if (!getTeam) {
        try {
          store.commit("setError", null);

          // call addTeam to add the team to the db if it does not exists
          await addTeam(player1Id.value, player2Id.value).then(() => {
            team1.value = addedTeam.value;

          });
        } catch (err) {
          store.commit("setError", err.message);
        }
      } else {
        team1.value = getTeam;
      }
    };

    const handleP4change = async () => {
      let getTeam = [];
      getTeam = teams.value
        .filter(
          (team) =>
            team.player1.id === player3Id.value || team.player1.id === player4Id.value
        )
        .filter(
          (team) =>
            team.player2.id === player3Id.value || team.player2.id === player4Id.value
        )[0];
      if (!getTeam) {
        try {
          store.commit("setError", null);

          // call addTeam to add the team to the db if it does not exists
          await addTeam(player3Id.value, player4Id.value).then(() => {
            team2.value = addedTeam.value;

          });
        } catch (err) {
          store.commit("setError", err.message);
        }
      } else {
        team2.value = getTeam;
      }
      store.commit("setError", null);

    };

    const handleStart = async () => {
      randomGame()
      startGame.value = true
      // retrieve each player from players array
      player1.value = users.value.filter((player) => player.id == player1Id.value)[0];
      player2.value = users.value.filter((player) => player.id == player2Id.value)[0];
      player3.value = users.value.filter((player) => player.id == player3Id.value)[0];
      player4.value = users.value.filter((player) => player.id == player4Id.value)[0];

      game.value = {
        live: true,
        creator: user.value.uid,
        lots: [],
        team1: {
          player1: { id: player1.value.id, name: player1.value.name },
          player2: { id: player2.value.id, name: player2.value.name },
        },
        team2: {
          player1: { id: player3.value.id, name: player3.value.name },
          player2: { id: player4.value.id, name: player4.value.name },
        },
        timestamp: serverTimestamp(),
      };

      await addDoc(
        collection(db, "season", friendlySeason.value.id, "games"),
        game.value
      ).then((docRef) => {
        gameId = docRef.id;
      });

      await onSnapshot(
        doc(db, "season", friendlySeason.value.id, "games", gameId),
        (snapshot) => {
          //!!! restore to seasons.value[0]
          game.value = snapshot.data();
        }
      );

      router.push(`/new-friendly-game?id=${gameId}`);
    }

    const randomGame = async () => {
      store.commit('setIsPending', true)
      let randomize = [player1Id.value, player2Id.value, player3Id.value, player4Id.value]
      // shuffle algorithm
      let currentIndex = randomize.length, randomIndex;
      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [randomize[currentIndex], randomize[randomIndex]] = [
          randomize[randomIndex], randomize[currentIndex]];
      }
      player1Id.value = randomize[0]
      player2Id.value = randomize[1]
      player3Id.value = randomize[2]
      player4Id.value = randomize[3]

      store.commit("setError", null);
      store.commit('setIsPending', false)
    }


    const handleReset = async () => {
      closeResetModal.value.closeMdl(); // call function closeMdl of #resetModal to close modal
      await deleteDoc(doc(db, 'season', friendlySeason.value.id, 'games', gameId))
      router.push("/new-friendly-game")
      gameId = null;
      game.value = null;
      lots.value = [];
      team1.value = null;
      team2.value = null;
      player1.value = null;
      player2.value = null;
      player3.value = null;
      player4.value = null;
      player1Id.value = null;
      player2Id.value = null;
      player3Id.value = null;
      player4Id.value = null;
      scoreLot1.value = null;
      scoreLot2.value = null;
      dekaKalo.value = [];
      startGame.value = false
      store.commit("setError", null);
    }

    // get game id from route.query to rematch game with the same teams
    const rematch = route.query.id || null;
    if (rematch) {
      // redirect to GameDetails if season id is not ready
      if (!seasons.value.length) {
        router.push("/game/friendly/" + rematch);
        return;
      }
      // error.value = null
      // isPending.value = true
      store.commit("setIsPending", true);
      const load = async () => {
        const colRefGame = doc(db, "season", friendlySeason.value.id, "games", rematch); //!!! restore to seasons.value[0]
        getDoc(colRefGame).then((snapshot) => {
          game.value = snapshot.data();
          // check if game has finished and redirect to GameDetails
          if (!game.value.live) {
            router.push("/game/friendly/" + rematch);
            return;
          }
          loadGame(rematch);
        });
      };
      load();
      // load().then(() => {
      //   const colRefGame = doc(db, "season", seasons.value[0].id, "games", rematch);  //!!! restore to seasons.value[0]
      //   getDoc(colRefGame).then((snapshot) => {
      //     game.value = snapshot.data()
      //     // check if game has finished and redirect to GameDetails
      //     if (!game.value.live) {
      //       router.push('/game/' + rematch)
      //       return
      //     }
      //     loadGame()
      //   })
      // })

      const loadGame = async (rematch) => {
        try {
          store.commit("setError", null);

          player1.value = store.state.users.filter(player => player.id === game.value.team1.player1.id)[0]
          player2.value = store.state.users.filter(player => player.id === game.value.team1.player2.id)[0]
          player3.value = store.state.users.filter(player => player.id === game.value.team2.player1.id)[0]
          player4.value = store.state.users.filter(player => player.id === game.value.team2.player2.id)[0]
          player4Id.value = player4.value.id;
          startGame.value = true

          // const colRefTeam2 = doc(
          //   db,
          //   "season",
          //   seasons.value[0].id,
          //   "teams",
          //   game.value.team2.id
          // );
          // snapDoc = await getDoc(colRefTeam2);
          // team2.value = { ...snapDoc.data(), id: snapDoc.id };

          // const player1Ref = doc(db, team1.value.player1.path);
          // snapDoc = await getDoc(player1Ref);
          // player1.value = { ...snapDoc.data(), id: snapDoc.id };

          // const player2Ref = doc(db, team1.value.player2.path);
          // snapDoc = await getDoc(player2Ref);
          // player2.value = { ...snapDoc.data(), id: snapDoc.id };

          // const player3Ref = doc(db, team2.value.player1.path);
          // snapDoc = await getDoc(player3Ref);
          // player3.value = { ...snapDoc.data(), id: snapDoc.id };

          // const player4Ref = doc(db, team2.value.player2.path);
          // snapDoc = await getDoc(player4Ref);
          // player4.value = { ...snapDoc.data(), id: snapDoc.id };
          // player4Id.value = player4.value.id;

          await onSnapshot(
            doc(db, "season", friendlySeason.value, "games", rematch),
            (snapshot) => {
              //!!! restore to seasons.value[0]
              game.value = snapshot.data();
            }
          );

          // isPending.value = false
          store.commit("setIsPending", false);
        } catch (err) {
          // isPending.value = false
          store.commit("setIsPending", false);
          // error.value = "Error: Game was not found..."
        }
      };
    }

    return {
      user,
      users,
      teams,
      players,
      team1,
      team2,
      player1Id,
      player2Id,
      player3Id,
      player4Id,
      filtP2,
      filtP3,
      filtP4,
      player1,
      player2,
      player3,
      player4,
      lots,
      game,
      random,
      addLot,
      removeLot,
      addData,
      addTeam,
      handleP2change,
      handleP4change,
      handleReset,
      handleStart,
      randomGame,
      toggleAddTeam,
      toggleReset,
      toggleEndGame,
      startGame,
      scoreLot1,
      scoreLot2,
      dekaKalo,
      sum1,
      sum2,
      dekaP1,
      dekaP2,
      dekaP3,
      dekaP4,
      spinner,
      closeModal,
      closeResetModal,
      closeEndGameModal,
      error,
      isPending,
    };
  },
};
</script>
<style>
.diamond {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: red;
  position: relative;
  top: -5px;
  display: inline-block;
}

.diamond:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: red;
}

.kalo {
  color: red;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.lot-enter-active {
  transition: all 1s ease;
}

.lot-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.lot-leave-to {
  opacity: 0;
  transform: translateX(50px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

input[type="number"]::placeholder {
  text-align: end;
}

input[type="number"] {
  min-width: 70px;
  text-align: end;
}

.w-40px {
  min-width: 40px;
}
</style>
