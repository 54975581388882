import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage, ref } from 'firebase/storage'
import { getMessaging, getToken } from 'firebase/messaging'

// firebase production
const firebaseConfig = {
    apiKey: "AIzaSyBmejF1PttsiY1JnmPod_TCOOtfGql3c0Q",
    authDomain: "diloti-ffb39.firebaseapp.com",
    projectId: "diloti-ffb39",
    storageBucket: "diloti-ffb39.appspot.com",
    messagingSenderId: "559437539083",
    appId: "1:559437539083:web:3a44fd295a517da1db640e"
};

// firebase diloti-testing
// const firebaseConfig = {
//     apiKey: "AIzaSyCTtEavCIyC48iTotf0wfCzlZeXnN4nYzc",
//     authDomain: "diloti-testing.firebaseapp.com",
//     projectId: "diloti-testing",
//     storageBucket: "diloti-testing.appspot.com",
//     messagingSenderId: "751526154826",
//     appId: "1:751526154826:web:f4282159d92dd0ce4c8cb3"
//   };

// init firebase
const app = initializeApp(firebaseConfig);

// init services
const db = getFirestore()
const auth = getAuth()
const storage = getStorage()
const storageRef = ref(storage)
const messaging = getMessaging(app)
getToken(messaging, {vapidKey: "BMTN07PXfIbEyRSQpeVTYIIsR7FqcPE5HFzupTA9T28qxmO1mGPh820OvjKa3RPqMpKMTnZofyJsN9ZFKNALdQI"})

export { db, auth, storage, storageRef, messaging }