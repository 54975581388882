import 'bootstrap/dist/css/bootstrap.css'
import 'startbootstrap-sb-admin-2/css/sb-admin-2.min.css'
import 'startbootstrap-sb-admin-2/vendor/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/js/bootstrap.js'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'

// firebase imports
import { auth } from './firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import './registerServiceWorker'

let app

// waiting for auth to be ready before mounting anything to the DOM
onAuthStateChanged(auth, () => {
    if(!app) {
        app = createApp(App).use(router).use(store).mount('#app')
    }
})

