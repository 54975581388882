<template>
    <button
        type="button"
        :class="btnClass"
        data-bs-toggle="modal"
        :data-bs-target="`#${modalId}`"
        v-html="modalText"
        @click="spinner = false"
    ></button>
    <teleport to="body">
        <div
            class="modal fade"
            :id="modalId"
            tabindex="-1"
            aria-labelledby="modal"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalTitle">{{ title }}</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                        <button
                            class="px-4"
                            v-if="!spinner"
                            type="button"
                            :class="btnClass"
                            @click="handleButton"
                        >ΝΑΙ</button>
                        <div v-else class="spinner-border text-danger" role="status">
                            <span class="visually-hidden"></span>
                        </div>
                        <button
                            class="visually-hidden"
                            ref="closeModal"
                            type="button"
                            data-bs-dismiss="modal"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>
<script>
import { computed, ref, onMounted } from "@vue/reactivity";

export default {
    name: "Modal",
    props: ["buttonText", "title", "modalId", "btnClass"],
    emits: ['handleClick'],
    setup(props, context) {
        const spinner = ref(false)
        const closeModal = ref(null)
        const modalText = ref()
        modalText.value = props.buttonText

        function closeMdl() {
            spinner.value = false
            closeModal.value.click()
        }

        function handleButton() {
            context.emit('handleClick')
            spinner.value = true
        }

        // onMounted(() => {
        //     spinner.value = false
        // })

        return { spinner, closeModal, modalText, closeMdl, handleButton }
    }

}
</script>
<style>
</style>