<template>
    <div v-if="gameProgress" class="container-fluid p-1">
        <Spinner v-if="!isReady" color="primary" />
        <div v-else>
            <div class="row d-flex justify-content-start">
                <h5 class="text-start">{{ game.timestamp }}</h5>
            </div>
            <div class="row justify-content-center">
                <div class="col table-responsive">
                    <table class="table table-bordered text-black" v-if="player4">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th :class="{ winner: game.team1.win }" colspan="3"><span v-if="player1.id === dealer"><img
                                            src="../../public/dealer.png" alt="dealer-img"
                                            class="img-thumbnail mx-1 img-responsive img-fluid"
                                            style="max-width: 30px"></span>{{ player1.name }} - {{
                                                player2.name
                                            }}<span v-if="player2.id === dealer"><img src="../../public/dealer.png" alt="dealer-img"
                                            class="img-thumbnail mx-1 img-responsive img-fluid"
                                            style="max-width: 30px"></span>
                                </th>
                                <th :class="{ winner: game.team2.win }" colspan="3"><span v-if="player3.id === dealer"><img
                                            src="../../public/dealer.png" alt="dealer-img"
                                            class="img-thumbnail mx-1 img-responsive img-fluid"
                                            style="max-width: 30px"></span>{{ player3.name }} - {{
                                                player4.name
                                            }}<span v-if="player4.id === dealer"><img src="../../public/dealer.png" alt="dealer-img"
                                            class="img-thumbnail mx-1 img-responsive img-fluid"
                                            style="max-width: 30px"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <transition-group name="game">
                                <tr v-for="(lot, index) in gameProgress.lots" :key="index">
                                    <th scope="row">{{ index + 1 }}</th>
                                    <td v-if="lot.dekakalo == player1.id">
                                        <img src="../../public/10-diamond.png" alt="10-diamond"
                                            class="img-thumbnail mx-auto img-responsive img-fluid"
                                            style="max-width: 30px;" />
                                    </td>
                                    <td v-else></td>
                                    <td>{{ lot.score1 }}</td>
                                    <td v-if="lot.dekakalo == player2.id">
                                        <img src="../../public/10-diamond.png" alt="10-diamond"
                                            class="img-thumbnail mx-auto img-responsive img-fluid"
                                            style="max-width: 30px;" />
                                    </td>
                                    <td v-else></td>
                                    <td v-if="lot.dekakalo == player3.id">
                                        <img src="../../public/10-diamond.png" alt="10-diamond"
                                            class="img-thumbnail mx-auto img-responsive img-fluid"
                                            style="max-width: 30px;" />
                                    </td>
                                    <td v-else></td>

                                    <td>{{ lot.score2 }}</td>
                                    <td v-if="lot.dekakalo == player4.id">
                                        <img src="../../public/10-diamond.png" alt="10-diamond"
                                            class="img-thumbnail mx-auto img-responsive img-fluid"
                                            style="max-width: 30px;" />
                                    </td>
                                    <td v-else></td>
                                </tr>
                            </transition-group>

                            <tr>
                                <th class="col-1" scope="row">Σύν.</th>
                                <td class="col-1">
                                    <span class="kalo">{{ Math.floor(dekaP1) }}%</span>
                                </td>
                                <th class="col-2">{{ sum1 }}</th>
                                <td class="col-1">
                                    <span class="kalo">{{ Math.floor(dekaP2) }}%</span>
                                </td>
                                <td class="col-1">
                                    <span class="kalo">{{ Math.floor(dekaP3) }}%</span>
                                </td>
                                <th class="col-2">{{ sum2 }}</th>
                                <td class="col-1">
                                    <span class="kalo">{{ Math.floor(dekaP4) }}%</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row justify-content-center mb-3 mx-auto" style="max-width: 500px;">
                <button v-if="!play" class="col-2 mx-2 p-0 btn btn-outline-primary" @click="prevButton"
                    :disabled="gameProgress.lots.length === 0">
                    <span aria-hidden="true" class="fs-1">&laquo;</span>
                </button>
                <!-- <button v-if="play" class="col-2 mx-2 p-0 btn btn-outline-danger" @click="pauseButton">
                <i class="bi bi-pause fs-1 blink"></i>
                </button>
                <button v-else class="col-2 mx-2 p-0 btn btn-outline-success" @click="playButton">
                <i class="bi bi-play fs-1"></i>
                </button>-->
                <button v-if="!play" class="col-2 mx-2 p-0 btn btn-outline-primary" @click="nextButton"
                    :disabled="gameProgress.lots.length === game.lots.length">
                    <span aria-hidden="true" class="fs-1">&raquo;</span>
                </button>
            </div>
            <!-- continue game if live is true -->
            <div v-if="deleteLive" class="col-8 p-1 mx-auto border border-info rounded">
                <p class="col-md-6 mx-auto"><em>Αν το παιχνίδι συνεχίζει να είναι LIVE παρότι έχει τελειώσει πατήστε αυτό το
                        κουμπί για να σβήσετε την ένδειξη και ΟΧΙ την ΣΥΝΕΧΕΙΑ ΠΑΙΧΝΙΔΙΟΥ</em></p>
                <button class="btn btn-info" @click="removeLive">Αφαίρεση LIVE</button>
            </div>
            <div v-if="continueGame" class="row justify-content-center mt-3">
                <router-link class="btn btn-outline-success col-5 col-md-3" style="min-width: 100px;"
                    :to="'/new-game?id=' + gameId">...ΣΥΝΕΧΕΙΑ ΠΑΙΧΝΙΔΙΟΥ</router-link>
            </div>
            <!-- redirect to new-game to rematch with the same teams -->
            <div v-if="player4 && rematchGame" class="row justify-content-center mt-3">
                <button v-if="!champion && !rematchSpinner" class="btn btn-info col-2" style="min-width: 100px;"
                    @click="rematch">ΡΕΒΑΝΣ</button>
                <div v-else class="spinner-border text-info" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { onMounted, watchEffect, onUnmounted } from "vue";
// @ is an alias to /src
import { db } from "../firebase/config";
import { doc, getDoc, serverTimestamp, collection, addDoc, onSnapshot, updateDoc } from "firebase/firestore";
import Lot from "../components/Lot.vue";
import { useRoute } from "vue-router";
import getSeasons from "../composables/getSeasons";
import router from "../router";
import { useStore } from "vuex";
import Spinner from "../components/Spinner.vue";


export default {
    name: "GameDetails",
    components: { Lot, Spinner },
    setup(props) {
        const route = useRoute()
        const store = useStore()
        store.commit('setSidebarToggle', true)

        const isReady = computed(() => {
            return store.state.isReady;
        });

        const gameId = route.params.id
        const seasons = computed(() => {
            return store.state.season;
        });
        const games = computed(() => {
            if (store.state.games) {
                return store.state.games
            }
        })
        const players = computed(() => {
            if (store.state.totalPlayers) {
                return store.state.totalPlayers;
            }
        });
        const teams = computed(() => {
            if (store.state.totalTeams) {
                return store.state.totalTeams
            }
        })
        // const game = ref()
        const game = computed(() => {
            if (games.value && isReady.value) {
                if (games.value[gameId]) {
                    return games.value[gameId]
                } else {
                    store.commit('setError', 'Δεν βρέθηκε παιχνίδι...')
                    store.commit('setAlertColor', 'alert-danger')
                    router.push('/games')
                    stop()
                    return null
                }
            }
        })

        // const gameProgress = computed(() => {
        //     return game.value
        // })
        const gameProgress = ref()
        // const team1 = ref()
        // const team2 = ref()
        // const player1 = ref()
        // const player2 = ref()
        // const player3 = ref()
        // const player4 = ref()
        const play = ref(false)
        // const live = ref(false)
        const live = computed(() => {
            if (game.value) {
                return game.value.live
            }
        })
        const user = computed(() => {
            if (store.state.user) {
                return store.state.user
            }
        })
        // const error = ref(null)
        const error = computed(() => {
            return store.state.error
        })
        const isPending = computed(() => {
            return store.state.isPending
        })

        const champion = computed(() => {
            return !!seasons.value[0].champion
        })


        let displayName
        const team1 = computed(() => {
            return teams.value[game.value.team1.id]
        })
        const team2 = computed(() => {
            return teams.value[game.value.team2.id]
        })


        const player1 = computed(() => {
            return players.value[team1.value.player1.id]
        })
        const player2 = computed(() => {
            return players.value[team1.value.player2.id]
        })
        const player3 = computed(() => {
            return players.value[team2.value.player1.id]
        })
        const player4 = computed(() => {
            return players.value[team2.value.player2.id]
        })
        const playersIds = computed(() => {
            return [player1.value.id, player2.value.id, player3.value.id, player4.value.id]
        })

        const dealer = computed(() => {
            return game.value.dealer
        })

        const rematchSpinner = ref(false)

        const rematch = async () => {
            rematchSpinner.value = true

            const colRefGames = collection(db, "season", seasons.value[0].id, "games");

            // random who deals first
            const random = Math.floor(Math.random() * playersIds.value.length)
            let rematchDealer = playersIds.value[random]

            let rematchId
            try {
                await addDoc(colRefGames, {
                    creator: user.value.uid,
                    live: true,
                    lots: [],
                    dealer: rematchDealer,
                    team1: {
                        id: team1.value.id,
                    },
                    team2: {
                        id: team2.value.id,
                    },
                    timestamp: serverTimestamp()
                }).then((docRef) => {
                    rematchId = docRef.id
                }).then(() => {
                    router.push('/new-game?id=' + rematchId)
                })
            } catch (error) {
                console.log(error);
            }

            rematchSpinner.value = false

        }

        let i = 0
        let lotInterval


        const pauseButton = () => {
            play.value = false
            stop() // the animation stops but i has already increased by 1 so
            i-- //    we decrease 1 after stop
        }

        const playButton = () => {
            play.value = true
            start()
            playInterval()
        }

        // add and remove every lot manually with next and prev buttons
        const prevButton = () => {
            if (i > 0) {
                i--
                gameProgress.value = { ...game.value, lots: game.value.lots.slice(0, i) }
            }
        }

        const nextButton = () => {
            if (i < game.value.lots.length) {
                i++
                gameProgress.value = { ...game.value, lots: game.value.lots.slice(0, i) }
            }
        }

        // calculate 10kalo percentage
        const dekaP1 = computed(() => {
            if (gameProgress.value.lots.length) {
                const count = gameProgress.value.lots.filter(lot => lot.dekakalo === player1.value.id).length
                return ((count / gameProgress.value.lots.length) * 100).toFixed(2) // multiply by 100 and cut to two decimal numbers
            } else return 0;
        })
        const dekaP2 = computed(() => {
            if (gameProgress.value.lots.length) {
                const count = gameProgress.value.lots.filter(lot => lot.dekakalo === player2.value.id).length
                return ((count / gameProgress.value.lots.length) * 100).toFixed(2)
            } else return 0;
        })
        const dekaP3 = computed(() => {
            if (gameProgress.value.lots.length) {
                const count = gameProgress.value.lots.filter(lot => lot.dekakalo === player3.value.id).length
                return ((count / gameProgress.value.lots.length) * 100).toFixed(2)
            } else return 0;
        })
        const dekaP4 = computed(() => {
            if (gameProgress.value.lots.length) {
                const count = gameProgress.value.lots.filter(lot => lot.dekakalo === player4.value.id).length
                return ((count / gameProgress.value.lots.length) * 100).toFixed(2)
            } else return 0;
        })

        // calculate total score for each team
        const sum1 = computed(() => {
            if (gameProgress.value.lots.length) {
                return gameProgress.value.lots.map(lot => lot.score1).reduce((prev, next) => prev + next)
            } else return 0
        })
        const sum2 = computed(() => {
            if (gameProgress.value.lots.length) {
                return gameProgress.value.lots.map(lot => lot.score2).reduce((prev, next) => prev + next)
            } else return 0
        })

        const continueGame = computed(() => {
            if (!user.value) {
                return false
            } else if (finishedGame.value) {
                return false
            } else {
                if (!user.value.verified) {
                    return false
                } else if (user.value.admin) {
                    return (live.value)
                } else {
                    return (live.value && playersIds.value.includes(user.value.uid))
                }
            }
            // else return live.value
        })

        const rematchGame = computed(() => {
            if (!user.value) {
                return false
            } else if (user.value.admin) {
                return (!live.value)
            } else {
                return (!live.value && playersIds.value.includes(user.value.uid))
            }
        })

        const finishedGame = computed(() => {
            if (game.value) {
                return (!!game.value.team1.win || !!game.value.team2.win)
            }
        })

        const deleteLive = computed(() => {
            if (game.value) {
                // const finishedGame = (game.value.team1.win || game.value.team2.win)
                // if (game.value.live) {
                //     return finishedGame;
                // }
                return (game.value.live && finishedGame.value)
            }
        })

        const removeLive = async () => {
            const refGame = await doc(db, "season", seasons.value[0].id, "games", gameId);
            updateDoc(refGame, {
                live: false
            })
        }

        const unsub = watchEffect(() => {
            gameProgress.value = game.value
            if (game.value) {
                i = game.value.lots.length
            }
        })

        onUnmounted(() => {
            unsub()
        })


        return { user, isReady, game, gameProgress, gameId, team1, team2, player1, player2, player3, player4, dealer, dekaP1, dekaP2, dekaP3, dekaP4, sum1, sum2, pauseButton, playButton, prevButton, nextButton, rematch, removeLive, deleteLive, play, live, continueGame, rematchGame, champion, rematchSpinner, error }
    }

}
</script>
<style scoped>
.winner {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb),
            var(--bs-bg-opacity)) !important;
}

.blink {
    animation: repeat 0.5s ease;
    animation-iteration-count: infinite;
}

@keyframes repeat {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.game-enter-active,
.game-leave-active {
    transition: all 0.5s ease;
}

.game-enter-from,
.game-leave-to {
    opacity: 0;
    transform: translateY(30px);
}</style>