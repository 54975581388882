<template>
  <nav class="navbar navbar-light bg-light sticky-top">
    <div class="container-fluid">
      <div class="col-4 d-flex justify-content-start">
        <router-link to="/">
          <img src="../assets/cards_5569.png" class="img-fluid mx-4" />
        </router-link>
        <h5 class="d-none d-md-block nav-link link-dark fs-4">Δηλωτάρα</h5>
      </div>
      <div class="col-6 d-flex justify-content-end align-items-center">
        <router-link v-if="live" class="list-group-item text-danger ms-auto mx-2 pulse rounded"
          :to="({ name: 'Games' })">LIVE</router-link>
        <router-link v-if="liveFriendly" class="list-group-item text-danger ms-auto mx-2 pulse rounded"
          :to="({ name: 'FriendlyGames' })">ΦΙΛΙΚΟ</router-link>
        <router-link v-if="!user" to="/login">
          <i class="bi bi-person-fill fs-2 mx-2"></i>
        </router-link>
        <div class="d-flex" v-else>
          <span>Hi, {{ user.displayName }}</span>
          <router-link to="/profile">
            <i class="bi bi-person-fill fs-2 mx-2"></i>
          </router-link>
        </div>

        <!-- <Modal
          v-else
          :btnClass="'btn text-danger fs-2'"
          :buttonText="`<i class='btn bi bi-person-x-fill fs-2 text-danger'></i>`"
          :title="'Έξοδος;'"
          :modalId="'logoutModal'"
          ref="closeLogoutModal"
          @handleClick="handleLogout"
        ></Modal>-->
        <button ref="togglerButton" class="navbar-toggler me-4" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header bg-light">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            <img src="../assets/cards_5569.png" class="img-fluid mx-4" />
          </h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <router-link @click="togglerButton.click()" class="mb-2 btn"
              :class="path === '/' ? 'btn-primary' : 'btn-outline-primary'" to="/">Αρχική</router-link>
            <router-link v-if="!friendly" @click="togglerButton.click()" class="mb-2 btn"
              :class="path === '/stats' ? 'btn-primary' : 'btn-outline-primary'" to="/stats">Στατιστικά</router-link>
            <router-link v-if="!friendly" @click="togglerButton.click()" class="mb-2 btn"
              :class="path === '/head-2-head' ? 'btn-primary' : 'btn-outline-primary'" to="/head-2-head">Head-to-head
            </router-link>
            <router-link @click="togglerButton.click()" class="mb-2 btn"
              :class="path === '/teams' ? 'btn-primary' : 'btn-outline-primary'" to="/teams">Ομάδες</router-link>
            <router-link v-if="!friendly" @click="togglerButton.click()" class="mb-2 btn"
              :class="path === '/games' ? 'btn-primary' : 'btn-outline-primary'" to="/games">Παιχνίδια</router-link>
            <router-link v-else @click="togglerButton.click()" class="mb-2 btn"
              :class="path === '/friendly-games' ? 'btn-primary' : 'btn-outline-primary'" to="/games">Φιλικά</router-link>
            <router-link @click="togglerButton.click()" class="mb-2 btn"
              :class="path === '/archive' ? 'btn-primary' : 'btn-outline-primary'" to="/archive">Αρχείο</router-link>
            <router-link v-if="!friendly" @click="newGame" class="btn btn-success" to="/new-game">Νέο Παιχνίδι
            </router-link>
            <router-link v-else @click="newGame" class="btn btn-success" to="/new-friendly-game">Νέο Φιλικό Παιχνίδι
            </router-link>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { watchEffect } from 'vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { signOut } from 'firebase/auth'
import { auth } from "../firebase/config";
// import fetchUser from "../composables/fetchUser";
import { getAuth } from 'firebase/auth'

import router from "../router";
import Modal from "./Modal.vue";

export default {
  name: "Navbar",
  components: { Modal },
  setup() {
    const togglerButton = ref();
    const route = useRoute();
    const path = ref();
    // const { user } = fetchUser();
    const router = useRouter();
    const store = useStore();
    const closeLogoutModal = ref(null)
    // getAuth()
    //   .getUser("PZWvuNWofYY8fGSrTmyIYwaUSEo2")
    //   .then((userRecord) => {
    //     // See the UserRecord reference doc for the contents of userRecord.
    //     console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
    //   })
    //   .catch((error) => {
    //     console.log('Error fetching user data:', error);
    //   });
    const user = computed(() => {
      return store.state.user
    })
    const friendly = computed(() => {
      if (store.state.isReady) {
        return !!store.state.season[0].champion
      }
    })
    const games = computed(() => {
      if (store.state.isReady) {
        return store.state.games
      } else return []
    })
    const friendlyGames = computed(() => {
      if (store.state.isReady) {
        return store.state.friendlyGames
      } else return []
    })

    const handleLogout = () => {
      signOut(auth);
      closeLogoutModal.value.closeMdl()
      router.push("/login");
    };
    const live = computed(() => {
      if (games.value.length) {
        return games.value.map(game => game.live).reduce((prev, next) => (prev || next));
      } else return false
    });

    const liveFriendly = computed(() => {
       if (friendlyGames.value.length) {
        return friendlyGames.value.map(game => game.live).reduce((prev, next) => (prev || next));
      } else return false      
    });

    const newGame = () => {
      togglerButton.value.click()
      if (!user.value) {
        store.commit('setError', 'Δεν είστε εγγεγραμμένος χρήστης...')
        store.commit('setAlertColor', 'alert-danger')
      }
    }
    watchEffect(() => {
      path.value = route.path;
    });
    return { togglerButton, path, handleLogout, newGame, live, liveFriendly, user, closeLogoutModal, friendly };
  },
  components: { Modal }
}
</script>
<style>
.pulse {
  animation: Pulsate 1s infinite;
}

@keyframes Pulsate {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}
</style>
