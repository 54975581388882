import { createStore } from "vuex";
// firebase imports
import { auth } from "../firebase/config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { db } from "../firebase/config";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
  onSnapshot,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { computed, ref } from "@vue/reactivity";

let seasons = [];
let users = {};
let players = {};
let unorderedPlayers = {};
let totalPlayers = {};
let teams = {};
let orderedTeams = {};
let unorderedTeams = {};
let games = {};
let friendlyGames = [];
let archiveSeasons = [];

const colRefSeasons = collection(db, "season");
// order seasons (newest first)
const q = query(colRefSeasons, orderBy("timestamp", "desc"));

const loadSeasons = async () => {
  await getDocs(q).then((snapshot) => {
    let docs = [];
    snapshot.docs.forEach(async (docSnap) => {
      docs.push({ ...docSnap.data(), id: docSnap.id });
    });
    seasons = docs;
  });
};

// const loadSeasons = async () => {
//   await getDocs(q).then((snapshot) => {
//     let docs = [];
//     // let archive = [];
//     snapshot.docs.forEach(async (docSnap) => {
//       docs.push({ ...docSnap.data(), id: docSnap.id });
//       // if (docSnap.data().champion) {
//       //   const championRef = doc(db, "users", docSnap.data().champion);
//       //   const champion = await getDoc(championRef);
//       //   const teamChampionRef = doc(
//       //     db,
//       //     "season",
//       //     docSnap.id,
//       //     "teams",
//       //     docSnap.data().teamChampion
//       //   );
//       //   const teamChampion = await getDoc(teamChampionRef);
//       //   archive.push({
//       //     ...docSnap.data(),
//       //     champion: champion.data(),
//       //     teamChampion: teamChampion.data(),
//       //     id: docSnap.id,
//       //   });
//       // }
//     });
//     seasons = docs;
//     // archiveSeasons = archive;
//     // store.commit("setArchiveSeasons", archiveSeasons);
//   });
// };
// loadSeasons().then(async () => {
//   await store.commit("setSeason", seasons);
//   // await store.commit("setArchiveSeasons", archiveSeasons);
//   await store.dispatch("seasonStats", seasons[0].id);
//   store.commit("setIsPending", false);
// });

function getUsersSnapshot(snapshot) {
  let docs = {};
  let userTable = {};
  snapshot.docs.forEach((doc) => {
    let totalGames = doc.data().wins + doc.data().loses;
    let percent = ((doc.data().wins / totalGames) * 100).toFixed(2);
    if (totalGames >= 30) {
      // how many games user must complete to enter the table
      userTable[doc.id] = { ...doc.data(), percent, id: doc.id };
    }
    docs[doc.id] = { ...doc.data(), id: doc.id };
  });

  users = docs;

  return { users, userTable };
}

function getPlayersSnapshot(snapshot) {
  let players = {};
  let unorderedPlayers = {};
  snapshot.docs.forEach(async (docSnap) => {
    let totalGames = docSnap.data().wins + docSnap.data().loses;
    let displayName = "";
    let photoURL = "";
    if (docSnap.data().user) {
      photoURL = users[docSnap.data().user.id].photoURL || "";
      displayName = users[docSnap.data().user.id].name.trim();
    }
    if (totalGames < 30) {
      // how many games player must complete to enter the table
      unorderedPlayers[docSnap.id] = {
        ...docSnap.data(),
        id: docSnap.id,
        photoURL,
        name: displayName || docSnap.data().name,
      };
    } else {
      let percent = ((docSnap.data().wins / totalGames) * 100).toFixed(2);
      players[docSnap.id] = {
        ...docSnap.data(),
        id: docSnap.id,
        photoURL,
        name: displayName || docSnap.data().name,
        percent,
      };
    }
  });

  return { players, unorderedPlayers };
}

function getColTeamsSnapshot(snapshot) {
  let docs = {};
  let teamTable = {};

  snapshot.docs.forEach((doc) => {
    // console.log(doc.data().player1.name, doc.data().player2.name);
    const player1 = users[doc.data().player1.id];
    const player2 = users[doc.data().player2.id];

    let totalGames = doc.data().wins + doc.data().loses;
    let percent = ((doc.data().wins / totalGames) * 100).toFixed(2);
    if (totalGames >= 20) {
      teamTable[doc.id] = {
        ...doc.data(),
        id: doc.id,
        percent,
        name: player1.name + "-" + player2.name,
      };
    }
    docs[doc.id] = {
      ...doc.data(),
      id: doc.id,
      name: player1.name + "-" + player2.name,
    };
  });

  return { docs, teamTable };
}

function getTeamsSnapshot(snapshot) {
  orderedTeams = {};
  unorderedTeams = {};
  snapshot.docs.forEach(async (docSnap) => {
    let totalGames = docSnap.data().wins + docSnap.data().loses;
    let teamName = "";
    let player1 = totalPlayers[docSnap.data().player1.id];
    let player2 = totalPlayers[docSnap.data().player2.id];

    teamName = player1.name + "-" + player2.name;

    // team has to complete 20 games to get on the board list
    if (totalGames < 20) {
      unorderedTeams[docSnap.id] = {
        ...docSnap.data(),
        id: docSnap.id,
        name: teamName,
      };
    } else {
      let percent = ((docSnap.data().wins / totalGames) * 100).toFixed(2);
      orderedTeams[docSnap.id] = {
        ...docSnap.data(),
        id: docSnap.id,
        name: teamName,
        percent,
      };
    }
  });

  return { orderedTeams, unorderedTeams };
}

function getGamesSnapshot(snapshot) {
  games = {};
  let timestamp;
  let wins = 0;
  let loses = 0;
  snapshot.docs.forEach((doc) => {
    // teams = store.state.totalTeams;
    // let team1 = teams[doc.data().team1.id];
    // let team2 = teams[doc.data().team2.id];
    let team1 = teams[doc.data().team1.id] || Object.values(teams)
      .filter(
        (team) =>
          team.player1.id === doc.data().team1.player1.id ||
          team.player1.id === doc.data().team1.player2.id
      )
      .filter(
        (team) =>
          team.player2.id === doc.data().team1.player1.id ||
          team.player2.id === doc.data().team1.player2.id
      )[0];
    let team2 = teams[doc.data().team2.id] || Object.values(teams)
      .filter(
        (team) =>
          team.player1.id === doc.data().team2.player1.id ||
          team.player1.id === doc.data().team2.player2.id
      )
      .filter(
        (team) =>
          team.player2.id === doc.data().team2.player1.id ||
          team.player2.id === doc.data().team2.player2.id
      )[0];
    let player1 = { id: team1.player1.id, win: doc.data().team1.win };
    let player2 = { id: team1.player2.id, win: doc.data().team1.win };
    let player3 = { id: team2.player1.id, win: doc.data().team2.win };
    let player4 = { id: team2.player2.id, win: doc.data().team2.win };
    if (doc.data().timestamp) {
      timestamp = doc.data().timestamp.toDate().toLocaleDateString();
    }
    games[doc.id] = {
      ...doc.data(),
      id: doc.id,
      team1: { ...doc.data().team1, id: team1.id},
      team2: { ...doc.data().team2, id: team2.id},
      timestamp: timestamp,
      teamName1: team1.name,
      teamName2: team2.name,
      player1,
      player2,
      player3,
      player4,
    };

  });

  console.log(games);

  return { games };
}

function sortByPercent(value) {
  const sorted = {};
  Object.keys(value)
    .sort((a, b) => value[b].percent - value[a].percent)
    .forEach((id) => {
      sorted[id] = value[id];
    });
  return sorted;
}

const store = createStore({
  state: {
    user: auth.currentUser,
    error: null,
    alertColor: "",
    isPending: false,
    isReady: false,
    sidebarToggle: true,
    season: [],
    users: {},
    players: {},
    unorderedPlayers: {},
    totalPlayers: {},
    games: {},
    allGames: {},
    allStats: [],
    allTeamStats: [],
    friendlyGames: [],
    teams: {},
    orderedTeams: {},
    unorderedTeams: {},
    totalTeams: {},
    archiveSeasons: [],
    userTable: {},
    teamTable: {},
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setAlertColor(state, payload) {
      state.alertColor = payload;
    },
    setIsPending(state, payload) {
      state.isPending = payload;
    },
    setIsReady(state, payload) {
      state.isReady = payload;
    },
    setSidebarToggle(state, payload) {
      state.sidebarToggle = payload;
    },
    setSeason(state, payload) {
      state.season = payload;
    },
    setArchiveSeasons(state, payload) {
      state.archiveSeasons = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    setPlayers(state, payload) {
      state.players = payload;
    },
    setUnorderedPlayers(state, payload) {
      state.unorderedPlayers = payload;
    },
    setTotalPlayers(state, payload) {
      state.totalPlayers = payload;
    },
    setGames(state, payload) {
      state.games = payload;
    },
    setAllGames(state, payload) {
      state.allGames = payload;
    },
    setAllStats(state, payload) {
      state.allStats = payload;
    },
    setAllTeamStats(state, payload) {
      state.allTeamStats = payload;
    },
    setFriendlyGames(state, payload) {
      state.friendlyGames = payload;
    },
    setTeams(state, payload) {
      state.teams = payload;
    },
    setOrderedTeams(state, payload) {
      state.orderedTeams = payload;
    },
    setUnorderedTeams(state, payload) {
      state.unorderedTeams = payload;
    },
    setTotalTeams(state, payload) {
      state.totalTeams = payload;
    },
    setUserTable(state, payload) {
      state.userTable = payload;
    },
    setTeamTable(state, payload) {
      state.teamTable = payload;
    },
  },
  actions: {
    async seasonStats(context, seasonId) {
      // console.log(`season stats dispatched:${seasonId}`);
      context.commit("setError", null);
      // context.commit("setIsPending", true);
      // context.commit("setIsReady", false);
      try {
        const colRefPlayers = collection(db, "season", seasonId, "players");
        const colRefGames = collection(db, "season", seasonId, "games"); // restore to seasonId !!!
        const colRefTeams = collection(db, "season", seasonId, "teams");
        const colRefUsers = collection(db, "users");
        const colTeams = collection(db, "teams");
        const queryGames = query(colRefGames, orderBy("timestamp", "desc"));

        const colRefFriendlyGames = collection(
          db,
          "season",
          "mGi2x2WCOV3R3IHt27Bl",
          "games"
        );
        const queryFriendlyGames = query(
          colRefFriendlyGames,
          orderBy("timestamp", "desc")
        );

        store.dispatch("getUsers", { colRefUsers, colRefPlayers });
        store.dispatch("getColTeams", { colTeams, colRefTeams });
        store.dispatch("getPlayers", colRefPlayers);
        store.dispatch("getTeams", colRefTeams);
        store.dispatch("getGames", queryGames);
        store.dispatch("getFriendlyGames", queryFriendlyGames);
        // store.dispatch("getAllStats");
      } catch (err) {
        console.log(err);
      }
    },
    async getUsers(context, { colRefUsers, colRefPlayers }) {
      await onSnapshot(colRefUsers, (snapshot) => {
        if (!snapshot.docs) {
          return;
        }
        const { users, userTable } = getUsersSnapshot(snapshot);

        const sortUserTable = sortByPercent(userTable);
        // console.log(sortUserTable);
        context.commit("setUsers", users);
        context.commit("setUserTable", sortUserTable);
        store.dispatch("getPlayers", colRefPlayers);
        // console.log(users);
      });
    },
    async getColTeams(context, { colTeams, colRefTeams }) {
      await onSnapshot(colTeams, (snapshot) => {
        // teams = {};
        if (!snapshot.docs) {
          return;
        }
        const { docs, teamTable } = getColTeamsSnapshot(snapshot);

        const sortTeamTable = sortByPercent(teamTable);

        // teams = docs;
        context.commit("setTeams", docs);
        context.commit("setTeamTable", sortTeamTable);
        // console.log(teams);
        // store.dispatch("getTeams", colRefTeams);
      });
    },
    async getPlayers(context, colRefPlayers) {
      await onSnapshot(colRefPlayers, (snapshot) => {
        if (!snapshot.docs) {
          return;
        }
        const { players, unorderedPlayers } = getPlayersSnapshot(snapshot);

        const sortedPlayers = sortByPercent(players);

        totalPlayers = Object.assign({}, sortedPlayers, unorderedPlayers);
        context.commit("setPlayers", sortedPlayers);
        context.commit("setUnorderedPlayers", unorderedPlayers);
        context.commit("setTotalPlayers", totalPlayers);
      });
    },

    async getTeams(context, colRefTeams) {
      await onSnapshot(colRefTeams, (snapshot) => {
        if (!snapshot.docs) {
          return;
        }
        teams = {};
        const { orderedTeams, unorderedTeams } = getTeamsSnapshot(snapshot);
        teams = Object.assign({}, orderedTeams, unorderedTeams);

        const sortedOrderedTeams = sortByPercent(orderedTeams);

        context.commit("setOrderedTeams", sortedOrderedTeams);
        context.commit("setUnorderedTeams", unorderedTeams);
        context.commit("setTotalTeams", teams);
      });
    },
    async seedTeams(context, teams) {
      const colTeams = collection(db, "teams");
      teams.forEach(async (team) => {
        const teamRef = doc(db, "season", seasons[0].id, "teams", team.id);
        const player1 = store.state.totalPlayers.filter(
          (player) => player.id === team.player1.id
        )[0];
        const player2 = store.state.totalPlayers.filter(
          (player) => player.id === team.player2.id
        )[0];

        await addDoc(colTeams, {
          player1: player1.user,
          player2: player2.user,
        }).then(async (res) => {
          const getTeamRef = doc(db, "teams", res.id);
          await updateDoc(teamRef, {
            team: getTeamRef,
          });
        });
      });
    },
    async getGames(context, queryGames) {
      await onSnapshot(queryGames, (snapshot) => {
        if (!snapshot.docs) {
          return;
        }
        const { games } = getGamesSnapshot(snapshot);
        if (games) {
          context.commit("setGames", games);
          context.commit("setIsReady", true);
          context.commit("setIsPending", false);
          // console.clear();
          // context.dispatch("getArchive", seasons);
        }
      });
    },
    async getAllStats() {
      store.commit("setIsReady", false);
      let docs = [];
      let teamDocs = [];
      store.commit("setIsPending", true);
      const statsRef = collection(
        db,
        "allstats",
        "48HCLMNKEIarqlz6iU76",
        "h2h"
      );
      const statsTeamRef = collection(
        db,
        "allstats",
        "48HCLMNKEIarqlz6iU76",
        "teamH2h"
      );
      await getDocs(statsRef).then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
      });
      await getDocs(statsTeamRef).then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          teamDocs.push({ ...doc.data(), id: doc.id });
        });
      });
      store.commit("setAllStats", docs);
      store.commit("setAllTeamStats", teamDocs);
      store.commit("setIsReady", true);
    },
    async getAllGames(context, { p1, p2 }) {
      store.commit("setIsPending", true);
      // let allPlayers = store.state.totalPlayers;
      // for (let i = 0; i < allPlayers.length; i++) {
      //   for (let j = i + 1; j < allPlayers.length; j++) {
      //     let getGames = store.state.games
      //       .filter(
      //         (game) =>
      //           (game.player1.id === allPlayers[i].id || game.player2.id === allPlayers[i].id) &&
      //           (game.player3.id === allPlayers[j].id || game.player4.id === allPlayers[j].id)
      //       )
      //       .concat(
      //         store.state.games.filter(
      //           (game) =>
      //             (game.player1.id === allPlayers[j].id || game.player2.id === allPlayers[j].id) &&
      //             (game.player3.id === allPlayers[i].id || game.player4.id === allPlayers[i].id)
      //         )
      //       );

      //     // if (!getGames.length) {
      //     //   store.commit("setIsPending", false);
      //     //   return (
      //     //     (player1.value.h2hWins = "Δε βρέθηκαν παιχνίδια"),
      //     //     (player2.value.h2hWins = ""),
      //     //     (barPlayer1.value = 100),
      //     //     (barPlayer2.value = 0)
      //     //   );
      //     // }
      //     let player1Win = 0;
      //     let player2Win = 0;
      //     getGames.forEach((game) => {
      //       switch (allPlayers[i].id) {
      //         case game.player1.id:
      //           game.player1.win ? player1Win++ : player2Win++;
      //           break;
      //         case game.player2.id:
      //           game.player2.win ? player1Win++ : player2Win++;
      //           break;
      //         case game.player3.id:
      //           game.player3.win ? player1Win++ : player2Win++;
      //           break;
      //         case game.player4.id:
      //           game.player4.win ? player1Win++ : player2Win++;
      //           break;
      //       }
      //     });
      //     console.log(`${allPlayers[i].user.id}:${player1Win}`, `${allPlayers[j].user.id}:${player2Win}`);
      //     addDoc(statsRef, {
      //       player1: { user: allPlayers[i].user, wins: player1Win },
      //       player2: { user: allPlayers[j].user, wins: player2Win },
      //     })
      //   }
      // }

      store.commit("setIsPending", false);
    },
    async getFriendlyGames(context, queryFriendlyGames) {
      await onSnapshot(queryFriendlyGames, (snapshot) => {
        if (!snapshot.docs) {
          return;
        }
        let docs = [];
        snapshot.docs.forEach((doc) => {
          teams = store.state.totalTeams;
          let player1 = {
            id: doc.data().team1.player1.id,
            win: doc.data().team1.win,
          };
          let player2 = {
            id: doc.data().team1.player2.id,
            win: doc.data().team1.win,
          };
          let player3 = {
            id: doc.data().team2.player1.id,
            win: doc.data().team2.win,
          };
          let player4 = {
            id: doc.data().team2.player2.id,
            win: doc.data().team2.win,
          };
          let timestamp = doc.data().timestamp.toDate().toLocaleDateString();
          docs.push({
            ...doc.data(),
            id: doc.id,
            timestamp: timestamp,
            teamName1:
              doc.data().team1.player1.name +
              "-" +
              doc.data().team1.player1.name,
            teamName2:
              doc.data().team2.player1.name +
              "-" +
              doc.data().team2.player1.name,
            player1,
            player2,
            player3,
            player4,
          });
        });
        if (docs) {
          friendlyGames = docs;
          context.commit("setFriendlyGames", friendlyGames);
          context.commit("setIsReady", true);
          // context.dispatch("getArchive", seasons);
        }
      });
    },
    async getArchive(context, seasons) {
      let docs = [];
      seasons.forEach(async (season) => {
        if (season.champion) {
          const champion = await getDoc(season.champion);
          const teamChampionRef = doc(
            db,
            "season",
            season.id,
            "teams",
            season.teamChampion
          );
          const teamChampion = await getDoc(teamChampionRef);
          docs.push({
            ...season,
            champion: champion.data(),
            teamChampion: teamChampion.data(),
          });
        }
      });
      context.commit("setArchiveSeasons", docs);
    },
    async signup(context, { email, password }) {
      context.commit("setError", null);
      context.commit("setIsPending", true);
      try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        if (res) {
          context.commit("setUser", res.user);
        } else {
          throw new Error("Could not complete signup");
        }
        context.commit("setError", null);
        // context.commit("setIsPending", false);
      } catch (err) {
        context.commit("setError", err);
        context.commit("setIsPending", false);
      }
    },
    async login(context, { email, password }) {
      context.commit("setError", null);
      context.commit("setIsPending", true);
      try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        if (res) {
          context.commit("setUser", res.user);
        } else {
          throw new Error("Could not complete login");
        }
        context.commit("setError", null);
        // context.commit("setIsPending", false);
      } catch (err) {
        context.commit("setError", err);
        context.commit("setIsPending", false);
      }
    },
    async logout(context, payload) {
      await signOut(auth);
    },
    authChange(context, payload) {
      context.commit("setUser", payload);
    },
    async reset(context, { email }) {
      const actionCodeSettings = {
        url: "https://diloti-ffb39.web.app/",
      };
      context.commit("setError", null);
      context.commit("setIsPending", true);
      try {
        const res = await sendPasswordResetEmail(
          auth,
          email,
          actionCodeSettings
        ).then(() => {
          if (!res) {
            throw new Error("Could not send reset password...");
          }
          context.commit("setError", null);
          context.commit("setIsPending", false);
        });
      } catch (err) {
        context.commit("setError", err);
        context.commit("setIsPending", false);
      }
    },
    async reloadSeasons(context, seasonId) {
      loadSeasons().then(async () => {
        await store.commit("setSeason", seasons);
        await store.dispatch("seasonStats", seasonId);
        store.commit("setIsPending", false);
      });
    },
    // async addWins(context, payload) {
    //
    // }
  },
});

// export store
export default store;
