<template>
    <!-- Topbar -->
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 sticky-top shadow">

        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" @click="toggleSidebar">
            <i class="fa fa-bars"></i>
        </button>

        <h4 class="d-none d-md-block nav-link text-primary fs-4">Δηλωτάρα</h4>


        <!-- Topbar Search -->
        <!-- <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
            <div class="input-group">
                <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                    aria-label="Search" aria-describedby="basic-addon2">
                <div class="input-group-append">
                    <button class="btn btn-primary" type="button">
                        <i class="fas fa-search fa-sm"></i>
                    </button>
                </div>
            </div>
        </form> -->

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto align-items-center">

            <router-link v-if="live" class="nav-link text-danger ms-auto mx-2 pulse border border-danger rounded-3" to="/games?live=true">
                LIVE</router-link>
            <router-link v-if="liveFriendly" class="nav-link text-danger ms-auto mx-2 pulse rounded"
                :to="({ name: 'FriendlyGames' })">ΦΙΛΙΚΟ</router-link>


            <div class="topbar-divider"></div>

            <!-- Nav Item - User Information -->
            <li v-if="user" class="nav-item dropdown no-arrow">
                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <span class="mr-2 d-lg-inline text-gray-600 small">{{ user.displayName }}</span>
                    <img v-if="user.photoURL" :src="user.photoURL" style="max-width: 30px; max-height: 30px;"
                        alt="photoURL" class="img-profile rounded" />
                    <i v-else class="bi bi-person-circle me-1"></i>
                    <!-- <img class="img-profile rounded-circle" src="img/undraw_profile.svg"> -->
                </a>
                <!-- Dropdown - User Information -->
                <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                    <router-link class="dropdown-item" to="/profile">
                        <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                        Profile
                    </router-link>
                    <!-- <a class="dropdown-item" href="#">
                        <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                        Settings
                    </a>
                    <a class="dropdown-item" href="#">
                        <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                        Activity Log
                    </a> -->
                    <div class="dropdown-divider"></div>
                    <button class="dropdown-item" @click="logout">
                        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                        Logout
                    </button>
                </div>
            </li>
            <li v-else class="nav-item">
                <router-link class="nav-link text-primary" to="/login"><span class="me-1">Είσοδος</span><i
                        class="fas fa-sign-in-alt"></i>
                </router-link>
            </li>

        </ul>

    </nav>
    <!-- End of Topbar -->
</template>
<script>
import { useStore } from 'vuex'
import { computed, ref } from "@vue/reactivity";
import { watchEffect } from 'vue'
import { useRouter, useRoute } from "vue-router";

export default {
    name: "Topbar",
    setup() {
        const togglerButton = ref();
        const route = useRoute();
        const path = ref();
        // const { user } = fetchUser();
        const router = useRouter();
        const closeLogoutModal = ref(null)
        const store = useStore();

        const user = computed(() => {
            return store.state.user
        })
        const friendly = computed(() => {
            if (store.state.isReady) {
                return !!store.state.season[0].champion
            }
        })
        const games = computed(() => {
            if (store.state.isReady) {
                return store.state.games
            } else return {}
        })
        const friendlyGames = computed(() => {
            if (store.state.isReady) {
                return store.state.friendlyGames
            } else return []
        })

        const handleLogout = () => {
            signOut(auth);
            closeLogoutModal.value.closeMdl()
            router.push("/login");
        };
        const live = computed(() => {
            if (Object.values(games.value).length) {
                return Object.values(games.value).map(game => game.live).reduce((prev, next) => (prev || next));
            } else return false
        });

        const liveFriendly = computed(() => {
            if (friendlyGames.value.length) {
                return friendlyGames.value.map(game => game.live).reduce((prev, next) => (prev || next));
            } else return false
        });

        const newGame = () => {
            togglerButton.value.click()
            if (!user.value) {
                store.commit('setError', 'Δεν είστε εγγεγραμμένος χρήστης...')
                store.commit('setAlertColor', 'alert-danger')
            }
        }
        watchEffect(() => {
            path.value = route.path;
        });
        const sidebarToggle = computed(() => {
            return store.state.sidebarToggle
        })

        const toggleSidebar = () => {
            store.commit('setSidebarToggle', !store.state.sidebarToggle)
        }

        const logout = () => {
            store.dispatch('logout')
                .then(() => {
                    router.push('/login')
                })
        }

        // const consoleLog = () => {
        //     console.log(Object.values(games.value).map(game => game.live))
        // }

        return { sidebarToggle, toggleSidebar, togglerButton, path, handleLogout, newGame, live, liveFriendly, user, closeLogoutModal, friendly, logout }
    }

}
</script>
<style>
.pulse {
    animation: Pulsate 1s infinite;
}

@keyframes Pulsate {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}
</style>