<template>
    <div class="container-fluid p-1">
        <div class="row">
            <div class="d-flex col-md-8 mx-auto justify-content-start mt-3">
                <h3 class="text-dark">Παιχνίδια</h3>
            </div>
        </div>
        <Spinner v-if="!isReady" color="primary" />
        <div v-else class="col-md-8 mx-auto">
            <div class="row">
                <div class="col d-flex justify-content-between">
                    <div>
                        <nav v-if="!liveWatch" aria-label="Page navigation">
                            <ul class="pagination">
                                <li class="page-item" :class="{ disabled: activePage === 1 }">
                                    <button class="page-link" @click="activePage = 1" :disabled="activePage === 1">
                                        <span aria-hidden="true">&laquo;</span>
                                    </button>
                                </li>
                                <li class="page-item" :class="{ disabled: activePage === 1 }">
                                    <button class="page-link" @click="activePage--" :disabled="activePage === 1">
                                        <span aria-hidden="true">&lt;</span>
                                    </button>
                                </li>
                                <li class="page-item" :class="{ active: activePage === page }" v-for="page in computedPages"
                                    :key="page">
                                    <button class="page-link" @click="activePage = page">{{ page }}</button>
                                </li>
                                <li class="page-item" :class="{ disabled: activePage === pages }">
                                    <button class="page-link" @click="activePage++" :disabled="activePage === pages">
                                        <span aria-hidden="true">&gt;</span>
                                    </button>
                                </li>
                                <li class="page-item" :class="{ disabled: activePage === pages }">
                                    <button class="page-link" @click="activePage = pages" :disabled="activePage === pages">
                                        <span aria-hidden="true">&raquo;</span>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="form-check form-switch p-2">
                        <input class="form-check-input" type="checkbox" role="switch" id="showOnlyLiveGames"
                            v-model="showLiveGames" @click="show">
                        <label class="form-check-label text-danger" for="showOnlyLiveGames">Only Live</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="!liveWatch">
                    <router-link v-if="games" class="list-group-item list-group-item-action px-0"
                        v-for="(game, index) in paginatedGames" :key="index"
                        :to="{ name: 'GameDetails', params: { id: index } }">
                        <div class="d-flex flex-row">
                            <div v-if="game.live"
                                class="col-2 d-flex justify-content-center align-items-center border-end px-1   "
                                style="min-width: 80px;">
                                <h4 class="text-danger pulse">LIVE</h4>
                            </div>
                            <div v-else class="col-2 d-flex flex-column align-items-center border-end px-1"
                                style="min-width: 90px;">
                                <span class="fs-6 my-auto">{{ game.timestamp }}</span>
                            </div>
                            <div class="col-9 align-items-center">
                                <div class="col d-flex justify-content-between" :class="{ bold: game.team1.win }">
                                    <span>{{ game.teamName1 }}</span>
                                    <span>{{ game.team1.sum }}</span>
                                </div>
                                <div class="col d-flex justify-content-between" :class="{ bold: game.team2.win }">
                                    <span>{{ game.teamName2 }}</span>
                                    <span>{{ game.team2.sum }}</span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div v-else>
                    <router-link v-if="liveGames" class="list-group-item list-group-item-action px-0"
                        v-for="(game, index) in liveGames" :key="index"
                        :to="{ name: 'GameDetails', params: { id: game.id } }">
                        <div class="d-flex flex-row">
                            <div v-if="game.live"
                                class="col-2 d-flex justify-content-center align-items-center border-end px-1   "
                                style="min-width: 80px;">
                                <h4 class="text-danger pulse">LIVE</h4>
                            </div>
                            <div v-else class="col-2 d-flex flex-column align-items-center border-end px-1"
                                style="min-width: 80px;">
                                <span>{{ game.timestamp }}</span>
                            </div>
                            <div class="col-9 align-items-center">
                                <div class="col d-flex justify-content-between" :class="{ bold: game.team1.win }">
                                    <span>{{ game.teamName1 }}</span>
                                    <span>{{ game.team1.sum }}</span>
                                </div>
                                <div class="col d-flex justify-content-between" :class="{ bold: game.team2.win }">
                                    <span>{{ game.teamName2 }}</span>
                                    <span>{{ game.team2.sum }}</span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div v-if="!liveWatch" class="col">
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <li class="page-item" :class="{ disabled: activePage === 1 }">
                                <button class="page-link" @click="activePage = 1" :disabled="activePage === 1">
                                    <span aria-hidden="true">&laquo;</span>
                                </button>
                            </li>
                            <li class="page-item" :class="{ disabled: activePage === 1 }">
                                <button class="page-link" @click="activePage--" :disabled="activePage === 1">
                                    <span aria-hidden="true">&lt;</span>
                                </button>
                            </li>
                            <li class="page-item" :class="{ active: activePage === page }" v-for="page in computedPages"
                                :key="page">
                                <button class="page-link" @click="activePage = page">{{ page }}</button>
                            </li>
                            <li class="page-item" :class="{ disabled: activePage === pages }">
                                <button class="page-link" @click="activePage++" :disabled="activePage === pages">
                                    <span aria-hidden="true">&gt;</span>
                                </button>
                            </li>
                            <li class="page-item" :class="{ disabled: activePage === pages }">
                                <button class="page-link" @click="activePage = pages" :disabled="activePage === pages">
                                    <span aria-hidden="true">&raquo;</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { watchEffect, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex"
import { db } from "../firebase/config";
import { collection, getDocs, query, orderBy, doc, setDoc, onSnapshot } from "firebase/firestore";
import getSeasons from "../composables/getSeasons";
import Spinner from "../components/Spinner.vue";

export default {
    name: 'Games',
    components: { Spinner },
    setup(props) {
        const store = useStore()
        store.commit('setSidebarToggle', true)
        const route = useRoute();
        const router = useRouter();

        const isReady = computed(() => {
            return store.state.isReady;
        });
        if (!store.state.games) {
            store.commit('setIsPending', true)
        }
        const error = computed(() => {
            return store.state.error;
        });
        const isPending = computed(() => {
            return store.state.isPending;
        });
        const games = computed(() => {
            return store.state.games
        })
        const teams = computed(() => {
            return store.state.totalTeams;
        });

        const showLiveGames = ref()
        if(route.query.live == 'true') {
            showLiveGames.value = true
        } else showLiveGames.value = false

        const liveGames = computed(() => {
            if (Object.values(games.value).length) {
                return Object.values(games.value).filter(game => game.live);
            } else return false
        });

        const activePage = ref(1)

        const pages = computed(() => {
            if (games.value) {
                let numberGames = Object.keys(games.value).length
                // divide games in pages of 10 results - add 1 page if there is a remainder (%)
                return Math.floor(numberGames / 10) + Math.ceil((numberGames % 10) / 10)
            }
        })

        // compute which games to be displayed
        const paginatedGames = computed(() => {
            let i = activePage.value - 1
            // return games.value.slice(i * 10, (i * 10) + 10)
            const slicedObj = {};
            let count = 0;

            for (const [key, value] of Object.entries(games.value)) {
                if (count >= (i * 10) && count < ((i * 10) + 10)) {
                    slicedObj[key] = value;
                }
                count++;
                if (count === (i * 10) + 10) {
                    break;
                }
            }
            return slicedObj
        })

        // compute pagination
        const computedPages = computed(() => {
            switch (pages.value) {
                case 1:
                    return 1
                    break;
                case 2:
                    return 2
                    break;
            }
            if (pages.value) {
                if (activePage.value === 1) {
                    return 3
                } else if (activePage.value === pages.value) {
                    return [pages.value - 2, pages.value - 1, pages.value]
                } else {
                    return [activePage.value - 1, activePage.value, activePage.value + 1]
                }
            }
        })

        const liveWatch = computed(() => {
            if (route.query.live == 'true') {
                return true
            } else return false
        })

        const show = () => {
            router.replace({
                path: '/games',
                query: {
                    live: !showLiveGames.value
                }
            })
        }

        // find games per player
        // console.log(Object.values(games.value))

        // const findGames = computed(() => {
        //     const allGames =  Object.values(games.value).filter(game => game.player1.id == "0fOwUG6K4FMuMGXm3ZDdRvOrHv62" || game.player2.id == "0fOwUG6K4FMuMGXm3ZDdRvOrHv62" || game.player3.id == "0fOwUG6K4FMuMGXm3ZDdRvOrHv62" || game.player4.id == "0fOwUG6K4FMuMGXm3ZDdRvOrHv62")

        //     const wins = allGames.filter(game => (game.player1.id == "0fOwUG6K4FMuMGXm3ZDdRvOrHv62" && game.player1.win == true) || (game.player2.id == "0fOwUG6K4FMuMGXm3ZDdRvOrHv62" && game.player2.win == true) || (game.player3.id == "0fOwUG6K4FMuMGXm3ZDdRvOrHv62" && game.player3.win == true) || (game.player4.id == "0fOwUG6K4FMuMGXm3ZDdRvOrHv62" && game.player4.win == true))

        //     return wins
        // })

        // const findGame = computed(() => {
        //     return Object.values(games.value).filter(game => game.live == true)
        // })

        // console.log(findGames.value);

        return { error, isPending, games, pages, computedPages, paginatedGames, activePage, isReady, showLiveGames, liveGames, liveWatch, show }
    }

}
</script>
<style>
.bold {
    font-weight: 600;
}
</style>