import { db } from "../firebase/config";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { ref } from "@vue/reactivity";

// get all seasons
const getSeasons = () => {
  const seasons = ref([]);
  const colRefSeasons = collection(db, "season");
  // order seasons (newest first)
  const q = query(colRefSeasons, orderBy("timestamp", "desc"));

  const load = async () => {
    await getDocs(q).then((snapshot) => {
      let docs = [];
      snapshot.docs.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      seasons.value = docs;
    });
  };
  return { seasons, load };
};

export default getSeasons;
