import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NewGame from "../views/NewGame.vue";
import NewFriendlyGame from "../views/NewFriendlyGame.vue";
import GameDetails from "../views/GameDetails.vue";
import FriendlyGameDetails from "../views/FriendlyGameDetails.vue";
import Signup from "../views/Signup.vue";
import Login from "../views/Login.vue";
import Stats from "../views/Stats.vue";
import Teams from "../views/Teams.vue";
import Games from "../views/Games.vue";
import FriendlyGames from "../views/FriendlyGames.vue";
import Head2Head from "../views/Head2Head.vue";
import Profile from "../views/Profile.vue";
import Archive from "../views/Archive.vue";
import SeasonArchive from "../views/SeasonArchive.vue";

// firebase imports
import { auth } from "../firebase/config";

const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  if (!user) {
    // redirect
    next({ name: "Login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      let user = auth.currentUser;
      if (user) {
        // redirect
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: (to, from, next) => {
      let user = auth.currentUser;
      if (!user) {
        // redirect
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/new-game",
    name: "NewGame",
    component: NewGame,
    beforeEnter: (to, from, next) => {
      let user = auth.currentUser;
      if (!user) {
        // redirect
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/new-friendly-game",
    name: "NewFriendlyGame",
    component: NewFriendlyGame,
    beforeEnter: (to, from, next) => {
      let user = auth.currentUser;
      if (!user) {
        // redirect
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/stats",
    name: "Stats",
    component: Stats,
  },
  {
    path: "/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/games",
    name: "Games",
    component: Games,
  },
  {
    path: "/friendly-games",
    name: "FriendlyGames",
    component: FriendlyGames,
  },
  {
    path: "/head-2-head",
    name: "Head2Head",
    component: Head2Head,
  },
  {
    path: "/game/:id",
    name: "GameDetails",
    component: GameDetails,
  },
  {
    path: "/game/friendly/:id",
    name: "FriendlyGameDetails",
    component: FriendlyGameDetails,
  },
  {
    path: "/archive",
    name: "Archive",
    component: Archive,
  },
  {
    path: "/archive/:seasonId",
    name: "SeasonArchive",
    component: SeasonArchive
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
