<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" :class="sidebarToggle ? 'toggled' : ''"
        id="accordionSidebar">

        <!-- Sidebar - Brand -->
        <div class="sidebar-brand d-flex align-items-center justify-content-center">
            <div class="sidebar-brand-icon ">
                <!-- <i class="fas fa-laugh-wink"></i> -->
                <router-link to="/">
                    <!-- <img src="../assets/cards_5569.png" class="img-fluid mx-4" /> -->
                    <i class="bi bi-suit-spade text-white"></i>
                </router-link>

            </div>
            <!-- <h5 class="d-none d-md-block nav-link link-dark fs-4">Δηλωτάρα</h5> -->

            <!-- <div class="sidebar-brand-text mx-3">Δηλωτάρα</div> -->
        </div>

        <!-- Nav Item - Dashboard -->
        <li class="nav-item" :class="path === '/' ? 'active' : ''">
            <!-- Divider -->
            <hr class="sidebar-divider my-0">
            <router-link class="nav-link" to="/"><i class="fas fa-fw fa-tachometer-alt"></i><span>Αρχική</span>
            </router-link>
        </li>

        <!-- Nav Item -->
        <li v-if="!friendly" class="nav-item" :class="path === '/stats' ? 'active' : ''">
            <!-- Divider -->
            <hr class="sidebar-divider my-0">
            <router-link class="nav-link" to="/stats"><i class="fas fa-chart-pie"></i><span>Στατιστικά</span>
            </router-link>
        </li>

        <!-- Nav Item -->
        <li class="nav-item" :class="path === '/head-2-head' ? 'active' : ''">
            <!-- Divider -->
            <hr class="sidebar-divider my-0">
            <router-link class="nav-link" to="/head-2-head"><i class="fas fa-people-arrows"></i><span>Head-to-Head</span>
            </router-link>
        </li>

        <!-- Nav Item -->
        <li class="nav-item" :class="path === '/teams' ? 'active' : ''">
            <!-- Divider -->
            <hr class="sidebar-divider my-0">
            <router-link class="nav-link" to="/teams"><i class="fas fa-user-friends"></i><span>Ομάδες</span>
            </router-link>
        </li>

        <!-- Nav Item -->
        <li v-if="!friendly" class="nav-item" :class="path === '/games' ? 'active' : ''">
            <!-- Divider -->
            <hr class="sidebar-divider my-0">
            <router-link class="nav-link" to="/games"><i class="fas fa-calendar-alt"></i><span>Παιχνίδια</span>
            </router-link>
        </li>

        <!-- Nav Item -->
        <!-- <li v-else class="nav-item" :class="path === '/friendly-games' ? 'active' : ''"> -->
        <!-- Divider -->
        <!-- <hr class="sidebar-divider my-0">
            <router-link class="nav-link" to="/friendly-games"><i class="fas fa-calendar-alt"></i><span>Φιλικά</span>
            </router-link>
        </li> -->

        <!-- Nav Item -->
        <li class="nav-item" :class="path === '/archive' ? 'active' : ''">
            <!-- Divider -->
            <hr class="sidebar-divider my-0">
            <router-link class="nav-link" to="/archive"><i class="fas fa-folder"></i><span>Αρχείο</span>
            </router-link>
        </li>

        <!-- Nav Item -->
        <li v-if="!friendly" class="nav-item" :class="path === '/new-game' ? 'active' : ''">
            <!-- Divider -->
            <hr class="sidebar-divider my-0">
            <router-link class="nav-link" @click="newGame" to="/new-game"><i class="fas fa-gamepad"></i><span>Νεό
                    Παιχνίδι</span>
            </router-link>
        </li>

        <!-- Nav Item -->
        <!-- <li v-else class="nav-item" :class="path === '/new-friendly-game' ? 'active' : ''"> -->
        <!-- Divider -->
        <!-- <hr class="sidebar-divider my-0">
            <router-link class="nav-link" @click="newGame" to="/new-friendly-game"><i
                    class="fas fa-gamepad"></i><span>Νεό Φιλικό</span>
            </router-link>
        </li> -->


        <!-- Heading -->
        <!-- <div class="sidebar-heading">
            Interface
        </div> -->

        <!-- Nav Item - Pages Collapse Menu -->
        <!-- <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i class="fas fa-fw fa-cog"></i>
                <span>Components</span>
            </a>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Custom Components:</h6>
                    <a class="collapse-item" href="buttons.html">Buttons</a>
                    <a class="collapse-item" href="cards.html">Cards</a>
                </div>
            </div>
        </li> -->


        <!-- Sidebar Toggler (Sidebar) -->
        <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle" @click="toggleSidebar"></button>
        </div>

    </ul>
</template>
<script>
import { useStore } from 'vuex'
import { computed, ref } from "@vue/reactivity";
import { watchEffect } from 'vue'
import { useRouter, useRoute } from "vue-router";

export default {
    name: "Sidebar",
    setup() {
        const togglerButton = ref();
        const route = useRoute();
        const path = ref();
        // const { user } = fetchUser();
        const router = useRouter();
        const closeLogoutModal = ref(null)
        const store = useStore();

        const user = computed(() => {
            return store.state.user
        })
        const friendly = computed(() => {
            if (store.state.isReady) {
                return !!store.state.season[0].champion
            }
        })
        const games = computed(() => {
            if (store.state.isReady) {
                return store.state.games
            } else return []
        })
        const friendlyGames = computed(() => {
            if (store.state.isReady) {
                return store.state.friendlyGames
            } else return []
        })

        const handleLogout = () => {
            signOut(auth);
            closeLogoutModal.value.closeMdl()
            router.push("/login");
        };
        const live = computed(() => {
            if (games.value.length) {
                return games.value.map(game => game.live).reduce((prev, next) => (prev || next));
            } else return false
        });

        const liveFriendly = computed(() => {
            if (friendlyGames.value.length) {
                return friendlyGames.value.map(game => game.live).reduce((prev, next) => (prev || next));
            } else return false
        });

        const newGame = () => {
            if (!user.value) {
                store.commit('setError', 'Δεν είστε εγγεγραμμένος χρήστης...')
                store.commit('setAlertColor', 'alert-danger')
            }
        }
        watchEffect(() => {
            path.value = route.path;
        });
        const sidebarToggle = computed(() => {
            return store.state.sidebarToggle
        })

        const toggleSidebar = () => {
            store.commit('setSidebarToggle', !store.state.sidebarToggle)
        }

        return { sidebarToggle, toggleSidebar, path, handleLogout, newGame, live, liveFriendly, user, closeLogoutModal, friendly }
    }

}
</script>
<style scoped>
ul {
    transition: all 0.5s ease;
}
</style>