<template>
    <div class="container fixed-top mt-6">
        <div
            v-if="alert"
            class="col-md-8 mx-auto alert alert-dismissible fade"
            :class="{ show: showAlert }, alertColor"
            role="alert"
        >
            {{ alert }}
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                ref="alertBtn"
                @click="cleanError"
            ></button>
        </div>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex"
export default {
    name: 'Alert',
    setup() {
        const store = useStore()
        const alertBtn = ref()

        const alertColor = computed(() => {
            return store.state.alertColor
        })

        let alertTimeout

        const alert = computed(() => {
            if (store.state.error) {
                alertTimeout = setTimeout(() => {
                    alertBtn.value.click()
                    store.commit('setError', null)
                }, 5000)
            }
            return store.state.error
        })

        const showAlert = computed(() => {
            return (!!store.state.error)
        })

        const cleanError = () => {
            store.commit('setError', null)
            clearTimeout(alertTimeout)
        }

        return { alert, showAlert, alertBtn, alertColor, cleanError }
    }

}
</script>
<style>
.mt-6 {
    margin-top: 5rem;
}
</style>