<template>
    <div class="container">
        <footer
            class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top"
        >
            <p class="col-md-4 mb-0 text-muted">&copy; {{ new Date().getFullYear() }} Δηλωτάρα</p>
            <router-link
                to="/"
                class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
            >
                <img src="../assets/cards_5569.png" class="img-fluid" width="40" height="32" />
            </router-link>
            <p class="col-md-4 mb-0 text-muted">Η γλύψα είναι τεράστια...</p>
        </footer>
    </div>
</template>
<script>
export default {
    name: "Footer"
}
</script>
<style>
</style>