<template>
    <div class="card col-md-8 mx-auto" aria-hidden="true">
        <div class="card-body justify-content-start text-start text-">
            <h5 class="card-title placeholder-glow">
                <span class="placeholder bg-primary col-6"></span>
            </h5>
            <p class="card-text placeholder-wave">
                <span class="placeholder col-7"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-6"></span>
                <span class="placeholder col-8"></span>
                <span class="placeholder col-6"></span>
                <span class="placeholder col-7"></span>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "Placeholder"

}
</script>
<style lang="">
    
</style>