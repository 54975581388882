<template>
    <div class="container-fluid p-1">
        <div class="row">
            <div class="d-flex col-md-8 mx-auto justify-content-start mt-3">
                <h3 class="text-dark">Παιχνίδια</h3>
            </div>
        </div>
        <Spinner v-if="!isReady" color="primary" />
        <div v-else class="col-md-8 mx-auto">
            <div class="row">
                <div class="col">
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <li class="page-item" :class="{ disabled: activePage === 1 }">
                                <button
                                    class="page-link"
                                    @click="activePage = 1"
                                    :disabled="activePage === 1"
                                >
                                    <span aria-hidden="true">&laquo;</span>
                                </button>
                            </li>
                            <li class="page-item" :class="{ disabled: activePage === 1 }">
                                <button
                                    class="page-link"
                                    @click="activePage--"
                                    :disabled="activePage === 1"
                                >
                                    <span aria-hidden="true">&lt;</span>
                                </button>
                            </li>
                            <li
                                class="page-item"
                                :class="{ active: activePage === page }"
                                v-for="page in computedPages"
                                :key="page"
                            >
                                <button class="page-link" @click="activePage = page">{{ page }}</button>
                            </li>
                            <li class="page-item" :class="{ disabled: activePage === pages }">
                                <button
                                    class="page-link"
                                    @click="activePage++"
                                    :disabled="activePage === pages"
                                >
                                    <span aria-hidden="true">&gt;</span>
                                </button>
                            </li>
                            <li class="page-item" :class="{ disabled: activePage === pages }">
                                <button
                                    class="page-link"
                                    @click="activePage = pages"
                                    :disabled="activePage === pages"
                                >
                                    <span aria-hidden="true">&raquo;</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="row">
                <div>
                    <router-link
                        v-if="games"
                        class="list-group-item list-group-item-action p-0"
                        v-for="(game, index) in paginatedGames"
                        :key="index"
                        :to="{ name: 'FriendlyGameDetails', params: { id: game.id } }"
                    >
                        <div class="row">
                            <div
                                v-if="game.live"
                                class="col-2 d-flex justify-content-center align-items-center border-end"
                                style="min-width: 80px;"
                            >
                                <h4 class="text-danger pulse">LIVE</h4>
                            </div>
                            <div v-else
                                class="col-2 d-flex align-items-center border-end"
                                style="min-width: 80px;"
                            >{{ game.timestamp }}</div>
                            <div class="col-9">
                                <div
                                    class="col d-flex justify-content-between"
                                    :class="{ bold: game.team1.win }"
                                >
                                    <span>{{ game.teamName1 }}</span>
                                    <span>{{ game.team1.sum }}</span>
                                </div>
                                <div
                                    class="col d-flex justify-content-between"
                                    :class="{ bold: game.team2.win }"
                                >
                                    <span>{{ game.teamName2 }}</span>
                                    <span>{{ game.team2.sum }}</span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <li class="page-item" :class="{ disabled: activePage === 1 }">
                                <button
                                    class="page-link"
                                    @click="activePage = 1"
                                    :disabled="activePage === 1"
                                >
                                    <span aria-hidden="true">&laquo;</span>
                                </button>
                            </li>
                            <li class="page-item" :class="{ disabled: activePage === 1 }">
                                <button
                                    class="page-link"
                                    @click="activePage--"
                                    :disabled="activePage === 1"
                                >
                                    <span aria-hidden="true">&lt;</span>
                                </button>
                            </li>
                            <li
                                class="page-item"
                                :class="{ active: activePage === page }"
                                v-for="page in computedPages"
                                :key="page"
                            >
                                <button class="page-link" @click="activePage = page">{{ page }}</button>
                            </li>
                            <li class="page-item" :class="{ disabled: activePage === pages }">
                                <button
                                    class="page-link"
                                    @click="activePage++"
                                    :disabled="activePage === pages"
                                >
                                    <span aria-hidden="true">&gt;</span>
                                </button>
                            </li>
                            <li class="page-item" :class="{ disabled: activePage === pages }">
                                <button
                                    class="page-link"
                                    @click="activePage = pages"
                                    :disabled="activePage === pages"
                                >
                                    <span aria-hidden="true">&raquo;</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex"
import { db } from "../firebase/config";
import { collection, getDocs, query, orderBy, doc, setDoc, onSnapshot } from "firebase/firestore";
import getSeasons from "../composables/getSeasons";
import Spinner from "../components/Spinner.vue";

export default {
    name: 'FriendlyGames',
    components: { Spinner },
    setup() {
        const store = useStore()
        store.commit('setSidebarToggle', true)

        const isReady = computed(() => {
            return store.state.isReady;
        });
        if (!store.state.games) {
            store.commit('setIsPending', true)
        }
        const error = computed(() => {
            return store.state.error;
        });
        const isPending = computed(() => {
            return store.state.isPending;
        });
        const games = computed(() => {
            return store.state.friendlyGames
        })
        const teams = computed(() => {
            return store.state.totalTeams;
        });

        const activePage = ref(1)

        const pages = computed(() => {
            if (games.value) {
                // divide games in pages of 10 results - add 1 page if there is a remainder (%)
                return Math.floor(games.value.length / 10) + Math.ceil((games.value.length % 10) / 10)
            }
        })

        // compute which games to be displayed
        const paginatedGames = computed(() => {
            let i = activePage.value - 1
            return games.value.slice(i * 10, (i * 10) + 10)
        })

        // compute pagination
        const computedPages = computed(() => {
            switch (pages.value) {
                case 1:
                    return 1
                    break;
                case 2:
                    return 2
                    break;
            }
            if (pages.value) {
                if (activePage.value === 1) {
                    return 3
                } else if (activePage.value === pages.value) {
                    return [pages.value - 2, pages.value - 1, pages.value]
                } else {
                    return [activePage.value - 1, activePage.value, activePage.value + 1]
                }
            }
        })

        return { error, isPending, games, pages, computedPages, paginatedGames, activePage, isReady }
    }

}
</script>
<style>
.bold {
    font-weight: 600;
}
</style>